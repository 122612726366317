import React, { useEffect } from "react";
import {
  ManagementTeamContainer,
  BoardOfDirectors,
  ManagementCardWrapper,
} from "./ManagementTeam2Style.js";
import ManagementTeamCard2 from "./ManagementTeamCard2";
import ayobola from "../../images/ManagementAyobola.png";
import obodo from "../../images/ManagementObodo.png";
import Mayowa from "../../images/ManagementMayowa.png";
import chikwue from "../../images/ManagementChikwue.png";
import adetomi from "../../images/ManagementAdetomi.png";
import mallamIdris from '../../images/MallamIdris.png';
import mgtArrow from "../../images/ManagementArrow.svg";

const ManagementTeam = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <ManagementTeamContainer>
      <BoardOfDirectors>
        <ManagementCardWrapper>
          <ManagementTeamCard2
            cardImage={ayobola}
            heading="Sir Ayobola Abiola"
            post="Chairman of Board"
            description="Sir Ayobola is an economics, finance, and banking expert with
            three decades of banking experience spanning investment,
            commercial, and development banking in leading financial
            institutions. He is currently the Founder & CEO of Fullhouse
            Advisory Partners, an investment and financial advisory services
            firm."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
          />
          <ManagementTeamCard2
            cardImage={obodo}
            heading="Dumebi Obodo"
            post="Director"
            description="Dumebi Obodo is a consummate professional with over fifteen (15) years'
            cognate experience obtained from operational, management and board
            positions covering business strategy/development, Product Development &
            Deployment, Partnerships & Sales."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
          />
          <ManagementTeamCard2
            cardImage={Mayowa}
            heading="Mayowa Olaniyan"
            post="Director"
            description="Mayowa Olaniyan is an insightful Business Executive with sturdy managerial expertise. Until her recent appointment as the Group Managing Director/CEO of Chams Holding Company PLC, she was the MD/CEO of ChamsMobile Limited, a fast-growing FinTech company, licensed by the Central Bank of Nigeria (CBN) to provide Mobile Money Operations and Agency services. Prior to that role, she served as the Executive Director overseeing finance, strategy and corporate services for Chams Group. "
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
          />
          <ManagementTeamCard2
            cardImage={chikwue}
            heading="Lady Chikwue Ochiaga"
            post="Director"
            description="Lady Chikwue Ochiaga holds a B.L from Ahmadu Bello University, an
            LLB (Hons.), and is a lawyer by profession. She started her career
            as a Counsel in Onyeabo Obi and Co, until she established her own
            law Firm. She is the Principal Counsel, at T. Chikwue Ochiagha &
            Co, a position she still currently holds. "
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            // mgtPage="/pc"
          />
          {/* <ManagementTeamCard2
            cardImage={adetomi}
            heading="Adetomi Imoteda"
            post="Director"
            description="Adetomi Imoteda Aladekomo is a Cordon Bleu-trained chef. She is
            the host of two TV shows – Heels in the Kitchen and Urban Kitchen,
            as well as the Head Chef at Heels in the Kitchen. She
            conceptualized and created Heels in the Kitchen while exercising
            her vast creative abilities in the make-up and entertainment
            industries."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
          /> */}
          <ManagementTeamCard2
            cardImage={mallamIdris}
            heading="Mallam Idris Yushau Saeed"
            post="Director"
            description="Mallam Idris Yushau Saeed is a distinguished professional with a robust background in accounting, finance, and management. A graduate of Accounting from the University of Maiduguri (1982), he furthered his education with an MBA from Ahmadu Bello University, Zaria (1994). Idris is an associate member of the Institute of National Accountants of Nigeria (ANAN) and a member of the Chartered Institute of Taxation of Nigeria (CITN).

            Mallam Idris commenced his career during the mandatory NYSC program with the accounts department of Nigerian Paper Mills Limited, Jebba, Kwara State. In 1984, he joined the Federal Civil Service as an Inspector of Taxes with the Federal Inland Revenue Department.
            
            The following year, he moved to Icon Limited (Merchant Bankers), where he ascended to the role of Area Manager. In 1995, he transitioned to African International Bank (AIB), eventually becoming Principal Manager and Head of the Broad Street Branch. By 1999, Idris joined Intercity Bank Plc, where he led the bank’s International Department until his resignation in 2000 to enter politics.
            
            Throughout his career, Mallam Idris has enhanced his expertise through various professional courses, seminars, and conferences both domestically and internationally. These include induction courses at Icon Merchant Bankers, financial management, commercial lending,
            bank management, export development, microcomputer applications, and workshops on leasing, export processing zones, and Phoenix banking software, among others.
            
            Currently, Mallam Idris serves as the Managing Director of Linknet Nigeria Limited, a construction and general contracting company based in Kaduna. He is also a Partner at Adigun Idris Bala and Co. (AIB), an account management and tax consulting firm affiliated with other professional entities. Additionally, he is the Chairman of the Audit and Risk Asset Committee of Backbone Connectivity Network Nigeria Ltd (BCN).
            
            Mallam Idris has also held significant positions as a non-executive Director at Premium Pension Ltd (2011-2017), ChamsSwitch Nigeria Ltd (2007-2022), and Oxygen Manufacturing Company Ltd (2008-2022)."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            // mgtPage="/pc"
          />
        </ManagementCardWrapper>
      </BoardOfDirectors>
    </ManagementTeamContainer>
  );
};

export default ManagementTeam;
