import React from "react";
import styled from "styled-components";
// import industrialBanking from "../../images/IndustrialBanking.svg";
import bankingIcon from "../../images/BankingIcon.png";

const IndustrailCard2 = ({ cardHeader, sourceImage, list1, list2, list3, list4, list5}) => {
  return (
    <IndustrialCardContainer>
      <p>{cardHeader}</p>
      <img id="cardImage" src={sourceImage} alt="industrial-banking" />
      <IndustrialInfo id="cardDescription">
        <List>
          <img src={bankingIcon} alt="list1" />
          <p>{list1}</p>
        </List>
        <List>
          <img src={bankingIcon} alt="list1" />
          <p>{list2}</p>
        </List>
        <List>
          <img src={bankingIcon} alt="list1" />
          <p>{list3}</p>
        </List>
        <List>
          <img src={bankingIcon} alt="list1" />
          <p>{list4}</p>
        </List>
        <List>
          <img src={bankingIcon} alt="list1" />
          <p>{list5}</p>
        </List>
      </IndustrialInfo>
    </IndustrialCardContainer>
  );
};

export default IndustrailCard2;

const IndustrialCardContainer = styled.div`
  width: 360px;
  height: 500px;
  background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin: 15px;

  img {
    width: 300px;
    height: 300px;
    align-self: center;
  }

  p {
    width: 183px;
    // height: 82px;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #ffffff;
    text-align: left;
    margin-left: 30px;
    margin-top: 40px;
    margin-bottom: 48px;
  }

  :hover #cardImage {
    display: none;
  }

  :hover #cardDescription {
    display: flex;
    visibility: visible;
    flex-direction: column;
  }

  @media screen and (min-width: 961px) {
    width: 30%;
    height: 500px;
  }
  @media screen and (max-width: 960px) {
    width: 43%;
    height: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 43%;
    height: 500px;
  }
  @media screen and (max-width: 600px) {
    width: 60%;
    height: 500px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: 500px;
     {
      width: 80%;
    }
  }
  @media screen and (max-width: 375px) {
    width: 90%;
    height: 500px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    height: 500px;
  }
`;

const IndustrialInfo = styled.div`
  // background-color: red;
  display: none;
  margin-left: 30px;
  margin-top: 5px;
  color: #ffffff;
`;
const List = styled.div`
  width: 100%;
  height: 30px;
  // background: blue;
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;

  img {
    width: 24px;
    height: 24px;
    // background: pink;
    align-self: center;
  }

  p {
    width: 100%;
    // background: red;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px;
    margin-left: 12px;
    margin-bottom: 16px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #ebf5f0;
    height: 100%;
  }
`;
// const IndustrialHeader = styled.div``;

// const IndustrialHeader = styled.div``;
// const IndustrialHeader = styled.div``;
// const IndustrialHeader = styled.div``;
