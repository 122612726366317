import React, { useState, useEffect, useRef } from "react";
import "./awards-styles.css";
import eBank from "../../../images/e-banking.png";
import cardExpo from "../../../images/card-expo.png";
import cardExpoAfrica from "../../../images/card-expo-africa.png";
import techGov from "../../../images/tech-in-gov.png";
import partner from "../../../images/partner-of-the-year.png";
import finIN from "../../../images/fin-in.png";
import cardSolution from "../../../images/card-solution.png";
import partnershipByDermalog from "../../../images/dermalog.png";

const Awards = () => {
  const [awardsDisplay, setAwardsDisplay] = useState([
    eBank,
    cardExpo,
    techGov,
    partner,
    finIN,
    cardExpoAfrica,
    cardSolution,
    partnershipByDermalog,
  ]);
  const containerRef = useRef(null);

  useEffect(() => {
    const slide = () => {
      const container = containerRef.current;
      const awards = Array.from(container.children);
      container.classList.add("animate");

      setTimeout(() => {
        // Remove animation class after it completes...
        container.classList.remove("animate");

        // Update the awards array...
        setAwardsDisplay((prevAwards) => {
          const updatedAwards = prevAwards.slice();
          updatedAwards.push(updatedAwards.shift());
          return updatedAwards;
        });

        // Reset scale for all awards...
        awards.forEach((award) => {
          award.style.transform = "";
        });

        awards[0].style.transform = "scale(1.2)";
        awards[1].style.transform = "scale(1)";
      }, 1000);
    };

    const intervalId = setInterval(slide, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="awards">
      <div className="text-div">
        <div className="line"> </div>
        Awards and Recognitions
      </div>
      <div className="awards-div" ref={containerRef}>
        {awardsDisplay.map((award, index) => (
          <img
            key={index}
            className={index === 0 ? "main-award" : "award"}
            src={award}
            alt={`Award ${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Awards;
