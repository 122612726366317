import React from "react";
import styled from "styled-components";

const CarouselCard = ({
  carouselImage,
  carouselBody,
  carouselName,
  CarouselRole,
  sourceImage,
}) => {
  return (
    <CarouselContainer>
      <CareerDescriptions>
        <Div1>
          <img src={carouselImage} alt="apostrophe" />
        </Div1>
        <Div2>{carouselBody}</Div2>
      </CareerDescriptions>
      <CareerImage>
        <img src={sourceImage} alt="carousel" />
      </CareerImage>
    </CarouselContainer>
  );
};

export default CarouselCard;

const CarouselContainer = styled.div`
  background: red;
  // width: 1200px;
  width: 85vw;
  // width: 80%;
  height: 380px;
  box-shadow: 0px 32px 64px -12px rgba(16, 24, 40, 0.14);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  // justify-content: center;
  align-items: center;
  border-radius: 30px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 85vw;
    height: 380px;
    border-radius: 30px;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 85vw;
    height: 300px;
    border-radius: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 85vw;
    height: 300px;
    border-radius: 30px;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85vw;
    height: 800px;
    border-radius: 30px;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column-reverse;
    border-radius: 30px;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 85vw;
    height: 800px;
    border-radius: 30px;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column-reverse;
    border-radius: 30px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 85vw;
    height: 700px;
    border-radius: 30px;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column-reverse;
    border-radius: 30px;
  }
`;
const CareerDescriptions = styled.div`
  // background: yellow;
  width: 820px;
  //   width: 65%;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 60%;
    height: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 768px) {
    width: 60%;
    height: 300px;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    // background: green;
    width: 100%;
    height: 35%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100%;
    height: 50%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
  @media screen and (max-width: 320px) {
    // background: green;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
    border-bottom-right-radius: 30px;
    border-bottom-left-radius: 30px;
  }
`;
const CareerImage = styled.div`
  // background: green;
  width: 380px;
  //   width: 35%;
  height: 380px;
  display: flex;
  justify-content: center;
  //   align-items: center;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;

  img {
    width: 380px;
    height: 380px;
    // width: 100%;
    // height: 100%;
    object-fit: cover;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  @media screen and (min-width: 961px) and(max-width: 1200px) {
    width: 40%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 40%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 40%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: yellow;
    width: 100%;
    height: 65%;
    display: flex;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: yellow;
    width: 100%;
    height: 65%;
    display: flex;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: yellow;
    width: 100%;
    height: 45%;
    display: flex;
    border-top-right-radius: 30px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      border-bottom-right-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
`;
const Div1 = styled.div`
  position: relative;
  // background: blue;
  position: relative;
  width: 100px;
  height: 76px;
  // top: 40px;
  margin-left: 40px;
  // margin-bottom: 14px;

  img {
    width: 100px;
    height: 76px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    margin-left: 10px;
    width: 50px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    margin-left: 10px;
    top: 0px;
    width: 50px;
    height: 30px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    margin-left: 10px;
    top: 0px;
    width: 30px;
    height: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    margin-left: 10px;
    top: 0px;
    width: 30px;
    height: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    margin-left: 10px;
    top: -10px;
    width: 25px;
    height: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 375px) {
    // // background: pink;
    // margin-left: 10px;
    // top: -10px;
    // width: 25px;
    // height: 15px;

    // img {
    //   width: 100%;
    //   height: 100%;
    // }
    display: none;
  }
  @media screen and (max-width: 375px) {
    display: none;
  }
`;
const Div2 = styled.div`
  // background: green;
  position: relative;
  margin-right: 132px;
  width: 614px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-left: 142px;
  // top: -60px;
  top: -40px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    font-size: 16px;
    line-height: 26px;
    // background: pink;
    width: 70%;
    height: auto;
    margin-left: 100px;
    top: 0px;
  }
  @media screen and (max-width: 960px) {
    font-size: 16px;
    line-height: 24px;
    // background: pink;
    width: 85%;
    height: auto;
    margin-left: 60px;
    top: -20px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
    line-height: 24px;
    // background: pink;
    width: 90%;
    height: auto;
    margin-left: 40px;
    top: -20px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    // background: pink;
    width: 90%;
    height: auto;
    margin-left: 40px;
    top: -20px;
  }
  @media screen and (max-width: 480px) {
    font-size: 16px;
    line-height: 24px;
    // background: pink;
    width: 85%;
    height: auto;
    margin-left: 40px;
    top: -20px;
  }
  @media screen and (max-width: 375px) {
    font-size: 16px;
    line-height: 24px;
    // background: pink;
    width: 90%;
    height: auto;
    margin-left: 10px;
    // margin-left: 40px;
    top: -20px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
    line-height: 24px;
    // background: pink;
    width: 90%;
    height: auto;
    margin-left: 10px;
    top: -20px;
  }
`;
