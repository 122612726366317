import styled from "styled-components";
import dotedComplete from "../../images/DotedComplete.png";
import dotedLeft from "../../images/DotedLeft.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const CareerPageContainer = styled.div`
  // background: red;
  // height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const GetInTouch = styled.div`
  flex-wrap: wrap;
  width: inherit;
  margin-top: 80px;
  height: 500px;
  background: url(${dotedComplete}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 400px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: 400px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: 500px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    height: 400px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
`;

export const Content = styled.div`
  // background: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   width: 909px;
  width: auto;
  //   height: 184px;
  height: auto;
  overflow-x: hidden;

  p {
    width: 703px;
    height: 56px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 768px) {
    // background: blue;
    overflow-x: hidden;
    p {
    }
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    overflow-x: hidden;
    p {
    }
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    justify-content: space-around;
    height: 250px;
    width: 100vw;
    overflow-y: hidden;

    p {
      width: 85vw;
      height: auto;
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-y: hidden;

    p {
      width: 85vw;
      height: auto;
      line-height: 26px;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-y: hidden;

    p {
      width: 85vw;
      height: auto;
      line-height: 26px;
      font-size: 16px;
      // margin-bottom: 20px;
    }
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 94px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
  transition: background 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Touch = styled.div`
  width: 533px;
  height: 60px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #08783d;
  }

  @media screen and (max-width: 480px) {
    width: 95vw;
    font-size: 34px;
    line-height: 52px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    font-size: 30px;
    line-height: 48px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 95vw;
    font-size: 28px;
    line-height: 44px;
    span {
      line-height: 38px;
    }
`;

export const Perks = styled.div`
  width: 100vw;
  height: 1050px;
  height: auto;
  // background: yellow;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: yellow;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const Information = styled.div`
  //   background: red;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  p {
    width: 907px;
    height: 56px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 20px;
    margin-bottom: 60px;
    margin-right: 0px;
    margin-left: 0px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;

    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: blue;
    width: 100vw;
    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
    p {
      width: 90%;
      line-height: 25px;
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    p {
      width: 90%;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    p {
      width: 90%;
      line-height: 22px;
      font-size: 16px;
    }
  }
`;

export const PerkButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 94px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
  transition: background 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;
  margin-top: 90px;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PerkHeader = styled.div`
  width: 456px;
  height: 44px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d2939;
  margin-top: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    font-size: 34px;
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
  @media screen and (max-width: 375px) {
    font-size: 26px;
  }
  @media screen and (max-width: 320px) {
    fontsize: 24px;
  }
`;

export const CardWrapper = styled.div`
  // background: red;
  width: 90%;
  height: auto;
  //   background: green;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 40px 0px;
  overflow: hidden;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    gap: 0px;
    height: auto;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: green;
    width: 100vw;
    height: 1050px;
    margin-bottom: 0px;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    width: 100vw;
    height: 2000px;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 100vw;
    height: 2000px;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 100vw;
    height: 2200px;
    gap: 0px;
    padding: 0px 0px;
  }
`;

export const ImageZone = styled.div`
  //   background: red;
  width: 100vw;
  display: flex;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: 350px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 350px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
    // background: yellow;
    height: 300px;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    width: 100vw;
    flex-wrap: wrap;
    height: 450px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 100vw;
    height: 1100px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 100vw;
    height: 1000px;
  }
`;
export const Image1 = styled.div`
  //   background: blue;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 30%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 45%;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 10px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    margin: 10px;
  }
`;
export const Image2 = styled.div`
  //   background: yellow;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  img {
    width: 100%;
    height: 49%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 30%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 45%;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 10px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    margin: 10px;
  }
`;
export const Image3 = styled.div`
  //   background: pink;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  img {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 30%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 45%;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 10px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    margin: 10px;
  }
`;
export const Roles = styled.div`
  //   background: red;
  background: #ffffff;
  width: 100vw;
  height: 1000px;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const RoleWrapper = styled.div`
  overflow-x: hidden;
  // background: blue;
  width: 70%;
  margin-top: 110px;
  margin-bottom: 110px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: tomato;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const DescWrapper = styled.div`
  //   background: pink;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    margin-top: 0px;
    text-align: center;
    width: 900px;
    height: 73px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #475467;
  }
  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;

    p {
      // background: yellow;
      width: 80%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: yellow;
    p {
      width: 700px;
    }
  }
  @media screen and (max-width: 600px) {
    p {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    p {
      width: 380px;
      padding-top: 20px;
      padding-bottom: 60px;
    }
  }
  @media screen and (max-width: 375px) {
    p {
      width: 340px;
      padding-top: 10px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: green;
    height: 380px;
    p {
      width: 280px;
      padding-bottom: 80;
    }
  }
`;

export const DescText = styled.div`
  width: 172px;
  height: 32px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  display: flex;
  align-items: center;
  color: #101828;
  margin-bottom: 30px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
    margin-bottom: 0px;
  }
  @media screen and (max-width: 320px) {
    margin-bottom: 0px;
  }
`;
export const CardWrapper2 = styled.div`
  // background: green;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  // gap: 30px;
  width: 90%;
  height: auto;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    margin-bottom: 0px;
  }
`;

export const CarouselWrapper = styled.div`
  width: 100vw;
  // height: 609px;
  height: auto;
  padding-top: 103px;
  padding-bottom: 103px;
  background: #f9fafb;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  // margin-bottom: 60px;

  // display: flex;
  // justify-content: center;
  // align-items: center;

  @media screen and (min-width: 961px) and(max-width: 1200px) {
    display: none;
    background: blue;
    width: 100vw;
    height: auto;
    display: flex;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 480px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  overflow-x: hidden;
  overflow-y: hidden;

  p {
    width: 650px;
    height: 43px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 320px;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 59px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    // margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 33.5px;
  margin-top: 32px;
  margin-bottom: 50px;
  background: #08783d;
  border-radius: 10px;
  width: 210px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
  @media screen and (max-width: 375px) {
    margin: 0px;
  }
`;
// export const CareerPageContainer = styled.div``;
// export const CareerPageContainer = styled.div``;
