import styled from "styled-components";

export const HeaderContainer = styled.div`
  // background: tomato;
  background: #ffffff;
  width: 100vw;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  border-bottom: 1px solid #f2f4f7;
  z-index: 999;
  overflow: hidden;
  box-shadow: ${(props) =>
    props.showShadow ? "#ced3da 0px 0px 7px 0px;" : "none"};
  transition: box-shadow 0.3s ease-in-out;

  @media screen and (min-width: 1025px) {
    // background: tomato;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (min-width: 961px) and (min-height: 1024px) {
    // background: tomato;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 800px) {
    // background: tomato;
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const LeftNav = styled.div`
  // background: #f1f1f1;
  // background: red;
  // margin-left: 80px;
  margin-left: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: auto;

  @media screen and (min-width: 1025px) {
    justify-content: flex-start;
    margin-left: 10px;
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    justify-content: flex-start;
    margin-left: 10px;
  }
  @media screen and (max-width: 960px) {
    justify-content: flex-start;
    margin-left: 40px;
  }
`;
export const RightNav = styled.div`
  // background: green;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  // margin-left: 80px;
  margin-right: 120px;

  @media screen and (min-width: 1025px) {
    // background: black;
    // width: 100vw;
    height: auto;
    margin-right: 10px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    // background: black;
    // width: 100vw;
    height: auto;
    margin-right: 0px;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    margin-right: 0px;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;
export const Request = styled.div`
  // background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  //   background: blue;
  margin-right: 10px;
  cursor: pointer;
  padding: 8px;
  width: 160px;
  height: 40px;
  color: #667085;
  border-radius: 5px;
  font-weight: 400;
  font-size: 16px;

  @media screen and (max-width: 1024px) {
    // background: red;
    margin-right: 10px;
    margin-bottom: 0px;
    width: auto;
    font-size: 14px;

    :hover {
      outline: none;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
  }
  @media screen and (max-width: 960px) {
    margin-right: 0px;
    margin-bottom: 20px;
    width: 80vw;

    :hover {
      outline: none;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
  }
`;
export const Support = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  //   background: blue;
  margin-left: 10px;
  cursor: pointer;
  padding: 8px;
  width: 160px;
  height: 40px;
  border-radius: 5px;
  font-weight: 500;
  font-size: 16px;
  background: #08783d;
  color: #ffffff;
  transition: width 1s;

  :hover {
    width: 180px;
  }

  @media screen and (max-width: 1024px) {
    margin-right: 0px;
    margin-bottom: 0px;
    width: auto;
    margin-left: 0px;
    cursor: pointer;
    padding: 8px;
    font-size: 14px;
  }
  @media screen and (max-width: 960px) {
    margin-right: 0px;
    margin-bottom: 0px;
    width: 80vw;
  }
`;
export const ImageWrapper = styled.div`
  // background: brown;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (min-width: 1024px) {
    // background: red;
    width: auto;
    height: auto;
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    // background: black;
    width: auto;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
  }
`;
export const ImageLink = styled.img`
  width: 75px;
  height: 50px;

  @media screen and (max-width: 1024px) {
    // background: red;
    width: 60px;
    height: 35px;
  }
`;
export const Navs = styled.div`
  // background: pink;
  display: flex;
  //   justify-content: center;
  // align-items: center;
  height: inherit;
  width: auto;
  margin-left: 0px;

  @media screen and (min-width: 1025px) {
    // background: pink;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: auto;
    margin-left: 0px;
  }
  @media screen and (min-width: 961px) and (max-width 1024px) {
    // background: pink;
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    width: 100vw;
    margin-left: 0px;
  }
  @media screen and (max-width: 960px) {
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    // height: auto;
    position: fixed;
    top: 80px; // top margin due to the length of the header
    left: ${({ toggle }) => (toggle ? 0 : "-100%")};
    transition: all 0.5s ease;
    background: #ffffff;
    // background: red;
    padding-bottom: 20px;
    z-index: 999;
  }
`;
export const BurgerMenu = styled.div`
  display: none;
  // display: flex;

  @media screen and (max-width: 960px) {
    // background: green;
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }
`;
export const ItemWrapper = styled.div`
  // background: green;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  margin-top: 5px;

  // span {
  //   margin-left: 10px;
  // }

  @media screen and (min-width: 1025px) {
    // background: green;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    // background: tomato;
    justify-content: center;
    align-items: flex-end;
    align-items: : center;
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    left: ${({ toggle }) => (toggle ? 0 : "-100%")};
  }
  @media screen and (min-width: 320px) {
    // width: 100vw;
    // background: pink;
    justify-content: center;
    align-items: space-between;
  }
`;
export const ItemWrapper2 = styled.div`
  // background: red;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  margin-top: 5px;

  // span {
  //   margin-left: 10px;
  // }

  @media screen and (min-width: 1025px) {
    // background: green;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    // background: green;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    left: ${({ toggle }) => (toggle ? 0 : "-100%")};
  }
  @media screen and (min-width: 320px) {
    // width: 100vw;
    // background: pink;
    justify-content: center;
    align-items: space-between;
  }
`;
export const ItemWrapper22 = styled.div`
  // background: tomato;
  display: flex;
  flex-direction: column;
  width: inherit;
  height: auto;
  margin-top: 5px;

  // span {
  //   margin-left: 10px;
  // }

  @media screen and (min-width: 1025px) {
    // background: green;
    width: auto;
    height: auto;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (min-width: 960px) and (max-width: 1024px) {
    // background: green;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    left: ${({ toggle }) => (toggle ? 0 : "-100%")};
  }
  @media screen and (min-width: 320px) {
    // width: 100vw;
    // background: pink;
    justify-content: center;
    align-items: space-between;
  }
`;
export const Text = styled.div`
  // background: tomato;
  display: flex;
  height: inherit;
  width: inherit;
  font-weight: 500;

  span {
    margin-left: 10px;
  }

  @media screen and (min-width: 1025px) {
    // background: green;
    width: auto;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    overflow-wrap: break-word;
    display: flex;

    // span {
    //   margin-top: 10px;
    // }
  }
  @media screen and (min-width: 961px) and (max-width: 1024px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    span {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    span {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    span {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    span {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    span {
      margin-top: 10px;
    }
  }
`;
export const Drop = styled.div`
  // background: pink;
  height: auto;
  width: auto;
  // visibility: ${({ hd }) => (hd ? "visible" : "hidden")};
  position: fixed;
  top: 0px; // set to zero because of the header box-shadow

  // @media screen and (max-width: 960px) {
  //   background: green;
  //   width: 95vw;
  //   height: auto;
  //   position: relative;
  //   display: flex;
  //   justify-content: center;
  //   align-self: center;
  // }
  @media screen and (max-width: 768px) {
    background: green;
    width: 95vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: center;
    align-self: center;
  }
`;
export const Item = styled.div`
  // background: green;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //   justify-content: center;
  align-items: center;
  //   height: inherit;
  height: 60%;
  margin-left: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #667085;
  cursor: pointer;
  // margin-top: 25px;

  :after {
    content: "";
    background: linear-gradient(289.11deg, #04487f 7.4%, #a0c559 117.27%);
    width: 40%;
    height: 3px;
    opacity: 0;
    transition: all 650ms;
    align-self: bottom;
  }
  :hover {
    :after {
      width: 100%;
      opacity: 1;
      cursor: pointer;
      //   margin-top: 50px;
    }
  }

  @media screen and (max-width: 1024px) {
    // background: red;
    height: auto;
    margin-top: 0px;
  }
  @media screen and (min-width: 960px) and (max-width: 1024px) {
    // background: black;
    // height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    height: 20%;
    width: 90%;
    justify-content: center;
    align-self: center;
    margin-left: 0px;

    :after {
      content: "";
      background: none;
      width: 0%;
      height: 0px;
      opacity: 0;
      transition: none;
      align-self: none;
    }

    :hover {
      outline: none;
      border-radius: 5px;
      box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
        rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    }
  }
`;
// export const LeftNav = styled.div`
// `;
// export const LeftNav = styled.div`
// `;
export const Item3 = styled.div`
  // background: blue;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  //   justify-content: center;
  align-items: center;
  // height: inherit;
  height: 100%;
  // height: 60%;
  margin-left: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #667085;
  cursor: pointer;
  // margin-top: 25px;
  width: auto;

  // :after {
  //   content: "";
  //   background: linear-gradient(289.11deg, #04487f 7.4%, #a0c559 117.27%);
  //   width: 40%;
  //   height: 3px;
  //   opacity: 0;
  //   transition: all 650ms;
  //   align-self: bottom;
  // }
  // :hover {
    // :after {
    //   width: 65%;
    //   opacity: 1;
    //   cursor: pointer;
    //   //   margin-top: 50px;
    // }
  // }
  @media screen and (max-width: 1024px) {
    // background: blue;
    height: auto;
    width: auto;
    justify-content: center;
    align-self: center;
    margin-left: 0px;
    margin-top: 0px;

    // :after {
    //   content: "";
    //   background: none;
    //   width: 0%;
    //   height: 0px;
    //   opacity: 0;
    //   transition: none;
    //   align-self: none;
    // }

    // :hover {
    //   outline: none;
    //   border-radius: 5px;
    //   box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    //     rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    // }
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    height: 20%;
    width: 90%;
    justify-content: center;
    align-self: center;
    margin-left: 0px;

  //   :after {
  //     content: "";
  //     background: none;
  //     width: 0%;
  //     height: 0px;
  //     opacity: 0;
  //     transition: none;
  //     align-self: none;
  //   }

  //   :hover {
  //     outline: none;
  //     border-radius: 5px;
  //     box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
  //       rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
  //   }
  // }
`;
export const ItemWrapper3 = styled.div`
  // background: red;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
  margin-left: 40px;

  :hover {
    color: black;
  }

  @media screen and (max-width: 1024px) {
    // background: green;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto; //attend
  }
  @media screen and (max-width: 960px) {
    // background: green;
    justify-content: center;
    align-items: flex-end;
  }
  @media screen and (max-width: 600px) {
    // background: green;
    justify-content: center;
    align-items: flex-end;
    margin-left: 10px;
  }
`;
export const Text3 = styled.div`
  // background: tomato;
  display: flex;
  text-align: center;
  height: inherit;
  width: auto;
  color: grey;

  @media screen and (min-width: 1025px) {
    // background: green;
    width: auto;
    // width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
  }
  @media screen and (min-width: 960px) and (max-width: 1025px) {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    justify-content: center;
    align-items: center;
  }
`;
