import styled from "styled-components";

export const LineHistoryWrapper = styled.div`
  // background: red;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  // height: 110px;
  cursor: pointer;
  transition: transform 1.5s;

  &:hover {
    box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
      0px 4px 6px -2px rgba(16, 24, 40, 0.03);
    transform: scale(1.1);
  }

  &:hover #line {
    background: #08783d;
  }
  &:hover #circle {
    background: #08783d;
    margin-top: 45px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    // background: red;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 95%;
    // height: 1800px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Line = styled.div`
  width: 2px;
  height: 110px;
  background: #eaecf0;
  margin-left: 10px;
  margin-right: 30px;
  transition: background 1s;
  @media screen and (max-width: 900px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Circle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #eaecf0;
  margin-left: -5px;
  margin-top: 10px;
  transition: background, margin-top 1s;
`;
export const LineDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Year = styled.div`
  width: 257px;
  height: 17px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: #344054;
  margin-top: 10px;
  margin-bottom: 12px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 150px;
  }
  @media screen and (max-width: 375px) {
    width: 150px;
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    width: 100px;
    font-size: 12px;
  }
`;
export const YearDesc = styled.div`
  width: 450px;
  height: 48px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #344054;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 350px;
  }
  @media screen and (max-width: 375px) {
    width: 300px;
    font-size: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 250px;
    font-size: 14px;
  }
`;
