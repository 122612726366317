import React from "react";
import styled from "styled-components";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import dotedComplete from "../../images/DotedComplete.png";
import dotedLeft from "../../images/DotedLeft.png";
import ImageProps from "./ImageProps";
import dumebiSmall from "../../images/DumebiSmall.png";
import olayemiSmall from "../../images/OlayemiSmall.png";
import gbengaSmall from "../../images/GbengaSmall.png";
import damiSmall from "../../images/DamiSmall.png";
import ucheSmall from "../../images/UcheSmall.png";
import femiSmall from "../../images/Femi_Olawale.png";
import boseSmall from "../../images/Abosede_Akinleye.png";
import afokeSmall from "../../images/Afoke_Richmond.png";
import ireneSmall from "../../images/Irene_Ebong.png";

const MgtTeamProps = ({
  mgtName,
  mgtPosition,
  mgtPhoto,
  mgtDescName,
  mgtDescPosition,
  mgtDesc,
}) => {
  return (
    <MgtContainer>
      <Header />
      <GetInTouch>
        <Content>
          <Touch>{mgtName}</Touch>
          <p>{mgtPosition}</p>
        </Content>
      </GetInTouch>
      <MgtBio>
        <BioDivider>
          <MgtLeft>
            <img src={mgtPhoto} alt="dumebi" />
          </MgtLeft>
          <MgtRight>
            <D1>{mgtDescName}</D1>
            <D2>{mgtDescPosition}</D2>
            <D3>{mgtDesc}</D3>
          </MgtRight>
        </BioDivider>
      </MgtBio>
      <MgtNavs>
        <PropsCover>
          {/* <ImageProps
            mgtImageSmall={dumebiSmall}
            mgtNameSmall="Dumebi Obodo"
            mgtPositionSmall="Director"
            personalNav="/dumebi"
          /> */}
          <ImageProps
            mgtImageSmall={olayemiSmall}
            mgtNameSmall="Olayemi Odufeso"
            mgtPositionSmall="Chief Operating Officer"
            personalNav="/olayemi"
            style={{ display: 'none'}}
          />
          <ImageProps
            mgtImageSmall={gbengaSmall}
            mgtNameSmall="Gbenga Oshinoiki"
            mgtPositionSmall="VP, Emerging Markets and New Sectors"
            personalNav="/gbenga"
          />
          <ImageProps
            mgtImageSmall={damiSmall}
            mgtNameSmall="Damilola Adegbuyi"
            mgtPositionSmall="Head, Sales"
            personalNav="/dami"
          />
          <ImageProps
            mgtImageSmall={ucheSmall}
            mgtNameSmall="Uche Okorie"
            mgtPositionSmall="Divisional Head, Business Operations Support"
            personalNav="/uche"
          />
          <ImageProps
            mgtImageSmall={femiSmall}
            mgtNameSmall="Femi Olawale"
            mgtPositionSmall="Divisional Head, Innovation"
            personalNav="/femi"
          />
          <ImageProps
            mgtImageSmall={boseSmall}
            mgtNameSmall="Abosede Akinleye"
            mgtPositionSmall="Head, People & Culture"
            personalNav="/abosede"
          />
          <ImageProps
            mgtImageSmall={afokeSmall}
            mgtNameSmall="Afoke Richmond"
            mgtPositionSmall="Head, Marketing & Communications"
            personalNav="/afoke "
          />
          <ImageProps
            mgtImageSmall={ireneSmall}
            mgtNameSmall="Irene Ebong"
            mgtPositionSmall="Accounts & Finance Manager"
            personalNav="/irene"
          />
        </PropsCover>
      </MgtNavs>
      <Footer />
    </MgtContainer>
  );
};

export default MgtTeamProps;

const MgtContainer = styled.div`
  background: #ffffff;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 80px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
// const MgtContainer = styled.div``;
export const GetInTouch = styled.div`
  flex-wrap: wrap;
  width: 100vw;
  margin-top: 80px;
  height: 400px;
  background: url(${dotedComplete}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 300px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: 250px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: 250px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    // height: 100vh;
    height: 250px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    height: 200px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
`;

export const Content = styled.div`
  //   background: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   width: 909px;
  width: auto;
  //   height: 184px;
  height: auto;

  p {
    width: 668px;
    height: 30px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #667085;
    margin-top: 10px;
  }

  @media screen and (max-width: 960px) {
    // background: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: 85vw;
      height: auto;
      // background: pink;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
    }
  }
  @media screen and (max-width: 600px) {
    p {
      width: 85vw;
      // background: pink;
    }
  }
  @media screen and (max-width: 480px) {
    p {
      width: 85vw;
      // background: pink;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Touch = styled.div`
  width: 790px;
  height: 60px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;

  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 42px;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 45px;
    line-height: 40px;
    font-size: 38px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 85vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    line-height: 40px;
  }
`;
// const MgtContainer = styled.div``;
const MgtBio = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  //   padding: 80px 120px;
  border-radius: 20px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: tomato;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const BioDivider = styled.div`
  // background: red;
  width: 90vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  // align-items: center;
  margin-top: 80px;
  margin-bottom: 80px;
  padding-bottom: 80px;

  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const MgtLeft = styled.div`
  // background: blue;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  top: 0px;

  img {
    width: 500px;
    height: 600px;
  }

  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 500px;
      height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    img {
      width: 90vw;
      height: 440px;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 90vw;
      height: 400px;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 90vw;
      height: 330px;
    }
  }
`;
const MgtRight = styled.div`
  // background: green;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  // padding-top: 80px;

  @media screen and (max-width: 960px) {
    // background: grey;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const D1 = styled.div`
  width: 100%;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 2px;

  @media screen and (max-width: 960px) {
    // background: green;
    width: 85vw;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const D2 = styled.div`
  //   background: red;
  width: 500px;
  height: 28px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #1d2939;
  margin-bottom: 22px;

  @media screen and (max-width: 960px) {
    // background: green;
    width: 85vw;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const D3 = styled.div`
  width: 630px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #1d2939;

  @media screen and (max-width: 960px) {
    // background: green;
    width: 85vw;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const MgtNavs = styled.div`
  width: 100vw;
  height: 300px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const PropsCover = styled.div`
  // background: red;
  width: 85%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  overflow: auto;
  gap: 1rem;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: red;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;
    overflow: auto;

  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// const MgtContainer = styled.div``;
// const MgtContainer = styled.div``;
// const MgtContainer = styled.div``;
// const MgtContainer = styled.div``;
// const MgtContainer = styled.div``;
// const MgtContainer = styled.div``;
