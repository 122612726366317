import React from "react";
import { Link } from "react-router-dom";
import {
  SubsidiaryContainer,
  Left,
  Pension,
  ArgonWorld,
  Content,
  P1,
} from "./MediaStyle.js";
import galleryIcon from "../../../images/galleryIcon.svg";
import blogIcon from "../../../images/blogIcon.svg";

const Media = () => {
  return (
    <SubsidiaryContainer>
      <Left>
        <Pension>
          <img src={blogIcon} alt="insight" />
          <Link to="/insights" style={{
            textDecoration: "none",
          }}>
            <Content>
              <P1>Blog</P1>
              <p>Daily blog news</p>
            </Content>
            </Link>
        </Pension>
        <ArgonWorld>
          <img src={galleryIcon} alt="event" />
          <Link to="/events" style={{
            textDecoration: "none",
          }}>
            <Content>
              <P1>Gallery</P1>
              <p>Showcasing our events</p>
            </Content>
            </Link>
        </ArgonWorld>
      </Left>
    </SubsidiaryContainer>
  );
};

export default Media;
