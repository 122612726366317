import React, { useState } from "react";
import { AboutHero } from "../components/AboutHero";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";

export const FAQ = () => {
  // Array of questions and answers
  const faqData = [
    {
      question: "What is the ChamsAccess Security Solution?",
      answer:
        "ChamsAccess Security Solution is a comprehensive security solution tailored for schools and general public use. SecureKid focuses on educational institutions, while Trackman is designed for individual tracking needs.",
    },
    {
      question: "How does SecureKid benefit schools?",
      answer:
        "SecureKid enhances student safety with live location tracking, CCTV monitoring, and real-time notifications for any irregular activities or emergencies.",
    },
    {
      question: "What can Trackman be used for?",
      answer:
        "Trackman offers versatile tracking solutions for personal use, such as monitoring family members, pets, or valuable items with real-time updates.",
    },
    {
      question: "Can I integrate both SecureKid and Trackman?",
      answer:
        "Yes, you can integrate features from both systems depending on your preferences.",
    },
    {
      question: "How do I setup an account?",
      answer:
        "Setting up is easy! Just click on the request a callback button and we will respond to all your needs.",
    },
    {
      question:
        "What are the costs associated with ChamsAccess Security Solution?",
      answer:
        "Our pricing varies based on the specific features and scale of implementation. For detailed information about costs, please contact us directly, and we will provide a tailored quote based on your requirements.",
    },
    {
      question: "Is support available after installation?",
      answer:
        "Yes, we offer ongoing support to ensure your system operates smoothly. Our team is available for troubleshooting, updates, and any additional assistance you may need after installation.",
    },
    {
      question: "How secure is my data with ChamsAccess?",
      answer:
        "We prioritize your privacy and security. Our systems use advanced encryption and secure protocols to protect your data and ensure that only authorized users have access to sensitive information.",
    },
  ];

  const [openIndex, setOpenIndex] = useState(null); // Track which question is open

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index); // Toggle the selected FAQ
  };

  const FAQBody = () => {
    return (
      <div className="faqs-container">
        <div className="faqs">
          {faqData.map((faq, index) => (
            <div className="faq-item" key={index}>
              <div className="faq-question" onClick={() => toggleFAQ(index)}>
                <div>{faq.question}</div>
                <span className={`arrow ${openIndex === index ? "open" : ""}`}>
                  <svg
                    width="12"
                    height="7"
                    viewBox="0 0 12 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 5.66666L5.41074 1.25592C5.73618 0.930482 6.26382 0.930482 6.58926 1.25592L11 5.66666"
                      stroke="#1D2939"
                      stroke-width="1.67"
                      stroke-linecap="round"
                    />
                  </svg>
                </span>
              </div>
              <div
                className={`faq-answer ${openIndex === index ? "open" : ""}`}
              >
                {openIndex === index && <p>{faq.answer}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const FAQHero = () => {
    return (
      <div className="about-hero">
        <div className="ab-content">
          <div className="ab-tag">Frequently Asked Questions</div>
          <div className="ab-title">
            Quick Answers About <br /> ChamsAccess Security Solution
          </div>
          <div className="ab-subtitle">
            Your go-to guide for understanding how our solution ensures seamless
            and effective security management.
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Header />
      <FAQHero />
      <FAQBody />
      <Footer />
    </>
  );
};

export default FAQ;
