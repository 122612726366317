import React from "react";
import { Link } from "react-router-dom";
import Marquee from "react-fast-marquee";
import gtBank from "../../../images/GTBank.png";
import centralBank from "../../../images/CentralBank.png";
import promasidor from "../../../images/Promasidor.png";
import pwc from "../../../images/PWC.png";
import accessBank from "../../../images/AccessBank.png";
import zenith from "../../../images/Zenith.png";
import gigm from "../../../images/GIGM.png";
import grand from "../../../images/Grand.png";
import fg from "../../../images/Fg.png";
import firstBank from "../../../images/FirstBank.png";
import cussons from "../../../images/Cusson.png";
import stirlingBank from "../../../images/StirlingBank.png";
import secure from "../../../images/Secure.png";
import fidelity from "../../../images/Fidelity.png";
import fcmb from "../../../images/FCMB.png";
import globusBank from "../../../images/GlobusBank.png";
import heritageBank from "../../../images/HeritageBank.png";
import keystoneBank from "../../../images/KeystoneBank.png";
import polarisBank from "../../../images/PolarisBank.png";
import stanbicBank from "../../../images/StanbicBank.png";
import uba from "../../../images/UBA.png";
import wema from "../../../images/WemaBank.png";
import {
  TestContainer,
  TrusteeLogos,
  TextDiv,
  Ready,
  Label,
  RequestButton,
  Text,
  ImageWrapper,
  Items,
} from "./TrusteeStyle";

const Testimonial = () => {
  return (
    <TestContainer>
      <Label>Testimonials</Label>
      {/* <Text>What our clients say about us</Text> */}
      <TrusteeLogos>
        <Text>Trusted by top companies in the markets we serve</Text>
        <ImageWrapper>
          <Marquee
            speed={100}
            pauseOnHover={true}
            style={{ cursor: "pointer" }}
          >
            <Items>
              <img src={gtBank} alt="gtb" />
            </Items>
            <Items>
              <img src={centralBank} alt="central-bank" />
            </Items>
            <Items>
              <img src={promasidor} alt="promasidor" />
            </Items>
            <Items>
              <img src={pwc} alt="pwc" />
            </Items>
            <Items>
              <img src={accessBank} alt="access-bank" />
            </Items>
            <Items>
              <img src={zenith} alt="zenith" />
            </Items>
            <Items>
              <img src={gigm} alt="gigm" />
            </Items>
            <Items>
              <img src={grand} alt="grand" />
            </Items>
            <Items>
              <img src={fg} alt="fg" />
            </Items>
            <Items>
              <img src={firstBank} alt="first-bank" />
            </Items>
            <Items>
              <img src={cussons} alt="cussons" />
            </Items>
            <Items>
              <img src={stirlingBank} alt="sterling-bank" />
            </Items>
            <Items>
              <img src={secure} alt="secure-id" />
            </Items>
            <Items>
              <img src={fidelity} alt="fidelity" />
            </Items>
            <Items>
              <img src={fcmb} alt="fcmb" />
            </Items>
            <Items>
              <img src={globusBank} alt="globus-bank" />
            </Items>
            <Items>
              <img src={heritageBank} alt="heritage-bank" />
            </Items>
            <Items>
              <img src={keystoneBank} alt="keystone-bank" />
            </Items>
            <Items>
              <img src={polarisBank} alt="polaris-bank" />
            </Items>
            <Items>
              <img src={stanbicBank} alt="stanbic-bank" />
            </Items>
            <Items>
              <img src={uba} alt="uba-bank" />
            </Items>
            <Items>
              <img src={wema} alt="wema-bank" />
            </Items>
          </Marquee>
        </ImageWrapper>
      </TrusteeLogos>
      <TextDiv>
        <Ready>Ready to connect with us?</Ready>
        <p>
          Let's explore existing opportunities or create bespoke solutions to
          serve your specific business needs.
        </p>
        <Link to="/request-a-callback" style={{ textDecoration: "none" }}>
          <RequestButton>Request a call back</RequestButton>
        </Link>
      </TextDiv>
    </TestContainer>
  );
};

export default Testimonial;
