import React from "react";

export const AboutHero = () => {
  return (
    <div className="about-hero">
      <div className="ab-content">
        <div className="ab-tag">About us</div>
        <div className="ab-title">
          Why we built <span className="txt-green">ChamsAccess</span> <br />Security
          Solution
        </div>
        <div className="ab-subtitle">
          We have designed the right solution that ensures efficient management
        </div>
      </div>
    </div>
  );
};
