import styled from "styled-components";
import heroBackground from "../../images/hero-background.svg";

export const ImpactContainer = styled.div`
  background: #ffffff;
  // background: red;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 80px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    align-items: flex-start;
    height: auto;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;

export const Hero1 = styled.div`
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  width: inherit;
  height: 780px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: tomato;
    width: 100%;
    height: inherit;
    // height: 7800px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;

export const HeroContent = styled.div`
  // background: red;
  width: 50%;
  height: 100%;
  //   height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100%;
    // height: 1200px;
    height: 500px;
    margin-left: 0px;
    display: flex;
    align-items: center;

    // [data-aos-delay] {
    //   transition-delay: 0s !important;
    // }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 0px;
    margin-top: 80px;
    height: inherit;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    height: 400px;
  }
  @media screen and (max-width: 375px) {
    height: 400px;
  }
  @media screen and (max-width: 320px) {
    height: 500px;
  }
`;

export const OurImpact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 15px 50px;
  width: 120px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  background: #f6eaeb;
  margin-bottom: 20px;
  cursor: pointer;
  text-wrap: nowrap;
  transition: background, color 1s;

  width: 72px;
  height: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;

  /* Secondary/500 */

  color: #a92f34;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const ContentHeader = styled.div`
  //   background: red;
  width: 540px;
  height: 126px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #08783d;
    text-transform: capitalize;
  }

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100%;
    justify-content: center;
    align-items: center;
    font-size: 62px;
    word-wrap: break-word;
    text-align: center;
  }

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 0px;
    width: 350px;
  }
  @media screen and (max-width: 320px) {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 0px;
    width: 250px;
  }
`;

export const ContentDescription = styled.div`
  width: 446px;
  height: 84px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 90%;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin-top: 40px;
  }
`;

export const ContentButton = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  width: 200px;
  height: 48px;
  background: #08783d;
  border-radius: 10px;
  font-family: Komet;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
  cursor: pointer;
  transition: width 1s;

  :hover {
    width: 210px;
  }

  @media screen and (max-width: 960px) {
    margin-top: 80px;
    margin-bottom: 40px;
  }
`;

export const HeroImage = styled.div`
  position: relative;
  // background: purple;
  background-image: url(${heroBackground});
  // height: 500px;
  background-position: bottom;
  // background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  //   background-size: 650px 700px;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-right: 120px;

  img {
    width: 525.37px;
    height: 617px;
    margin-bottom: 24px;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    margin-right: 0px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;

    img {
      width: 353.99px;
      height: 332px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
    // background: green;
    img {
      width: 353.99px;
      height: 332px;
      margin-bottom: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    background-image: url(${heroBackground});
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-x: hidden;

    img {
      width: 333.99px;
      height: 322px;
      width: 90%;
      height: 400px;
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 375px) {
    background-image: url(${heroBackground});
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-x: hidden;

    img {
      width: 333.99px;
      height: 322px;
      width: 90%;
      height: 362px;
    }
  }
  @media screen and (max-width: 320px) {
    background-image: url(${heroBackground});
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: contain;
    overflow-x: hidden;

    img {
      width: 333.99px;
      height: 322px;
      width: 90%;
      height: 312px;
    }
  }
`;

// export const ImpactContainer = styled.div``;
export const Verification = styled.div`
width: 100vw;
height: 800px;
background: #fcfcfd;
display: flex;
justify-content: center;
align-items: center;

@media screen and (min-width: 961px) and (max-width: 1200px) {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 960px) {
  // background: red;
  width: 100vw;
  height: auto;
  display: flex:
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  // background: red;
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 480px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 375px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 320px) {
  width: 100vw;
  overflow-x: hidden;
}
`;
export const VerificationWrapper = styled.div`
  // background: red;
  width: 85%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const W1 = styled.div`
  // background: tomato;
  width: 40%;
  height: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 80%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      // height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;

    img {
      width: 570px;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;

    img {
      width: 470px;
      height: 470px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;

    img {
      width: 85%;
      height: 370px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;

    img {
      width: 90%;
      height: 360px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: white;
    width: 100vw;
    img {
      width: 90%;
      height: 300px;
    }
  }
`;
export const W2 = styled.div`
  // background: blue;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 40%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: auto;
    height: auto;
    display: flex;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
`;
export const W2Description = styled.div`
  // background: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  p {
    width: 422px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #667085;
    margin-bottom: 23px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    p {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    p {
      // background: red;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      // background: blue;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // height: 80px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;

    p {
      // background: blue;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      // background: blue;
    }
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      // background: blue;
    }
  }
`;
export const W21 = styled.div`
  width: 422px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VerifyOption = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Options = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ImpactContainer = styled.div``;
export const Atm = styled.div`
  width: 100vw;
  height: 800px;
  background: #f6eaeb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const AtmWrapper = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: hidden;
  }
`;
export const A1 = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 80%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 70%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 60%;
      height: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 560px;
      height: 552px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80vw;
      height: 452px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 80vw;
      height: 352px;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 80vw;
      height: 320px;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 80vw;
      height: 260px;
      height: auto;
    }
  }
`;
export const A2 = styled.div`
  // background: blue;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
    // }background-color: red;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
`;
export const A2Description = styled.div`
  //   background: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  p {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-bottom: 26px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    p {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      // background: blue;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    align-items: flex-start;
    overflow-y: hidden;
    // background: white;

    p {
      width: 100%;
      font-size: 18px;
      // background: red;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    overflow-y: hidden;
    // background: white;

    p {
      width: 100%;
      font-size: 18px;
      // background: red;
    }
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    overflow-y: hidden;
    // background: white;

    p {
      width: 280px;
    }
  }
`;
export const A21 = styled.div`
  // background: blue;
  width: 90%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AtmOption = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptionsAtm = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ImpactContainer = styled.div``;
export const Credit = styled.div`
width: 100vw;
height: 800px;
background: #fcfcfd;
display: flex;
justify-content: center;
align-items: center;

@media screen and (min-width: 961px) and (max-width: 1200px) {
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
}
@media screen and (max-width: 960px) {
  // background: red;
  width: 100vw;
  height: auto;
  display: flex:
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 768px) {
  // background: red;
  width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
}
@media screen and (max-width: 600px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 480px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 375px) {
  width: 100vw;
  overflow-x: hidden;
}
@media screen and (max-width: 320px) {
  width: 100vw;
  overflow-x: hidden;
}
`;
export const CreditWrapper = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const C1 = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 80%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      // height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;

    img {
      width: 570px;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;

    img {
      width: 470px;
      height: 470px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;

    img {
      width: 85%;
      height: 370px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;

    img {
      width: 90%;
      height: 360px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: white;
    width: 100vw;
    img {
      width: 90%;
      height: 300px;
    }
  }
`;
export const C2 = styled.div`
  // background: blue;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 40%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: auto;
    height: auto;
    display: flex;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
`;
export const C2Description = styled.div`
  //   background: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;

  p {
    width: 422px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #667085;
    margin-bottom: 23px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
    p {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
    p {
      // background: red;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
    p {
      // background: blue;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
`;
export const C21 = styled.div`
  width: 422px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const CreditOption = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptionsCredit = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ImpactContainer = styled.div``;
