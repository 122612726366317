import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import NewsletterUno from "../../../images/Newsletter1.svg";
import "./newsletter.css";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const sendEmail = async (emailAddress) => {
  try {
    const url = "https://adminapi.chamsaccess.com/api/emails";
    const response = await axios({
      headers: {
        "Content-Type": "application/json",
        Accept: "*",
        mode: "cors",
      },
      url: url,
      method: "post",
      data: {
        email: emailAddress,
      },
    });
    return response;
  } catch (error) {
    console.log("An error occured while trying to add email.", error);
  }
};
export const NewsLetterModalUno = () => {
  const navigate = useNavigate();
  const [isNotNull, setIsNotNull] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(true);
  const [emailAddress, setEmailAddress] = useState(null);
  const [subscribed, setSubscribed] = useState(false);

  useEffect(() => {
    localStorage.setItem("subscribed", subscribed);
  }, [subscribed]);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubscribe = async (e) => {
    e.preventDefault();
    if (emailAddress) {
      setIsNotNull(true);
      setLoading(true);
    }
    if (!emailAddress) {
      return;
    }
    const subscribe = await sendEmail(emailAddress);
    handleClose();
    if (subscribe?.status === 200) {
      // setSubscribed(true);
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Email Sent",
        text: "Thank you for subscribing for our newsletter.",
        timer: 2000, 
      showConfirmButton: false,
        willClose: () => {
          navigate("/"); // Navigate to home after the modal closes
        },
      });
    } else {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Oops...",
        text: "Email failed to submit",
        showConfirmButton: true,
      });
    }
  };
  return (
    <React.Fragment>
      <Dialog maxWidth={"900px"} open={open} onClose={handleClose}>
        <DialogContent>
          <div className="newsletter-content">
            <img
              className="newsletterImage"
              src={NewsletterUno}
              alt="Newsletter Envelope"
            />
            <div className="newsletter-content-text">
              <svg
                className="close-newsletter-dialog"
                onClick={() => handleClose()}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6516 10.6516C14.9445 10.3588 14.9445 9.88388 14.6516 9.59099C14.3588 9.2981 13.8839 9.2981 13.591 9.59099L12 11.182L10.409 9.59099C10.1161 9.2981 9.64124 9.2981 9.34835 9.59099C9.05546 9.88388 9.05546 10.3588 9.34835 10.6517L10.9393 12.2426L9.34835 13.8336C9.05546 14.1265 9.05546 14.6014 9.34835 14.8943C9.64124 15.1872 10.1161 15.1872 10.409 14.8943L12 13.3033L13.591 14.8943C13.8839 15.1872 14.3588 15.1872 14.6517 14.8943C14.9445 14.6014 14.9445 14.1265 14.6517 13.8336L13.0607 12.2426L14.6516 10.6516Z"
                  fill="#399364"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
                  fill="#399364"
                />
              </svg>

              {subscribed ? (
                <div className="newsletter-content-text-title">
                  Thank you for subscribing to our newsletter!
                </div>
              ) : (
                <>
                  <div className="newsletter-content-text-title">
                    Be The First to Know!
                  </div>
                  <div className="newsletter-content-text-sub">
                    Join our community of forward-thinkers by subscribing to our
                    monthly newsletter.
                  </div>
                </>
              )}

              {subscribed ? (
                <div className="newsletter-actions">
                  <button
                    className="newsletter-button"
                    onClick={() => handleClose()}
                  >
                    Close
                  </button>
                </div>
              ) : (
                <form className="newsletter-actions" onSubmit={handleSubscribe}>
                  <input
                    required
                    type="text"
                    placeholder="Enter you email address"
                    id="email"
                    className="newsletter-input"
                    onChange={(e) => setEmailAddress(e.target.value)}
                  />
                  <button
                    disabled={loading && isNotNull}
                    type="submit"
                    className="newsletter-button"
                    // onClick={() => handleSubscribe()}
                  >
                    {loading ? "Loading......" : "Sign up today"}
                  </button>
                </form>
              )}
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

// export const NewsLetterModalDos = () => {
//   const [loading, setLoading] = useState(false);
//   const [open, setOpen] = React.useState(true);
//   const [emailAddress, setEmailAddress] = useState(null);
//   const [subscribed, setSubscribed] = useState(false);

//   const handleClickOpen = () => {
//     setOpen(true);
//   };
//   const handleClose = () => {
//     setOpen(false);
//   };
//   const handleSubscribe = () => {
//     setLoading(true);
//     if (!emailAddress) {
//       return;
//     }
//     const subscribe = sendEmail(emailAddress);
//     if (subscribe.status === "200") {
//       setSubscribed(true);
//     }
//   };
//   return (
//     <React.Fragment>
//       <Dialog maxWidth={"608px"} open={open} onClose={handleClose}>
//         {/* <DialogTitle>Subscribe</DialogTitle> */}
//         <DialogContent>
//           <div className="newsletter-content-dos">
//             <img src={NewsletterDos} alt="Newsletter Envelope" />
//             <div className="newsletter-content-text">
//               <svg
//                 className="close-newsletter-dialog-dos"
//                 onClick={() => handleClose()}
//                 width="24"
//                 height="24"
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M14.6516 10.6516C14.9445 10.3588 14.9445 9.88388 14.6516 9.59099C14.3588 9.2981 13.8839 9.2981 13.591 9.59099L12 11.182L10.409 9.59099C10.1161 9.2981 9.64124 9.2981 9.34835 9.59099C9.05546 9.88388 9.05546 10.3588 9.34835 10.6517L10.9393 12.2426L9.34835 13.8336C9.05546 14.1265 9.05546 14.6014 9.34835 14.8943C9.64124 15.1872 10.1161 15.1872 10.409 14.8943L12 13.3033L13.591 14.8943C13.8839 15.1872 14.3588 15.1872 14.6517 14.8943C14.9445 14.6014 14.9445 14.1265 14.6517 13.8336L13.0607 12.2426L14.6516 10.6516Z"
//                   fill="#399364"
//                 />
//                 <path
//                   fill-rule="evenodd"
//                   clip-rule="evenodd"
//                   d="M12 1.25C6.06294 1.25 1.25 6.06294 1.25 12C1.25 17.9371 6.06294 22.75 12 22.75C17.9371 22.75 22.75 17.9371 22.75 12C22.75 6.06294 17.9371 1.25 12 1.25ZM2.75 12C2.75 6.89137 6.89137 2.75 12 2.75C17.1086 2.75 21.25 6.89137 21.25 12C21.25 17.1086 17.1086 21.25 12 21.25C6.89137 21.25 2.75 17.1086 2.75 12Z"
//                   fill="#399364"
//                 />
//               </svg>

//               {subscribed ? (
//                 <div className="newsletter-content-text-title">
//                   Thank you for subscribing to our newsletter!
//                 </div>
//               ) : (
//                 <>
//                   <div className="newsletter-content-text-title">
//                     Be The First to Know!
//                   </div>
//                   <div className="newsletter-content-text-sub">
//                     Join our community of forward-thinkers by subscribing to our
//                     monthly newsletter.
//                   </div>
//                 </>
//               )}

//               {subscribed ? (
//                 <div>Thank you for subscribing to our newsletter!</div>
//               ) : (
//                 <div className="newsletter-actions">
//                   <input
//                     type="text"
//                     placeholder="Enter you email address"
//                     id="email"
//                     className="newsletter-input"
//                     onChange={(e) => setEmailAddress(e.target.value)}
//                   />
//                   <button
//                     disabled={loading}
//                     type="submit"
//                     className="newsletter-button"
//                     onClick={() => handleSubscribe()}
//                   >
//                     {loading ? "Loading......" : "Sign up today"}
//                   </button>
//                 </div>
//               )}
//             </div>
//           </div>
//           {/* <DialogContentText>
//             To subscribe to this website, please enter your email address here.
//             We will send updates occasionally.
//           </DialogContentText> */}
//           {/* <TextField
//             autoFocus
//             required
//             margin="dense"
//             id="name"
//             name="email"
//             label="Email Address"
//             type="email"
//             fullWidth
//             variant="standard"
//           /> */}
//         </DialogContent>
//         {/* <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button type="submit">Subscribe</Button>
//         </DialogActions> */}
//       </Dialog>
//     </React.Fragment>
//   );
// };
