import React, { useEffect, useState } from "react";
import styled from "styled-components";
const HrPageCard = ({
  sampleImage,
  cardHeading,
  Description,
  color = "#FFFFFF",
  align = 'flex-start',
  pad = "0px"
}) => {
  const [padding, setPadding] = useState(pad);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 780) {
        setPadding("0px");
      } else {
        setPadding(pad);
      }
    };

    handleResize(); // call initially
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [pad]);

  return (
    <CardContainer style={{ background: color, alignItems: align }}>
      <img src={sampleImage} alt="page-card" />
      <Text pad={padding}>{cardHeading}</Text>
      <div>{Description}</div>
    </CardContainer>
  );
};

export default HrPageCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 25%;
  // height: 200px;
  background: #f9fafb;
  border-radius: 30px;
  border: 1px;
  cursor: pointer;
  transition: transform 1s;
  margin: 20px;
  padding-top: 10px;

  // img {
  //   margin-left: 30px;
  //   margin-right: 30px;
  //   margin-top: 40px;
  //   margin-bottom: 20px;
  // }

  p {
    font-family: "Komet";
    font-size: 16px;
    line-height: 24px;
    text-wrap: nowrap;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 30%;
    margin: 10px;

    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 960px) {
    margin: 10px;
    p {
      width: 85%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 42%;
    line-height: 23px;
    margin: 10px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 10px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    margin: 15px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: 320px;
    margin: 15px;
    p {
      width: 85%;
      margin-top: 15px;
    }
  }
`;

const Text = styled.p`
  // width: 301px;
  // height: 40px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  color: #101828;
  // margin-left: 30px;
  // margin-right: 30px;
  // padding-left: ${props => props.pad};

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
    line-height: 25px;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    height: auto;
    line-height: 25px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    width: 220px;
    line-height: 26px;
    margin-bottom: 10px;
  }
`;
