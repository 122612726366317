import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import sampleImage from "../../images/TestImage.png";
import arrowForward from "../../images/arrowForward.svg";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { RiWindowsFill } from "react-icons/ri";

const EventCardCarousel = ({
  eventTitleImage,
  eventTitle,
  description,
  eventContent,
  name,
  learnMore,
  // id,
}) => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = "https://blogapi.chamsaccess.com/api/v1/visitors/events";
  // const url = "http://localhost:5050/api/v1/visitors/events";
  // function to get all blogs from the database
  const fetchEvents = async () => {
    const response = await axios.get(url);
    const mainData = response.data.data;
    // sort the data to show the most recent event first
    const sortedEventData = mainData.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setEventData(sortedEventData);
    setLoading(false);
  };
  // console.log(eventData);

  const nav = useNavigate();
  const navigate = (id) => {
    nav(`/event-detail/${id}`);
    window.location.reload();
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    fetchEvents();
  }, []);
  return (
    <>
      {loading ? (
        <ClipLoader color="green" size={30} />
      ) : (
        <>
          {eventData.map((event) => (
            <div key={event._id}>
              <CardWrapper>
                <ImageDiv>
                  <img
                    src={`https://blogapi.chamsaccess.com/uploads/${event.titleImage}`}
                    // src={`http://localhost:5050/uploads/${event.titleImage}`}
                    alt={event.eventTitle}
                  />
                </ImageDiv>
                <ContentDiv>
                  <Title>{event.eventTitle}</Title>
                  <Description>{event.eventContent.replace(/<\/?[^>]+(>|$)/g, "")}</Description>
                  <Nav>
                    <Text onClick={() => navigate(event._id)}>View More</Text>
                    <ArrowUp>
                      <img src={arrowForward} alt="up" />
                    </ArrowUp>
                  </Nav>
                </ContentDiv>
              </CardWrapper>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default EventCardCarousel;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  // width: 300px;
  // height: 537px;
  width: 293.42px;
  height: 344.77px;
  background: #fcfcfd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
  0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  transition: transform 1s;
  margin: 20px;
  // background: red;

  :hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 960px) {
    width: 293.42px;
    height: 400px;
  }
  @media screen and (max-width: 768px) {
    width: 293.42px;
    height: 344.77px;
    margin: 10px;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    width: 293.42px;
    height: 344.77px;
    margin: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 293.42px;
    height: 344.77px;
    margin: 15px;
  }
  @media screen and (max-width: 375px) {
    width: 293.42px;
    height: 344.77px;
    margin: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 293.42px;
    height: 344.77px;
    margin: 15px;
  }
`;
const ImageDiv = styled.div`
  // background: green;
  width: 100%;
  // height: 45%;
  height: 183.39px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
`;
const ContentDiv = styled.div`
  // background: purple;
  // width: inherit;
  height: 55%;
  height: 161.38px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20.54px 20.54px;

  @media screen and (max-width: 768px) {
    // background-color: yellow;
    padding: 16px 16px;
  }
`;
const Title = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 17.6054px;
  color: #344054;
  text-transform: capitalize;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
    font-size: 16px;
  }
  @media screen and (max-width: 480px) {
    font-size: 14px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Description = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  height: 70px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 13.2041px;
  line-height: 21px;
  color: #667085;

  white-space: wrap;
  // text-overflow: ellipsis;
  max-width: 300px;
  max-height: 145px;
  overflow: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 13px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Content = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Nav = styled.div`
  // background: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
  height: 30px;
  cursor: pointer;
`;
const Text = styled.div`
  width: auto;
  height: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #08783d;

  :hover {
    color: green;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const ArrowUp = styled.div`
  // background-color: red;
  width: 12.67px;
  height: 12.67px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// const CardWrapper = styled.div``;
// const CardWrapper = styled.div``;
