import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import femi from "../../images/Femi_Olawale.png";
import ScrollToTop from "react-scroll-to-top";

const Femi = () => {
    useEffect(() => {
        window.scroll({
            top: 0,
            left: 100,
            behavior: "smooth",
        });
    }, []);
    var desc = (
        <div>
            A seasoned software development professional with a proven track record in creating innovative solutions and leading dynamic teams, Femi brings to the table a wealth of experience in computer programming, database management, web design, and development, making him a versatile and accomplished Lead Software Developer. <br/><br/>

            With a career spanning almost 2 decades, Femi has built proficiency in a wide range of programming languages, including C#, VB .Net, .Net Core, Java, NodeJS, React, and JavaScript. His expertise extends to database management systems such as MS SQL Server and MySQL, and he excels in web design and development using technologies like ASP .Net, PHP, Angular, and React. Femi is also adept at Search Engine Optimization and possesses advanced skills in data analysis with R, Python, and PowerBI. He obtained his B.Sc. Mathematical Sciences (Computer Science Option) from the University of Agriculture, Abeokuta. His thirst for knowledge and personal development is evident in the series of trainings he has
            undergone after his first degree, in areas such as Software Engineering (NIIT), Emotional Intelligence, IT Service Management, Project Management, Maximum Productivity, etc.

            <br/><br/>Prior to joining ChamsAccess Ltd as Lead, Software Development, Femi had worked with other reputable organizations such as Universal Embedded Technologies Nigeria Ltd (Software Development), Card Centre Nigeria Ltd (Software Developer), amongst others. Since joining the company, Femi has championed many innovative, best-in-class solutions and reached unprecedented milestones. He is currently the Head, Product Innovations.
        </div>
    );
    return (
        <DumebiContainer>
            <MgtTeamProps
                mgtName="Femi Olawale"
                mgtPosition="Head, Innovation"
                mgtPhoto={femi}
                mgtDescName="Femi Olawale"
                mgtDescPosition="Head, Innovation"
                mgtDesc={desc}
            />
            <ScrollToTop smooth height="13" width="15" color="green" />
        </DumebiContainer>
    );
};

export default Femi;

const DumebiContainer = styled.div`
 // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
