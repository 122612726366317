import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import afoke from "../../images/Afoke_Richmond.png";
import ScrollToTop from "react-scroll-to-top";

const Afoke = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  var desc = (
    <div>
      Afoke Richmond is a seasoned Marketing Communications professional boasting over nine years of dedicated experience in the field. She holds a Bachelor of Science degree in Mass Communication, graduating with Second Class Honours Upper Division from Delta State University in 2011. Additionally, she obtained a Master of Information Management from Ahmadu Bello University, Zaria.

      <br/><br/>Commencing her career journey in 2013, Afoke worked as a call centre agent with Etisalat Nigeria. Demonstrating a penchant for broader horizons, she transitioned to the role of Corporate Communications Officer at Capitalfield Investment Group Ltd in 2014, where her ardor for Brands, Marketing, and Communications flourished.

      <br/><br/>Throughout her professional trajectory, Afoke has continuously invested in her development, amassing a wealth of expertise and garnering several certifications and trainings. Her dedication and commitment led to her ascension to the position of Group Head, Corporate Communications at Capitalfield Group, where her contributions significantly impacted the company's bottom line. Presently, she spearheads the Brand & Marketing Communications department at ChamsAccess Ltd.

      <br/><br/>A lifelong learner, Afoke's interests span diverse areas, including Brand Positioning, Integrated Marketing, Digital Marketing, Copywriting, Media Relations, Investor Relations, Stakeholder Management and Precision Marketing, underscoring her commitment to staying abreast of industry trends and advancements.
    </div>
  );
  return (
    <DumebiContainer>
      <MgtTeamProps
        mgtName="Afoke Richmond"
        mgtPosition="Head, Marketing & Communications"
        mgtPhoto={afoke}
        mgtDescName="Afoke Richmond"
        mgtDescPosition="Head, Marketing & Communications"
        mgtDesc={desc}
      />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </DumebiContainer>
  );
};

export default Afoke;

const DumebiContainer = styled.div`
 // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
