import styled from 'styled-components'


export const MainWrapper = styled.div`
// width: 100vw;
height: auto;
background: #ffffff;
display: flex;
flex-direction: column;
overflow-x: hidden;
`;