import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";

import {
  ContactUs,
  GetInTouch,
  Content,
  Contact,
  Touch,
  FormContainer,
  FormWrapper,
  ContactInfo,
  InnerWrapper,
  FormHead,
  Form,
  Name,
  Contact1,
  Message,
  Button,
  Err,
} from "./ContactUsStyle";
import ContactCard from "./ContactCard";
import salesIcon from "../../images/SalesIcon.svg";
import supportIcon from "../../images/SupportIcon.svg";
import whereIcon from "../../images/WhereIcon.svg";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

const AboutUs = () => {
  // declaring the states
  // const [name, setName] = useState("");
  // const [number, setNumber] = useState("");
  // const [email, setEmail] = useState("");
  // const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  // const submitHandler = async () => {
  //   // const url = "http://localhost:2000/api/email";
  //   if (!name || !number || !email || !message) {
  //     return toast.error("All fields must be filled");
  //   }
  //   try {
  //     setLoading(true);
  //     // const { data } = await axios.post(`http://localhost:2000/api/send-message`, {
  //     const { data } = await axios.post(`/api/send-message`, {
  //       name,
  //       number,
  //       email,
  //       message,
  //     });
  //     setLoading(false);
  //     setName("");
  //     setNumber("");
  //     setEmail("");
  //     setMessage("");
  //     toast.success(data.message);
  //   } catch (error) {
  //     setLoading(false);
  //     toast.error(
  //       error.response && error.response.data.message
  //         ? error.response.data.message
  //         : error.message
  //     );
  //   }
  // };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const dataSchema = yup.object().shape({
    name: yup.string().required("Name field is required."),
    number: yup
      .string()
      .required("required")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(11, "too long"),
    email: yup.string().required("Email field is required."),
    message: yup.string().required("Message field is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (value) => {
    // console.log(value);

    const { name, number, email, message } = value;
    try {
      setLoading(true);
      // const url = `api/send-message`;
      // const url = "http://localhost:2000/api/send-message";
      const url = "https://adminapi.chamsaccess.com/api/contacts";
      // const url = `${process.env.REACT_APP_BASE_URL}/api/contacts`;

      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: {
          name,
          number,
          email,
          message,
        },
      });
      if (response.status === 200) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Message sent",
          showConfirmButton: true,
          text: "Thanks for contacting us, we'll get back to you.",
        }).then(() => {
          console.log(response.status);
          navigate("/");
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        // console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      // console.error(error.message);
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <ContactUs>
      <Header />
      <GetInTouch>
        <Content>
          <Link
            to="contact"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1500}
          >
            <Contact>Contact Us</Contact>
          </Link>

          <Touch>
            Get in <span>touch</span>
          </Touch>
          <p>Generate Payment and Schedule Reports, easily</p>
        </Content>
      </GetInTouch>
      <FormContainer id="contact">
        <FormWrapper>
          <FormHead>Send us a message</FormHead>
          <p>
            Let us know how we can reach you and our team will get in touch.
          </p>
          {/* <ToastContainer position="top-center" limit={3} /> */}
          <Form>
            <Name>
              <label>
                First name
                <Err>{errors?.name?.message}</Err>
                <input
                  type="text"
                  id="name"
                  placeholder="e.g ubani"
                  // value={name}
                  // onChange={(e) => setName(e.target.value)}
                  {...register("name")}
                />
              </label>
            </Name>
            <Contact1>
              <label>
                Contact number
                <Err>{errors?.number?.message}</Err>
                <input
                  type="tel"
                  id="number"
                  placeholder="e.g 07012345678"
                  // value={number}
                  // onChange={(e) => setNumber(e.target.value)}
                  {...register("number")}
                />
              </label>
              <label>
                Email address
                <Err>{errors?.email?.message}</Err>
                <input
                  type="email"
                  id="email"
                  placeholder="e.g ubani@gmail.com"
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  {...register("email")}
                />
              </label>
            </Contact1>
            <Message>
              <label>
                Message
                <Err>{errors?.message?.message}</Err>
                <textarea
                  type="text"
                  id="message"
                  placeholder="e.g here goes my message...."
                  // value={message}
                  // onChange={(e) => setMessage(e.target.value)}
                  {...register("message")}
                />
              </label>
            </Message>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            >
              {loading ? "Loading......" : "Submit"}
            </Button>
          </Form>
        </FormWrapper>
      </FormContainer>
      <ContactInfo>
        <InnerWrapper>
          <ContactCard
            Icon={salesIcon}
            cardHeading="Talk to sales"
            cardDescription="An actual human will respond to you"
            email="info@chamsaccess.com"
            phone="+234 00 000 0000 | +234 00 000 0000"
          />
          <ContactCard
            Icon={supportIcon}
            cardHeading="Talk to support"
            cardDescription="For quick help and troubleshooting"
            email="bos@chamsaccess.com"
            phone="01-2914 988 | +234 813 4799 582"
          />
          <ContactCard
            Icon={whereIcon}
            cardHeading="Where we are"
            cardDescription="Where we are located"
            email="8, Louis Solomon Close, Victoria Island"
            phone="Lagos State"
          />
        </InnerWrapper>
      </ContactInfo>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </ContactUs>
  );
};

export default AboutUs;
