import styled from "styled-components";
import dotedComplete from "../../images/DotedComplete.png";
import dotedLeft from "../../images/DotedLeft.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const NewsCotainer = styled.div`
  // background: red;
  // height: 100vh;
  height: auto;
  // width: 100vw;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const EventCardWrapper = styled.div`
  background: #ffffff;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
  justify-content: center;
  align-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const EventContentWrapper = styled.div`
  // background-color: red;
  background: inherit;
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow-x: hidden;
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const ImageCover = styled.div`
  // background-color: red;
  width: 85vw;
  height: 442.92px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0px;
  justify-content: start;
  overflow-x: hidden;

  img {
    // width: 70%;
    // height: auto;
    width: 967px;
    height: 402.92px;
    // object-fit: contain;
    object-fit: cover;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;

    img {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;

export const Label = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 75px;
  height: 28px;
  background: #ebf5f0;
  border-radius: 97px;
  cursor: pointer;
  margin-bottom: 12px;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #08783d;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Title = styled.div`
  // background-color: blue;
  width: 65%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 24px;
  text-transform: capitalize;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    font-size: 30px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    font-size: 24px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Description = styled.div`
  width: 65%;
  height: 60px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #667085;
  margin-bottom: 64px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Content = styled.div`
  // background-color: red;
  display: flex;
  justify-content: center;
  width: 65%;
  margin-bottom: 64px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  text-align: center;
  color: #667085;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    font-size: 18px;
    line-height: 28px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    line-height: 26px;
    font-size: 16px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ImageCaption = styled.div`
  // background-color: red;
  width: 75%;
  width: 967px;
  display: flex;
  justify-content: left;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  margin-top: 10px;
  text-transform: capitalize;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 90%;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const EventCarouselWrapper = styled.div`
  // background-color: grey;
  width: 90%;
  height: auto;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    // width: 90%;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const OtherEvents = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  gap: 40px;
  width: 100vw;
  height: auto;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const OtherCarouselWrapper = styled.div`
  width: 90%;
  // background-color: blue;
  height: 500;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const OtherTitle = styled.div`
  width: 179px;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #000000;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;

// export const NewsCotainer = styled.div``;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 350px;
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 59px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 40px;
    line-height: 50px;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 23px 33.5px;
  margin-top: 32px;
  margin-bottom: 50px;
  background: #08783d;
  border-radius: 10px;
  width: 210px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
  @media screen and (max-width: 375px) {
    margin: 0px;
  }
`;
// export const NewsCotainer = styled.div``;
