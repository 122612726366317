import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import bose from "../../images/Abosede_Akinleye.png";
import ScrollToTop from "react-scroll-to-top";

const Abosede = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  var desc = (
    <div>
      As a seasoned Human Resources Professional with over 15 years of dedicated service in the Technology industry, Abosede Omowumi Akinleye has had the privilege of serving in diverse roles encompassing engineering, procurement, storekeeping, and HR generalist functions. Her career journey has equipped her with a profound understanding of the intricacies of this dynamic sector.

      <br/><br/>Currently serving as a Human Resources Manager, her core focus lies in talent management, culture reshaping, and leadership development. She is deeply passionate about fostering a workplace environment where individuals can thrive, organizations can flourish, and people can realize their full potential. This commitment to nurturing both talent and culture is at the heart of her professional ethos.

      <br/><br/>She is a proud member of the Chartered Institute of Personnel Management (CIPM) and a Chartered Accountant, underscoring her dedication to maintaining the highest standards of professionalism and ethical conduct. Continuous learning is a cornerstone of her approach, and she actively participates in HR workshops and training programs within Nigeria to stay abreast of the latest industry trends and best practices. Abosede is also a member of the Institute of Chartered Accountants of Nigeria.
    </div>
  );
  return (
    <DumebiContainer>
      <MgtTeamProps
        mgtName="Abosede Akinleye"
        mgtPosition="Head, People & Culture"
        mgtPhoto={bose}
        mgtDescName="Abosede Akinleye"
        mgtDescPosition="Head, People & Culture"
        mgtDesc={desc}
      />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </DumebiContainer>
  );
};

export default Abosede;

const DumebiContainer = styled.div`
 // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
