import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import irene from "../../images/Irene_Ebong.png";
import ScrollToTop from "react-scroll-to-top";

const Irene = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  var desc = (
    <div>
      
    </div>
  );
  return (
    <DumebiContainer>
      <MgtTeamProps
        mgtName="Irene Ebong"
        mgtPosition="Accounts & Finance Manager"
        mgtPhoto={irene}
        mgtDescName="Irene Ebong"
        mgtDescPosition="Accounts & Finance Manager"
        mgtDesc={desc}Irene Ebong
      />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </DumebiContainer>
  );
};

export default Irene;

const DumebiContainer = styled.div`
 // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
