import React from "react";

const Newsletter = () => {
  return <div className="news-letter">Newsletter</div>;
};

export const Footer = () => {
  return (
    <div className="footer">
      <Newsletter />
      <div className="footer-cont">
        <div className="footer-info">
          <div className="row">
            <div className="col-md-3">
              <img className="chams-logo" />

              <div className="tagline">
                Design amazing digital experiences that create more happy in the
                world. SecureKid is powered by ChamAccess.
              </div>
            </div>
            <div className="col-md-2">
              <ul>
                <li>Company</li>
                <li>About us</li>
                <li>Contact us</li>
                <li>Privacy policy</li>
                <li>Terms & conditions</li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul>
                <li>Explore</li>
                <li>Features</li>
                <li>Case studies</li>
                <li>Facebook</li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul>
                <li>Contact us</li>
                <li>00000000000</li>
                <li>00000000000</li>
                <li>name@domain.com</li>
                <li>8, Louis Solomon Close, Victoria Island, Lagos.</li>
              </ul>
            </div>
            <div className="col-md-2">
              <ul>
                <li>Get the app</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-end">
          <div>2016 - 2024 ChamsAccess Limited</div>
          <div className="social-icons"></div>
        </div>
      </div>
    </div>
  );
};
