import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useParams } from "react-router-dom";
import { RiContactsBookLine } from "react-icons/ri";

// const EventCarousel = ({ images }) => {
const EventCarousel = ({ eventImages = [] }) => {
  // console.log(eventImages);
  const [eventData, setEventData] = useState([]);
  // const { eventid } = useParams();
  const [startIndex, setStartIndex] = useState(0);

  //   const url = `https://blogapi.chamsaccess.com/api/v1/visitors/blogs/${eventid}`;
  // //   const url = `http://localhost:5050/api/v1/visitors/events/${eventid}`;
  //     // const url = `http://localhost:5050/api/v1/visitors/events`;
  //   // function to get all events from the database
  //   const fetchEvents = async () => {
  //     const response = await axios.get(url);
  //     const mainData = response.data.data;
  //     const imagesArray = mainData.eventImages;
  //     eventData.push(imagesArray);
  //     setEventData(imagesArray);
  //   };

  //   console.log(eventData);

  //   console.log(eventData.eventImages.map((t)=>(t)));

  const handleClickPrevious = () => {
    setStartIndex((prevIndex) =>
      prevIndex === 0
        ? eventData.length - 2
        : (prevIndex + 4) % eventData.length
    );
  };
  //   const handleClickPrevious = () => {
  //     setStartIndex((prevIndex) => (prevIndex - 4) % eventData.length);
  //   };

  const handleClickNext = () => {
    setStartIndex((prevIndex) => (prevIndex + 4) % eventData.length);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    // fetchEvents();
  }, []);

  return ( 
    <Container>
      {/*<ArrowLeft onClick={handleClickPrevious}>
        <p>{`<`}</p>
      </ArrowLeft>
      <ImageContainer>
        {eventImages.slice(startIndex, startIndex + 4).map((image) => (
          <ImageWrapper key={image}>
            <Image
              src={`https://blogapi.chamsaccess.com/uploads/${image}`}
              alt={image}
            />
            {/* <Image src={`http://localhost:5050/uploads/${image}`} alt={image} /> 
            <ImageCaption>{image.split(".")[0]}</ImageCaption>
          </ImageWrapper>
        ))}
      </ImageContainer>

       <ArrowRight onClick={handleClickNext}>
        <p>{`>`}</p>
      </ArrowRight> */}

      <div className="event-image-container">
        {eventImages.map((image, index) => (
          <div className="event-img-div">
          <img
            key={index}
            className="event-image"
            src={`https://blogapi.chamsaccess.com/uploads/${image}`}
            alt={`Event ${index}`}
          />
          <ImageCaption>{image.split(".")[0]}</ImageCaption>

          </div>
        ))}
      </div>
    </Container>
  );
};
export default EventCarousel;

const Container = styled.div`
  //   background-color: red;
  width: 90%;
  // height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 60px;

  @media screen and (max-width: 960px) {
  }
  @media (max-width: 768px) {
    // height: 180px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

const ArrowLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 100%;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  position: absolute;
  left: 70px;
  z-index: 1000;

  p {
    // background-color: #101828
    color: #667085;
    font-size: 50px;
    position: absolute;
    z-index: 900;
  }

  @media (max-width: 768px) {
    // background-color: red;
    display: flex;
    height: 180px;
    width: 90px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const ArrowRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 100%;
  height: 300px;
  background-color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  position: absolute;
  right: 70px;
  z-index: 1000;

  p {
    // background-color: #101828
    color: #667085;
    font-size: 50px;
    position: absolute;
    z-index: 900;
  }

  @media (max-width: 768px) {
    // background-color: red;
    display: flex;
    height: 180px;
    width: 90px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  //   flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
  //   background-color: yellow;

  @media screen and (max-width: 960px) {
  }
  @media (max-width: 768px) {
    // background-color: red;
    display: flex;
    height: 100%;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  object-fit: contain;
  //   background-color: tomato;

  @media screen and (max-width: 960px) {
  }
  @media (max-width: 768px) {
    // background-color: red;
    display: flex;
    height: 100%;
    width: 100%;
    padding: 0 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

const Image = styled.img`
  background-color: green;
  width: 300px;
  height: 240px;
  margin: 5px;

  @media (max-width: 768px) {
    width: 100%;
    width: 200px;
    height: 270px;
  }
`;

const ImageCaption = styled.div`
  // background-color: red;
  // width: 300px;
  margin-left: 5px;
  display: flex;
  justify-content: flex-start;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  text-transform: capitalize;

  @media (max-width: 768px) {
    font-size: 14px;
    width: 100%;
    height: 270px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
