import styled from "styled-components";
import bg from "../../images/CyberIndustryWeServeBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const CyberContainer = styled.div`
  background: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 80px;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;

export const Hero1 = styled.div`
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) -74.19%,
    rgba(206, 228, 216, 0.25) 60.99%
  );
  width: inherit;
  height: 85%;
  height: 700px;
  display: flex;
  justify-content: center;
  margin-top: 80px;
  // background: red;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const HeroContent = styled.div`
  //   background: red;
  width: 50%;
  //   width: inherit%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 120px;
  // padding-left: 100px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 0px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const ContentHeader = styled.div`
  width: 460px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #08783d;
    text-transform: capitalize;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 460px;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85%;
    height: 192px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
`;
export const ContentDescription = styled.div`
  width: 500px;
  height: 84px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 60%;
    height: auto;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 70%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 70%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  width: 227px;
  height: 44px;
  background: #08783d;
  border-radius: 10px;
  font-family: Komet;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  transition: width 1s;

  :hover {
    width: 210px;
  }
`;
export const HeroImage = styled.div`
  //   background: purple;
  // height: 500px;
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 120px;

  img {
    width: 700px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;

    img {
      width: 90%;
      // width: 500px;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-right: 0px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: tomato;
    width: 100vw;

    img {
      width: 80%;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const AdvanceThreats = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  img {
    width: 1240px;
    height: 400px;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    img {
      width: 90%;
      height: 320px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 350px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 250px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 250px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 150px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 150px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 120px;
    }
  }
`;
export const SmallImage = styled.p`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 95%;
  }
`;

export const A1 = styled.p`
  width: 800px;
  height: 73px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-top: 90px;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 95%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
`;
export const A2 = styled.p`
  width: 800px;
  height: 53px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;
  margin-top: 0px;
  margin-bottom: 60px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;
export const CyberSolution = styled.div`
  width: 100vw;
  height: 1300px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
    // padding-top: 30px;
    // padding-bottom: 30px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
`;
export const S1 = styled.p`
  width: 891px;
  height: 40px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-top: 100px;
  margin-bottom: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
`;
export const S2 = styled.p`
  width: 831px;
  height: 53px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;
  margin-top: 0px;
  margin-bottom: 61px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
`;
export const CyberWrapper = styled.div`
  //   background: red;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 90%;
  // width: 1203px;
  height: 925px;
  gap: 80px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
      // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 10px;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: pink;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
    align-items: center;
    gap: 0px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IndustriesWeServe = styled.div`
  position: relative;
  width: 100vw;
  height: 340px;
  background-image: url(${bg});
  background-image: url(${bg}),
    linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    position: relative;
    width: 100vw;
    height: 320px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    position: relative;
    width: 100vw;
    height: 350px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const Gradient = styled.div`
  position: absolute;
  width: inherit;
  height: 340px;
  left: 0px;
  top: 0px;
  background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // position: absolute;
    // width: 100vw;
    // height: auto;
    // left: 0px;
    // top: 0px;
    // background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    // opacity: 0.8;
    // overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ServeContent = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
`;
export const ServeLeft = styled.div`
  //   background: red;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-left: 120px;
  //   margin-top: 94px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: width: 30%;
    margin-left: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    height: auto;
    width: width: 30%;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: width: 100%;
    // height: 250px;
    margin-left: 0px;
  }
`;

export const L1 = styled.p`
  width: 383px;
  height: 44px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 32px;
  }
`;
export const L2 = styled.p`
  width: 406px;
  height: 88px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;
export const ServeRight = styled.div`
  //   background: yellow;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-right: 120px;
  //   margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90%;
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90%;
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    // background: green;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
`;
export const R1 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R2 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R3 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R4 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R5 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}), //TO-DO
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 60px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    height: auto;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }

  @media screen and (max-width: 960px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: auto;
    margin: 0px 0px 30px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08783d;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: auto;
    margin: 0px 0px 30px;
    height: auto;
    margin: 0px 0px 30px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08783d;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
`;
// export const CyberContainer = styled.div``;
// export const CyberContainer = styled.div``;
