import styled from "styled-components";
import aboutBackground from "../../../images/AboutBackground.png";

export const AboutWrapper = styled.div`
  width: 100vw;
  height: 1350px;
  // height: auto;
  // background-image: url(${aboutBackground});
  background: linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) -74.19%,
      rgba(206, 228, 216, 0.25) 60.99%
    ),
    url(${aboutBackground});
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: inherit 777.84px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // background: green;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 50px;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: red;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    height: auto;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
`;
export const AboutButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 97px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  margin-bottom: 20px;
  margin-left: 80px;
  margin-top: 80px;
  cursor: pointer;
  text-wrap: nowrap;
  transition: background, color 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #a92f34;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (max-width: 960px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 375px) {
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 0px;
  }
`;
export const Why = styled.div`
  width: 846px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 20px;
  margin-left: 80px;

  @media screen and (max-width: 960px) {
    margin-left: 0px;
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    text-align: center;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    margin-left: 0px;
    text-align: center;
    width: 100%;
    font-size: 34px;
  }
  @media screen and (max-width: 375px) {
    margin-left: 0px;
    text-align: center;
    width: 100%;
    font-size: 32px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 0px;
    text-align: center;
    width: 100%;
    font-size: 26px;
  }
`;
export const AboutDesc = styled.div`
  width: 713px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
  margin-bottom: 50px;
  margin-left: 80px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-bottom: 50px;
    margin-left: 80px;
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    margin-left: 0px;
    // background: yellow;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    margin-left: 0px;
    // background: yellow;
    width: 90%;
    text-align: center;
  }
  @media screen and (max-width: 480px) {
    margin-left: 0px;
    // background: yellow;
    width: 100%;
    text-align: center;
    width: 370px;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 375px) {
    margin-left: 0px;
    // background: yellow;
    width: 100%;
    text-align: center;
    width: 340px;
    font-size: 16px;
    line-height: 24px;
  }
  @media screen and (max-width: 320px) {
    margin-left: 0px;
    // background: yellow;
    width: 100%;
    text-align: center;
    width: 300px;
    font-size: 15px;
    line-height: 23px;
  }
`;
export const AboutImages = styled.div`
  // background: red;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 90%;
  // width: 1203px;
  // height: 925px;
  height: auto;
  gap: 60px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
    // width: 1203px;
    // height: 925px;
    height: auto;
    gap: 0px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 100%;
    align-items: center;
    gap: 0px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// export const AboutWrapper = styled.div``;
// export const AboutWrapper = styled.div``;
// export const AboutWrapper = styled.div``;
// export const AboutWrapper = styled.div``;
