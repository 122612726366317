import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import uche from "../../images/Uche.png";
import ScrollToTop from "react-scroll-to-top";

const Uche = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  var desc = (
    <div>
      Focused on improving the Business and Operations Unit of the ChamsAccess
      brand, Engr. Uche Okorie spent over 2 decades honing his skills in
      Operations Management, Business Technical Sales, Projects, and Service
      Delivery. He holds a B.Eng. in Electrical/Electronics Engineering, two
      Masters degrees in Business Administration (MBA) and Engineering
      Management (M.Eng.) from Nigeria and Germany respectively.<br /> <br />He started his
      postgraduate career with V-Mobile Nigeria (Now Airtel Nigeria) in 2003 as
      a Network Controller. Uche later joined Multi-links Telkom and was the
      Manager in charge of Business Solutions and Pre-Sales until his
      appointment at ChamsAccess as Head, Business Operations and Support, where
      he grew to be the Divisional Head, Business Operations. <br /><br />Engr. Uche is an
      alumnus of the Lagos Business School (Advanced Management Program), a
      Certified Associate in Project Management, Certified Agile and Emotional
      Intelligence practitioner. Registered COREN Engineer, and a member of the
      Nigeria Society of Engineers (NSE), as well as Institute of Electrical and
      Electronics Engineers (IEEE).
    </div>
  );
  return (
    <DumebiContainer>
      <MgtTeamProps
        mgtName="Uche Okorie"
        mgtPosition="Head, Business Operations Support"
        mgtPhoto={uche}
        mgtDescName="Uche Okorie"
        mgtDescPosition="Head, Business Operations Support"
        mgtDesc={desc}
      />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </DumebiContainer>
  );
};

export default Uche;

const DumebiContainer = styled.div`
  // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
