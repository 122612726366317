import React from "react";
import styled from "styled-components";

const VisitorCard = ({ visitorSource, visitorHeading, visitorDesc, color }) => {
  return (
    <VisitorCardContainer style={{backgroundColor: color}}>
      <img src={visitorSource} alt="visitor" />
      <V1>{visitorHeading}</V1>
      <V2>{visitorDesc}</V2>
    </VisitorCardContainer>
  );
};

export default VisitorCard;

const VisitorCardContainer = styled.div`
  // background: red;
  width: 480px;
  height: 498px;
  // background: ${(bgColor) => bgColor.color};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  img {
    width: 80px;
    height: 120px;
    margin-bottom: 29px;
    margin-left: 50px;
  }

  @media screen and (max-width: 960px) {
    width: 43vw;
    height: auto;
    height: 498px;
    // background: red;
    margin: 15px;

    img {
      width: 80px;
      height: 120px;
      margin-bottom: 29px;
      margin-left: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 43vw;
    height: auto;
    height: 498px;
    // background: red;

    img {
      width: 80px;
      height: 120px;
      margin-bottom: 29px;
      margin-left: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 43vw;
    height: auto;
    height: 498px;
    // background: red;

    img {
      width: 80px;
      height: 120px;
      margin-bottom: 29px;
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 80vw;
    height: auto;
    height: 498px;
    // background: red;

    img {
      width: 80px;
      height: 120px;
      margin-bottom: 15px;
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const V1 = styled.div`
  width: 381px;
  height: 78px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #08783d;
  margin-bottom: 29px;
  margin-left: 50px;

  @media screen and (max-width: 960px) {
    // background: white;
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: white;
    width: 85%;
    height: auto;
    margin-left: 20px;
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 85%;
    height: auto;
    margin-left: 20px;
    font-size: 26px;
    line-height: 34px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const V2 = styled.div`
  // background: red;
  width: 381px;
  height: 119px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #08783d;
  margin-left: 50px;

  @media screen and (max-width: 960px) {
    // background: white;
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: white;
    width: 85%;
    height: auto;
    margin-left: 20px;
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 85%;
    height: auto;
    margin-left: 20px;
    font-size: 18px;
    line-height: 24px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// const VisitorCardContainer = styled.div``;
// const VisitorCardContainer = styled.div``;
// const VisitorCardContainer = styled.div``;
