import React, { useState, useEffect } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import '../../styles/eventDetail.css'
import parse from "html-react-parser";
import { Link as NavLink, useParams } from "react-router-dom";
import {
  NewsCotainer,
  EventCardWrapper,
  TextDiv,
  Ready,
  RequestButton,
  EventContentWrapper,
  ImageCover,
  Title,
  Content,
  ImageCaption,
  OtherEvents,
  OtherTitle,
  OtherCarouselWrapper,
  EventCarouselWrapper,
} from "./EventDetailStyle.js";
import EventCard from "./EventCard";
import EventCarousel from "./EventCarousel";
import EventCardCarousel from "./EventCardCarousel";
import ClipLoader from "react-spinners/ClipLoader";

const EventDetails = () => {
  const [eventData, setEventData] = useState({
    eventContent: "",
  });
  const [loading, setLoading] = useState(true);
  const { eventid } = useParams();
  // console.log(eventid);

  const url = `https://blogapi.chamsaccess.com/api/v1/visitors/events/${eventid}`;
  // const url = `http://localhost:5050/api/v1/visitors/events/${eventid}`;
  // function to get all blogs from the database
  const fetchEvents = async () => {
    const response = await axios.get(url);
    const mainData = response.data.data;
    setEventData(mainData);
    setLoading(false);
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    fetchEvents();
  }, []);

  // const titleImage = eventData.titleImage.split('.')[0];
  return (
    <NewsCotainer>
      <Header />
      {loading ? (
        <div className="loader-container">
        <div className="spinner"></div>
      </div>
      ) : (
        <>
        <EventCardWrapper>
        <EventContentWrapper>
          <Title>{eventData.eventTitle}</Title>
          <Content>{ parse(eventData.eventContent) }</Content>
          <ImageCover>
            <img
              src={`https://blogapi.chamsaccess.com/uploads/${eventData.titleImage}`}
              // src={`http://localhost:5050/uploads/${eventData.titleImage}`}
              alt="title-image"
            />
            {/* <ImageCaption>{eventData.titleImage}</ImageCaption> */}
          </ImageCover>
        </EventContentWrapper>
        <EventCarousel eventImages={eventData.eventImages}/>
      </EventCardWrapper>
        </>
      )}
      <OtherEvents>
        <OtherTitle>Other Events</OtherTitle>
        <OtherCarouselWrapper>
          <EventCardCarousel />
        </OtherCarouselWrapper>
      </OtherEvents>
      <TextDiv>
        <Ready>Download our annual general report</Ready>
        <NavLink to="" style={{ textDecoration: "none" }}>
          <RequestButton>Download Report</RequestButton>
        </NavLink>
      </TextDiv>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </NewsCotainer>
  );
};

export default EventDetails;
