import styled from "styled-components";
import argonHeroBg from "../../images/ArgoneHeroBh.svg";
import trusteeImage from "../../images/TrusteesBg.svg";

export const ArgoneContainer = styled.div`
  // background: red;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const ArgoneHero = styled.div`
  // background: blue;
  width: 100vw;
  height: 700px;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    0deg,
    rgba(163, 47, 52, 0.25) 11.98%,
    rgba(238, 213, 214, 0.25) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const ArgoneDivider = styled.div`
  // background: pink;
  width: 85%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    overflow-x: hidden;
    overflow-y: hidden;
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const ArgonLeft = styled.div`
  // background: blue;
  width: auto;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  img {
    width: 157px;
    height: 47px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;

    img {
      width: 157px;
      height: 47px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 90%;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 157px;
      height: 47px;
    }
  }
  @media screen and (max-width: 768px) {
    // background-color: white;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background-color: white;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const ArgoneHead = styled.div`
  width: 90%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #1d2939;
  margin-bottom: 20px;

  span {
    color: #a92f34;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #1d2939;
    margin-bottom: 20px;

    span {
      color: #a92f34;
    }
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    height: auto;
    width: 100vw;
    text-align: center;
    font-size: 40px;
    line-height: 52px;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    height: auto;
    width: 100vw;
    text-align: center;
    font-size: 40px;
    line-height: 52px;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    height: auto;
    width: 100vw;
    text-align: center;
    font-size: 38px;
    line-height: 50px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    height: auto;
    width: 100vw;
    text-align: center;
    font-size: 38px;
    line-height: 46px;
  }
`;
export const ArgoneDesc = styled.div`
  width: 530px;
  height: 84px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  margin-bottom: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: red;
    height: auto;
    width: 93%;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    height: auto;
    width: 93%;
    text-align: center;
    line-height: 25px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    height: auto;
    width: 90%;
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
`;
export const ArgoneButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 170px;
  height: 44px;
  background: #a92f34;
  border-radius: 8px;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  :hover {
    width: 180px;
  }
`;
export const ArgonRight = styled.div`
  width: 40%;
  height: inherit;
  display: flex;
  align-items: center;
  // justify-content: flex-start;
  justify-content: center;
  background: url(${argonHeroBg});
  background-position: center;
  // background-position: left center;
  background-repeat: no-repeat;
  background-size: contain;
  // background: tomato;

  img {
    width: auto;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 80%;
      height: 546px;
    }
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    img {
      width: 600px;
      height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;

    img {
      width: 600px;
      height: 600px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;

    img {
      width: 600px;
      height: 600px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;

    img {
      width: 90%;
      height: 390px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;

    img {
      width: 90%;
      height: 350px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    img {
      width: 85%;
      height: 280px;
    }
  }
`;

export const DeviceSale = styled.div`
  width: 100vw;
  height: 800px;
  background: #fcfcfd;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const SaleDivider = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const SalesLeft = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      // height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;

    img {
      width: 570px;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;

    img {
      width: 470px;
      height: 470px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 100vw;

    img {
      width: 85%;
      height: 370px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;

    img {
      width: 90%;
      height: 360px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: white;
    width: 100vw;
    img {
      width: 90%;
      height: 300px;
    }
  }
`;
export const SalesRight = styled.div`
  // background: blue;
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 40%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: auto;
    height: auto;
    display: flex;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
`;
export const D1 = styled.div`
  width: 422px;
  height: 34px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const D2 = styled.div`
  width: 422px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SalesControl = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SalesList = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ArgoneContainer = styled.div``;
export const DeviceRepair = styled.div`
  width: 100vw;
  height: 800px;
  background: #f6eaeb;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const DeviceDivider = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: hidden;
  }
`;
export const DeviceLeft = styled.div`
  // background: blue;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
    // }background-color: red;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 30px;
    overflow-x: hidden;
  }
`;
export const DeviceRight = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 70%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 60%;
      height: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 560px;
      height: 552px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80vw;
      height: 452px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 80vw;
      height: 352px;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 80vw;
      height: 320px;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 80vw;
      height: 260px;
      height: auto;
    }
  }
`;
export const SalesDescription = styled.div`
  // background: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
`;
export const DeviceDescription = styled.div`
  // background: green;
  width: 100%;
  width: auto;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
`;
export const L1 = styled.div`
  width: 422px;
  height: 34px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const L2 = styled.div`
  width: 422px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
  }
`;
export const DeviceControl = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const DeviceList = styled.div`
  //   background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ArgoneContainer = styled.div``;
export const DeviceTraining = styled.div`
  width: 100vw;
  height: 800px;
  background: #fcfcfd;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
  }
`;
export const TrainingDivider = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  align: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const TrainingLeft = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;

    img {
      width: 584.9px;
      height: 549px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;

    img {
      width: 570px;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
    img {
      width: 90%;
      height: 350px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
    img {
      width: 90%;
      height: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
    img {
      width: 85%;
      height: 280px;
    }
  }
`;
export const TrainingRight = styled.div`
  // background: blue;
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 40%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const T1 = styled.div`
  width: 422px;
  height: 34px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const T2 = styled.div`
  width: 422px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TrainingControl = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 60vw;
  }
  @media screen and (max-width: 600px) {
    width: 90vw;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
  }
`;
export const TrainingList = styled.div`
  width: 100%;
  // background: tomato;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 30px;

  img {
    width: 28px;
    height: 28px;
    margin-right: 16px;
  }

  p {
    width: 100%;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    margin: 0px;
  }
  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }
`;
// export const ArgoneContainer = styled.div``;
export const DeviceFinance = styled.div`
  width: 100vw;
  height: 800px;
  background: #f6eaeb;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
  }
`;
export const FinanceDivider = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  align: flex-start;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const FinanceLeft = styled.div`
  // background: blue;
  width: 40%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-end;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
    // }background-color: red;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const FinanceRight = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    img {
      width: 70%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 60%;
      height: auto;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 560px;
      height: 552px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80vw;
      height: 452px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 80vw;
      height: 352px;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 80vw;
      height: 320px;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 80vw;
      height: 260px;
      height: auto;
    }
  }
`;
export const FinanceDescription = styled.div`
  // background: green;
  width: 100%;
  width: auto;
  height: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;
  }
`;
export const F1 = styled.div`
  width: 422px;
  height: 34px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const F2 = styled.div`
  width: 422px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
  }
`;
export const FinanceControl = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const FinanceList = styled.div`
  //   background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ArgoneContainer = styled.div``;
export const DeviceSwap = styled.div`
  width: 100vw;
  height: 800px;
  background: #fcfcfd;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const SwapDivider = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  align: flex-start;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const SwapLeft = styled.div`
  // background: tomato;
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      // height: 600px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;

    img {
      width: 570px;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    padding-top: 0px;
    padding-bottom: 0px;

    img {
      width: 470px;
      height: 470px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: white;
    width: 100vw;

    img {
      width: 85%;
      height: 370px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;

    img {
      width: 90%;
      height: 360px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: white;
    width: 100vw;
    img {
      width: 90%;
      height: 300px;
    }
  }
`;
export const SwapRight = styled.div`
  // background: blue;
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 40%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: auto;
    height: auto;
    display: flex;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    padding-top: 0px;
    padding-bottom: 0px;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    // background: blue;
    height: auto;
    display: flex;
    overflow-x: hidden;
  }
`;
export const S1 = styled.div`
  width: 422px;
  height: 34px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const S2 = styled.div`
  width: 422px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SwapControl = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SwapList = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;
// export const ArgoneContainer = styled.div``;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 320px;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
`;
export const Ready = styled.div`
  //   background: red;
  width: 900px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  margin-bottom: 20px;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    // margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
  @media screen and (max-width: 375px) {
    margin: 0px;
  }
`;
