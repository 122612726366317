import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import parse from "html-react-parser";
// import sampleImage from "../../images/TestImage.png";
import arrowForward from "../../images/arrowForward.svg";

const EventCard = ({
  eventTitleImage,
  eventTitle,
  description,
  eventContent,
  name,
  viewMore,
  id,
}) => {
  const nav = useNavigate();
  const navigate = (id) => {
    nav(`/event-detail/${id}`);
    // console.log(id);
  };

  const parsedDescription = description.replace(/<\/?[^>]+(>|$)/g, "");
  // let navigate = useNavigate();
  return (
    <CardWrapper onClick={() => navigate(id)}>
      <ImageDiv>
        <img
          src={`https://blogapi.chamsaccess.com/uploads/${eventTitleImage}`}
          // src={`http://localhost:5050/uploads/${eventTitleImage}`}
          alt={name}
        />
      </ImageDiv>
      <ContentDiv>
        <Title>{eventTitle}</Title>
        <Description>{parsedDescription}</Description>
        <Content> {parse(eventContent)}</Content>
        <Nav>
          <Text onClick={() => navigate(id)}>{viewMore}</Text>
          <ArrowUp>
            <img src={arrowForward} alt="up" />
          </ArrowUp>
        </Nav>
      </ContentDiv>
    </CardWrapper>
  );
};

export default EventCard;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px;
  width: 400px;
  height: 470px;
  background: #fcfcfd;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  transition: transform 1s;
  margin: 20px;
  // background: red;

  :hover {
    transform: scale(1.02);
    cursor: pointer;  
  }

  @media screen and (max-width: 960px) {
    // background: red;
    width: 400px;
    height: 470px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 300px;
    height: 400px;
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
    width: 300px;
    height: 400px;
    margin: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 280px;
    height: 400px;
    margin: 15px;
  }
  @media screen and (max-width: 375px) {
    width: 270px;
    height: 400px;
    margin: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 230px;
    height: 400px;
    margin: 15px;
  }
`;
const ImageDiv = styled.div`
  // background: green;
  width: 100%;
  // height: 45%;
  height: 250px;
  border-radius: 10px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }

  @media screen and (max-width: 960px) {
    // background: green;
    width: 100%;
    height: 250px;
    border-radius: 10px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const ContentDiv = styled.div`
  // background: purple;
  // width: inherit;
  height: 55%;
  // height: 337px;
  height: flex 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 28px 28px;

  @media screen and (max-width: 768px) {
    padding: 20px 20px;
  }
`;
const Title = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  color: #344054;
  text-transform: capitalize;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 600px) {
    font-size: 22px;
  }
  @media screen and (max-width: 480px) {
    font-size: 20px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Description = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 110px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #667085;

  white-space: wrap;
  // text-overflow: ellipsis;
  max-width: 300px;
  max-height: 145px;
  overflow: hidden;

  p {
    font-family: Komet;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: rgb(102, 112, 133);
    overflow: hidden;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Content = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    font-size: 16px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Nav = styled.div`
  // background-color: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;
const Text = styled.div`
  // background-color: blue;
  width: auto;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #08783d;

  :hover {
    color: green;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const ArrowUp = styled.div`
  // background-color: red;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 10px;
    height: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// const CardWrapper = styled.div``;
// const CardWrapper = styled.div``;
