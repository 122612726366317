import React, { useState } from "react";
import Shield from "../assets/Shield.png";
import CASLogo from '../assets/CASLogo.svg';

export const Contact = () => {
  // Form state to hold input values
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    phonenumber: "",
    productType: "",
    message: ""
  });
  const [loading, setLoading] = useState(false);

  // Handle input changes
  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault(); 
    setLoading(true);

    // You can validate form data here if needed
    const url = "https://adminapi.chamsccess.com/api/secure-kid/contact-form";
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Handle success (e.g., show a success message)
        console.log("Form submitted successfully");
      } else {
        // Handle server errors
        console.error("Failed to submit form");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div className="contact-container">
      <section className="left">
        <div className="contact-text">
          <div className="sk-title">
            Gain complete control with ChamsAccess Security Solution?
          </div>
          <div className="sk-subtitle">
            Gain complete control with ChamsAccess Security Solution with
            ChamsAccess Security Solution?
          </div>
        </div>
      </section>
      <section className="right">
        <div className="form-cont">
          <img src={CASLogo} className="cas-logo" alt="CAS Logo" />
          <div className="form-text">
            <div className="ft-title">Talk to our sales team</div>
            <div className="ft-subtitle">
              Our friendly team would love to hear from you.
            </div>
          </div>
          <form className="r-form" onSubmit={handleSubmit}>
            <label className="r-label">
              First name *
              <input
                type="text"
                id="fullname"
                placeholder="Fullname"
                value={formData.fullname}
                onChange={handleChange}
                required
              />
            </label>
            <label className="r-label">
              Email *
              <input
                type="email"
                id="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </label>
            <label className="r-label">
              Phone number *
              <input
                type="tel"
                id="phonenumber"
                placeholder="Phone number"
                value={formData.phonenumber}
                onChange={handleChange}
                required
              />
            </label>
            <label className="r-label">
              Product Type *
              <select
                id="productType"
                value={formData.productType}
                onChange={handleChange}
                required
              >
                <option value="">Select</option>
                <option value="Secure Kid">Secure Kid</option>
              </select>
            </label>
            <label className="r-label">
              Message *
              <textarea
                style={{ height: 60 }}
                id="message"
                placeholder="Enter a message..."
                value={formData.message}
                onChange={handleChange}
              />
            </label>
            <button type="submit" className="form-button">
            {loading ? "Loading..." : "Submit"}
            </button>
          </form>
        </div>
      </section>
    </div>
  );
};
