import React, { useRef } from "react";
import styled from "styled-components";
import cancelIcon from "../../../images/CancelIcon.svg";
import mallamIdris from '../../../images/MallamIdris.png';

const Modal = ({ setShowModal5 }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal5(false);
    }
  };
  return (
    <ModalCover
      ref={modalRef}
      onClick={closeModal}
      // style={{
      //   position: "fixed",
      //   top: "50%",
      //   left: "50%",
      //   transform: "translate(-50%, -50%)",
      //   width: "100vw",
      //   height: "100vh",
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   background: "rgba(0, 0, 0, 0.7)",
      //   zIndex: "999",
      //   // animation: "animate 0.9s",
      // }}
    >
      <ModalContainer>
        <ModalWrapper>
          <img src={mallamIdris} alt="sourceFile" />
          <Rights2 onClick={() => setShowModal5(false)}>
            <img src={cancelIcon} alt="cancel" />
          </Rights2>
          <ModalContent>
            <Tops>
              <Lefts>
                <Name>Mallam Idris Yushau Saeed</Name>
                <Position>Director</Position>
              </Lefts>
              <Rights onClick={() => setShowModal5(false)}>
                <img src={cancelIcon} alt="cancel" />
              </Rights>
            </Tops>
            <Buttoms>
            Mallam Idris Yushau Saeed is a distinguished professional with a robust background in accounting, finance, and management. A graduate of Accounting from the University of Maiduguri (1982), he furthered his education with an MBA from Ahmadu Bello University, Zaria (1994). Idris is an associate member of the Institute of National Accountants of Nigeria (ANAN) and a member of the Chartered Institute of Taxation of Nigeria (CITN).

<br /><br />Mallam Idris commenced his career during the mandatory NYSC program with the accounts department of Nigerian Paper Mills Limited, Jebba, Kwara State. In 1984, he joined the Federal Civil Service as an Inspector of Taxes with the Federal Inland Revenue Department.

<br /><br />The following year, he moved to Icon Limited (Merchant Bankers), where he ascended to the role of Area Manager. In 1995, he transitioned to African International Bank (AIB), eventually becoming Principal Manager and Head of the Broad Street Branch. By 1999, Idris joined Intercity Bank Plc, where he led the bank’s International Department until his resignation in 2000 to enter politics.

<br /><br />Throughout his career, Mallam Idris has enhanced his expertise through various professional courses, seminars, and conferences both domestically and internationally. These include induction courses at Icon Merchant Bankers, financial management, commercial lending,
bank management, export development, microcomputer applications, and workshops on leasing, export processing zones, and Phoenix banking software, among others.

<br /><br />Currently, Mallam Idris serves as the Managing Director of Linknet Nigeria Limited, a construction and general contracting company based in Kaduna. He is also a Partner at Adigun Idris Bala and Co. (AIB), an account management and tax consulting firm affiliated with other professional entities. Additionally, he is the Chairman of the Audit and Risk Asset Committee of Backbone Connectivity Network Nigeria Ltd (BCN).

<br /><br />Mallam Idris has also held significant positions as a non-executive Director at Premium Pension Ltd (2011-2017), ChamsSwitch Nigeria Ltd (2007-2022), and Oxygen Manufacturing Company Ltd (2008-2022).
            </Buttoms>
          </ModalContent>
        </ModalWrapper>
      </ModalContainer>
    </ModalCover>
  );
};

export default Modal;

const ModalCover = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  // animation: animate 0.9s;
`;

const ModalContainer = styled.div`
  // background: rgba(0, 0, 0, 0.7);
  background: #ffffff;
  // background: red;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 987px;
  height: 609px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 999;
  // animation: animate 0.9s;

  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: 50vw;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90vw;
    height: 50vw;
    border-radius: 20px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90vw;
    height: 50vw;
    border-radius: 20px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 80vw;
    height: 60vh;
    border-radius: 20px;
    padding: 10px;
    overflow-y: scroll;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 80vw;
    height: 60vh;
    border-radius: 20px;
    // padding: 10px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 80vw;
    height: 100vw;
    height: 60vh;
    border-radius: 20px;
    // padding: 10px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ModalWrapper = styled.div`
  // background: yellow;
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`;
const ModalContent = styled.div`
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
  overflow-x: auto;

  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
`;
const Tops = styled.div`
  // background: tomato;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    // background: grey;
    width: 337px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 768px) {
    // background: grey;
    width: 300px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 600px) {
    // background: grey;
    width: 218px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 480px) {
    // background: grey;
    width: 340px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
    // background: grey;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: grey;
    width: 256px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
`;
const Buttoms = styled.div`
  width: 100%;
  height: 429px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  margin-top: 22px;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: auto;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 600px) {
    // background: white;
    width: auto;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 480px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
  @media screen and (max-width: 375px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
  @media screen and (max-width: 320px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
`;
const Rights = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 375px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Rights2 = styled.div`
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: -20px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 375px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: -20px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: 0px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Lefts = styled.div`
  // background-color: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background-color: red;
    width: 90%;
  }
  @media screen and (max-width: 375px) {
    // background-color: red;
    width: 90%;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
  }
`;
const Name = styled.div`
  width: 100%;
  height: 32px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
`;
const Position = styled.div`
  // background: blue;
  width: inherit;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
`;
