import React from "react";
import solutionsDigital from "../assets/Solutions-Digital.svg";
import solutionsIdentity from "../assets/Solutions-Identity.svg";
import solutionsRealTime from "../assets/Solutions-Realtime.svg";
import solutionsCalender from "../assets/Solutions-Calender.svg";
import solutionsLocation from "../assets/Solutions-Location.svg";
import solutionsCustom from "../assets/Solutions-Custom.svg";
export const Solutions = () => {
  const solutionsData = [
    {
      imageUrl: solutionsDigital,
      text: "Digital Check-in/Check-out",
      subtext:
        "Efficiently manage attendance with our digital solution. Track and record check-ins and check-outs seamlessly for better time management and accountability.",
    },
    {
      imageUrl: solutionsIdentity,
      text: "Verification",
      subtext:
        "Enjoy peace of mind with quick, reliable access. No more fumbling with passwords—just a touch or a glance for instant verification. Your safety and convenience are our top priorities.",
    },
    {
      imageUrl: solutionsRealTime,
      text: "Real Time Notifications",
      subtext:
        "Stay informed with instant updates. Receive alerts and notifications as events happen in your school, ensuring you're always in the loop and never miss a thing.",
    },
    {
      imageUrl: solutionsCalender,
      text: "Attendance Management",
      subtext:
        "Simplify attendance tracking. Monitor student attendance in real-time, generate reports effortlessly, and ensure accurate records for your school.",
    },
    {
      imageUrl: solutionsLocation,
      text: "Live Location Tracking",
      subtext:
        "Track and monitor in real-time. See live locations to ensure safety and stay connected with accurate, up-to-date positioning.",
    },
    {
      imageUrl: solutionsCustom,
      text: "Customisable Solutions",
      subtext:
        "We provide comprehensive solutions tailored to various scenarios, ensuring that all critical requirements of the education industry are met for both parents and schools.",
    },
  ];
  return (
    <div className="solutions">
      <div className="sk-title">
        Securing educational institutions from unwanted incidents brings
        calmness to the minds of schools and parents.
      </div>
      <div className="sk-subtitle">
        We’ve built a scenario-based solutions to prevent incidents and meets
        all the critical needs of both parents and schools, ensuring safety and
        calmness.
      </div>
      <div className="solutions-cards row">
        {solutionsData && solutionsData.length > 0 ? (
          solutionsData.map((solution, index) => (
            <div key={index} className="col-md- s-card">
              <img
                src={solution.imageUrl}
                className="s-card-img"
                alt={solution.text}
              />
              <div className="s-card-text">
                <div className="sc-title">{solution.text}</div>
                <div className="sc-subtitle">{solution.subtext}</div>
              </div>
            </div>
          ))
        ) : (
          <p>No solutions available</p>
        )}
      </div>
    </div>
  );
};
