import React from "react";
import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import "../components/compStyles.css";
import { Benefits } from "../components/Benefits";
import { Testimonials } from "../components/Testimonials";
import { Matters } from "../components/Matters";
import { Solutions } from "../components/Solutions";
import { Control } from "../components/Control";
import { Hero } from "../components/Hero";

export const SecureKid = () => {
  return (
    <>
      <Header />
      <Hero />
      <Solutions />
      <Matters />
      <Benefits />
      <Testimonials />
      <Control />
      <Footer />
    </>
  );
};
