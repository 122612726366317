import React, { useState, useEffect } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import EventCard from "./EventCard";
import { Link } from "react-scroll";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NewsContainer,
  GetInTouch,
  Content,
  Contact,
  Touch,
  BlogCardWrapper,
  Wrap,
} from "./EventStyle.js";

const Events = () => {
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = "https://blogapi.chamsaccess.com/api/v1/visitors/events";
    // const url = "http://localhost:5050/api/v1/visitors/events";
  // function to get all blogs from the database
  const fetchEvents = async () => {
    const response = await axios.get(url);
    const mainData = response.data.data;
    // sort the data to show the most recent event first
    const sortedEventData = mainData.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setEventData(sortedEventData);
    setLoading(false);
  };
  // console.log(eventData);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    fetchEvents();
  }, []);

  return (
    <NewsContainer>
      <Header />
      <GetInTouch>
        <Content>
          <Link
            to="events"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1500}
          >
            <Contact>Gallery</Contact>
          </Link>

          <Touch>In Pictures: Discover Our Gallery</Touch>
          <p>
            Join Us on a Journey Through Our Gallery, Where Each Picture Tells a
            Captivating Story
          </p>
        </Content>
      </GetInTouch>
      <BlogCardWrapper>
        <p>Join us to explore...</p>
        <Wrap>
          {loading ? (
            <ClipLoader color="green" size={30} />
          ) : (
            <>
              {eventData.map((event) => (
                <div key={event._id}>
                  <EventCard
                    name={event.eventTitle}
                    // eventTitleImage={event.titleImage}
                    eventTitleImage={event.titleImage}
                    eventTitle={event.eventTitle}
                    description={event.eventContent}
                    eventContent={""}
                    viewMore="View More"
                    id={event._id}
                  />
                </div>
              ))}
            </>
          )}
        </Wrap>
      </BlogCardWrapper>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </NewsContainer>
  );
};

export default Events;
