import React, { useEffect } from "react";
import styled from "styled-components";
import MgtTeamProps from "./MgtTeamProps";
import dumebi from "../../images/Dumebi.png";
import ScrollToTop from "react-scroll-to-top";

const DumebiObodo = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  var desc = (
    <div>
      Dumebi Obodo is a consummate professional with over fifteen (15) years'
      cognate experience obtained from operational, management and board
      positions covering business strategy/development, Product Development &
      Deployment, Partnerships & Sales. <br />
      <br />
      Formerly holding the esteemed position of Managing Director at
      ChamsAccess, Dumebi has transition into another pivot role within the
      organization, now serving as a distinguished member of its Board of
      Directors. <br />
      <br />
      With a wealth of experience garnered over years of dedicated service in
      the technology and business sectors, his journey has been marked by
      notable achievements and strategic leadership. Having successfully
      navigated the challenges of executives management, Dumebi's transition to
      a directional role underscores a commitment to the long-term vision and
      sustainable growth of ChamsAccess.
      <br />
      <br />
      As a member of the Board of Directors, he brings forth a unique blend of
      visionary insights, industry expertise, and a profound understanding of
      the intricacies that drive success in the dynamic landscape of technology
      and corporate governance. Dumebi has a first degree in Microbiology, an
      MBS from the prestigious Lagos Business School (Pan Atlantic University),
      and is a member of the Chartered Institute of Directors of Nigeria.
    </div>
  );
  return (
    <DumebiContainer>
      <MgtTeamProps
        mgtName="Dumebi Obodo"
        mgtPosition="DIRECTOR"
        mgtPhoto={dumebi}
        mgtDescName="Dumebi Obodo"
        mgtDescPosition="DIRECTOR"
        mgtDesc={desc}
      />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </DumebiContainer>
  );
};

export default DumebiObodo;

const DumebiContainer = styled.div`
 // width: 100vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
`;
// const DumebiContainer = styled.div``;
// const DumebiContainer = styled.div``;
