import styled from "styled-components";
import bg from "../../images/CyberIndustryWeServeBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const CyberContainer = styled.div`
  background: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-top: 80px;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;

export const Hero1 = styled.div`
  background-image: linear-gradient(179deg, rgba(255, 238, 247, 0.25) 1%, rgba(198, 105, 109, 0.25) 1%, rgba(137, 235, 182, 0.25) 44.8%);
  width: inherit;
  height: 85%;
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  // background: red;

  .circleEdge {
    position: absolute;
    z-index: -2;
    top: 0px;
    left: 0px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const HeroContent = styled.div`
  //   background: red;
  width: 40%;
  //   width: inherit%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
  // padding-left: 100px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 0px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    margin-left: 0px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const ContentHeader = styled.div`
  width: 460px;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #a92f34;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 460px;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85%;
    height: 192px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 52px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    letter-spacing: -0.02em;
    color: #101828;
    text-align: center;

    span {
      color: #08783d;
      text-transform: capitalize;
    }
  }
`;
export const ContentDescription = styled.div`
  width: 500px;
  height: 84px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-top: 20px;
  margin-bottom: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 60%;
    height: auto;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 70%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 70%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 90%;
    text-align: center;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    color: #101828;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;
export const ContentButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 10px;
  width: 227px;
  height: 44px;
  background: #08783d;
  border-radius: 10px;
  font-family: Komet;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  transition: width 1s;
  gap: 4px;

  img {
    max-width: 12%;
    margin-bottom: 0px;
  }

  :hover {
    width: 210px;
  }
`;

export const HeroBackground = styled.img`
  width: 700px;
`;

export const HeroForeground = styled.img`
  position: relative;
  width: 700px;
`;
export const HeroImage = styled.div`
  //   background: purple;
  // height: 500px;
  width: 40%;
  height: 100px;
  display: flex;
  justify-items: center;
  align-items: center;
  margin-right: 120px;

  img {
    width: 120%;
  }
  img > .main {
    position: relative;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;

    img {
      width: 90%;
      // width: 500px;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 50px;
    // padding-bottom: 50px;
    margin-right: 0px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: tomato;
    width: 100vw;

    img {
      width: 80%;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const AdvanceThreats = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  img {
    width: 1240px;
    height: 400px;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    img {
      width: 90%;
      height: 320px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 350px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 250px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 250px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 150px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 150px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: tomato;
    width: 100vw;
    height: auto;

    img {
      width: 95%;
      height: 120px;
    }
  }
`;
export const SmallImage = styled.p`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 95%;
  }
`;

export const A1 = styled.p`
  width: 800px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-top: 90px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 95%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    color: #101828;
    margin-top: 60px;
    margin-bottom: 23px;
  }
`;
export const A2 = styled.div`
  width: 600px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;
  margin-top: 0px;
  margin-bottom: 60px;
  text-align: left;

  .circleEdge {
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #667085;
    margin-top: 0px;
    margin-bottom: 30px;
  }
`;
export const CyberSolution = styled.div`
  width: 100vw;
  height: 1300px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
    // padding-top: 30px;
    // padding-bottom: 30px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    // background: red;
  }
`;
export const S1 = styled.p`
  width: 891px;
  height: 40px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-top: 100px;
  margin-bottom: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
`;
export const S2 = styled.p`
  width: 831px;
  height: 53px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;
  margin-top: 0px;
  margin-bottom: 61px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-top: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: auto;
    // background: red;
    margin-bottom: 60px;
  }
`;
export const CyberWrapper = styled.div`
  //   background: red;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
  padding: 0px;
  width: 90%;
  // width: 1203px;
  height: 925px;
  gap: 80px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
      // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: hidden;
    margin-top: 20px;
    margin-bottom: 30px;
    gap: 10px;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: pink;
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center
    align-items: center;
    gap: 0px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IndustriesWeServe = styled.div`
  position: relative;
  width: 100vw;
  height: 340px;
  background-image: url(${bg});
  background-image: url(${bg}),
    linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    position: relative;
    width: 100vw;
    height: 320px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    position: relative;
    width: 100vw;
    height: 350px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const Gradient = styled.div`
  position: absolute;
  width: inherit;
  height: 340px;
  left: 0px;
  top: 0px;
  background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // position: absolute;
    // width: 100vw;
    // height: auto;
    // left: 0px;
    // top: 0px;
    // background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    // opacity: 0.8;
    // overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ServeContent = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
`;
export const ServeLeft = styled.div`
  //   background: red;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-left: 120px;
  //   margin-top: 94px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: width: 30%;
    margin-left: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    height: auto;
    width: width: 30%;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: width: 100%;
    // height: 250px;
    margin-left: 0px;
  }
`;

export const L1 = styled.p`
  width: 383px;
  height: 44px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 32px;
  }
`;
export const L2 = styled.p`
  width: 406px;
  height: 88px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
  }
`;
export const ServeRight = styled.div`
  //   background: yellow;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-right: 120px;
  //   margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90%;
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90%;
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    // background: green;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
`;
export const R1 = styled.p`
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R2 = styled.p`
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R3 = styled.p`
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R4 = styled.p`
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const R5 = styled.p`
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    //TO-DO
    linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-bottom: 30px;
    padding-top: 30px;

    p {
      width: 90vw;
      height: auto;
    }
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 60px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    height: auto;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }

  @media screen and (max-width: 960px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    height: auto;
    margin: 0px 0px 30px;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: auto;
    margin: 0px 0px 30px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 42px;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08783d;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: auto;
    margin: 0px 0px 30px;
    height: auto;
    margin: 0px 0px 30px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 48px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #08783d;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
`;
// export const CyberContainer = styled.div``;
// export const CyberContainer = styled.div``;

export const AccessManagementContainer = styled.div`
  //   background: red;
  height: auto;
  display: flex;
  flex-direction: column;
  // background: red;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const Management = styled.div`
  position: relative;
  background: #ffffff;
  width: 100vw;
  height: 700px;
  margin-top: 80px;
  display: flex;
  overflow-x: hidden;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 500px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const Div1 = styled.div`
  width: 900px;
  height: 700px;
  left: 0px;
  top: 0px;
  background: linear-gradient(45deg, #043c1f 0%, #08783d 100%);
  border-radius: 0px 0px 100px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    // width: 1300px;
    height: 500px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 70vw;
    height: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 500px;
    height: 400px;
    left: 0px;
    top: 0px;
    background: linear-gradient(45deg, #043c1f 0%, #08783d 100%);
    border-radius: 0px 0px 100px 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    width: 500px;
    height: 400px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    padding-top: 40px;
    // padding-bottom: 100px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Div2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -190px;

  img {
    width: 570px;
    height: 480px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -90px;

    img {
      width: 500px;
      height: 450px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -90px;
    top: -10px;

    img {
      width: 420px;
      height: 380px;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -50px;
    top: -40px;

    img {
      width: 380px;
      height: 280px;
    }
  }
  @media screen and (max-width: 600px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -50px;
    top: -40px;

    img {
      width: 380px;
      height: 280px;
    }
  }
  @media screen and (max-width: 480px) {
    // // background: pink;
    // width: 100vw;
    // height: auto;
    // position: relative;
    // display: flex;
    // justify-content: flex-start;
    // top: -70px;
    // left: 0px;

    // img {
    //   width: 380px;
    //   height: 280px;
    // }

    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 280px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 280px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 230px;
    }
  }
`;
export const M1 = styled.div`
  width: 455px;
  height: 126px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #fcfcfd;
  margin-bottom: 20px;
  margin-left: 120px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 70%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 70%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 768px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 480px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    // width: 80%;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    // width: 80%;
    width: 90%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
`;
export const M2 = styled.div`
  width: 455px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fcfcfd;
  margin-bottom: 20px;
  margin-left: 120px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessManagementButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 180px;
  height: 44px;
  background: #ebf5f0;
  border-radius: 8px;
  margin-left: 120px;
  cursor: pointer;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;

  :hover {
    width: 200px;
  }

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
  }
  @media screen and (max-width: 768px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementHistory = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementWrapper = styled.div`
  // background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // height: 1300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementLeft = styled.div`
  // background: yellow;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 75%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 524px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 424px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 324px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 280px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 250px;
    }
  }
`;
export const ManagementRight = styled.div`
  //   background: yellow;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #475467;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 95%;
      height: 73px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    p {
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TextHeader = styled.div`
  width: 100%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    width: 85%;
    height: 154px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85%;
    height: 154px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SecureKidComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidDivider = styled.div`
  width: 90%;
  // background: red;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidLeft = styled.div`
  //   background: green;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 80%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const K1 = styled.div`
  //   background: blue;
  width: 100%;
  height: 29px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const K2 = styled.div`
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #475467;
  margin-bottom: 26px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptList = styled.div`
  //   background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
`;
export const KidNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: auto;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    // background: red;
    margin-top: 50px;
  }
`;
export const Name = styled.div`
  width: 82px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #a92f34;
  margin-right: 5px;
`;
export const AccessControlComponent = styled.div`
  background-color: #ffffff;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlDivider = styled.div`
  // background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlLeft = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlRight = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C1 = styled.div`
  width: 450px;
  height: 72px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C2 = styled.div`
  width: 508px;
  height: 156px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;
export const PrintLabelComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 95%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PL1 = styled.div`
  width: auto%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PL2 = styled.div`
  width: auto;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const AssetManagementContainer = styled.div`
  //   background: red;
  //   width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  // background: red;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;

// new component
export const AssetSolutionComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 95%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AC1 = styled.div`
  width: auto%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AC2 = styled.div`
  width: auto;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;
export const VisitorsManagementComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorLeft = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const V1 = styled.div`
  width: 100%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const V2 = styled.div`
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceComponent = styled.div`
  background: white;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceDivider = styled.div`
  //   background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceRight = styled.div`
  //   background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;

export const Atm = styled.div`
  width: 100vw;
  height: 800px;
  // background: #ebf5f0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    height: auto;
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    overflow-x: hidden;
  }
`;
export const AtmWrapper = styled.div`
  // background: red;
  width: 85%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    border-radius: 50px;
    width: 40%;

    @media screen and (max-width: 960px) {
      width: 80%;
      // margin-bottom: 2rem;
    }
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    padding-top: 20px;
    padding-bottom: 20px;
    overflow-x: hidden;
  }
`;

// export const A2 = styled.div`
//   // background: blue;
//   width: 40%;
//   height: auto;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   align-items: flex-end;
//   justify-content: center;

//   @media screen and (min-width: 961px) and (max-width: 1200px) {
//     // background: blue;
//     width: 50%;
//     height: auto;
//     display: flex;
//     flex-direction: column;
//     align-items: flex-start;
//     justify-content: center;
//   }
//   @media screen and (max-width: 960px) {
//     width: 100vw;
//     height: auto;
//     padding-top: 60px;
//     padding-bottom: 60px;
//     display: flex;
//     justify-content: center;
//     align-items: flex-start;
//     // background: green;
//     overflow-y: hidden;
//     // }background-color: red;
//   }
//   @media screen and (max-width: 768px) {
//     // background: blue;
//     width: 100vw;
//     height: auto;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     overflow-x: hidden;
//     margin-bottom: 50px;
//   }
//   @media screen and (max-width: 600px) {
//     width: 100vw;
//     padding-top: 0px;
//     padding-bottom: 0px;
//     margin-bottom: 30px;
//     overflow-x: hidden;
//   }
//   @media screen and (max-width: 480px) {
//     width: 100vw;
//     padding-top: 0px;
//     padding-bottom: 0px;
//     margin-bottom: 30px;
//     overflow-x: hidden;
//   }
//   @media screen and (max-width: 375px) {
//     width: 100vw;
//     padding-top: 0px;
//     padding-bottom: 0px;
//     margin-bottom: 30px;
//     overflow-x: hidden;
//   }
//   @media screen and (max-width: 320px) {
//     width: 100vw;
//     padding-top: 0px;
//     padding-bottom: 0px;
//     margin-bottom: 30px;
//     overflow-x: hidden;
//   }
// `;
export const A2Description = styled.div`
  //   background: green;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;
  gap: 20px;

  span {
    color: #a92f34;
  }

  talk {
    color: #a92f34;
    display: flex;
    text-wrap: nowrap;
    gap: 10px;
    margin-bottom: 0px;

    img {
      width: 30%;
    }
  }

  p {
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-bottom: 0;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    p {
      width: 90%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      // background: blue;
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    align-items: flex-start;
    overflow-y: hidden;
    // background: white;

    p {
      width: 100%;
      font-size: 18px;
      // background: red;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    overflow-y: hidden;
    // background: white;

    p {
      width: 100%;
      font-size: 18px;
      // background: red;
    }
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    overflow-y: hidden;
    // background: white;

    p {
      width: 280px;
    }
  }
`;
export const A21 = styled.div`
  // background: blue;
  width: 90%;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AtmOption = styled.div`
  // background: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptionsAtm = styled.div`
  // background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #667085;
  }
`;

export const Perks = styled.div`
  width: 100vw;
  height: 1050px;
  height: auto;
  background-image: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%);
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: yellow;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const Information = styled.div`
  //   background: red;
  width: 80%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  overflow-x: hidden;

  p {
    width: 907px;
    // height: 56px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 20px;
    margin-right: 0px;
    margin-left: 0px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;

    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: blue;
    width: 100vw;
    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    p {
      width: 90%;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
    p {
      width: 90%;
      line-height: 25px;
      margin-bottom: 40px;
      margin-top: 30px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    p {
      width: 90%;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    p {
      width: 90%;
      line-height: 22px;
      font-size: 16px;
    }
  }
`;

export const PerkButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 94px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
  transition: background 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;
  margin-top: 90px;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PerkHeader = styled.div`
  width: 456px;
  height: 44px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d2939;
  margin-top: 20px;



  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    font-size: 34px;
  }
  @media screen and (max-width: 480px) {
    font-size: 28px;
  }
  @media screen and (max-width: 375px) {
    font-size: 26px;
  }
  @media screen and (max-width: 320px) {
    fontsize: 24px;
  }
`;

export const CardWrapper = styled.div`
  // background: red;
  width: 90%;
  height: auto;
  //   background: green;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  padding: 40px 0px;
  overflow: hidden;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    gap: 0px;
    height: auto;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: green;
    width: 100vw;
    height: 1050px;
    margin-bottom: 0px;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    gap: 0px;
    padding: 10px 0px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 100vw;
    height: 2200px;
    gap: 0px;
    padding: 0px 0px;
  }
`;
