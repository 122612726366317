import React, {useEffect} from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import AssetCard from "../pages/AssetCard";
import history from "../../images/AssetsHistoryLogo.svg"
import anywhere from "../../images/AssetAnywhereLogo.svg"
import roleBaseAccess from "../../images/AssetRoleBaseLogo.svg"
import notification from "../../images/AssetNotificationLogo.svg"
import contactTracking from "../../images/AssetContractTracking.svg"
import workflow from "../../images/AssetAnywhereLogo.svg"
import {
  AssetManagementContainer,
  AssetMaster,
  AssetButton,
  S1,
  S2,
  TextDiv,
  Ready,
  PhoneTrackComponent,
  PhoneTrackDivider,
  PhoneLeft,
  PhoneRight,
  C1,
  C2,
  TeamPageComponent,
  TeamPageDivider,
  TeamPageLeft,
  TeamPageRight,
  T1,
  T2,
  FeaturesContainer,
  FeaturesWrapper
} from "./AssetManagementSolutionStyle";
import { RequestButton } from "../landingPage/trustee/TrusteeStyle";
import ScrollToTop from "react-scroll-to-top";
import phoneTrackImage from "../../images/PhoneTrackImage.png";

const AssetManagementSolution = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <AssetManagementContainer>
      <Header />
      <AssetMaster>
        <S1>Manage your assets your way</S1>
        <S2>
          Finally, simple asset-tracking software that works your way.
          Cloud-based mobile technology provides complete flexibility to manage
          assets on your terms.
        </S2>
        <Link to="/callback" style={{ textDecoration: "none" }}>
          <AssetButton>Request a callback</AssetButton>
        </Link>
      </AssetMaster>
      <PhoneTrackComponent>
        <PhoneTrackDivider>
          <PhoneLeft>
            <img src={phoneTrackImage} alt="access" />
          </PhoneLeft>
          <PhoneRight>
            <C1>Access Control/Time and Attendance </C1>
            <C2>
              We deliver a broad array of security solutions around access
              control, intrusion detection, video surveillance, and management.
              Our OEM partnerships enable our customers to get competitive
              offers and world-class support on high-quality products and
              systems.
            </C2>
          </PhoneRight>
        </PhoneTrackDivider>
      </PhoneTrackComponent>
      <TeamPageComponent>
        <TeamPageDivider>
          <TeamPageLeft>
            <T1>Get your team on the same page</T1>
          </TeamPageLeft>
          <TeamPageRight>
            <T2>
              From boardrooms to storage rooms, our asset tracking software can
              be shared with anyone in your organization Any number of people
              can access our asset tracking software. That means executives can
              easily view reports, managers can view asset history, and
              frontline workers can manage assets anytime, anywhere.
            </T2>
          </TeamPageRight>
        </TeamPageDivider>
      </TeamPageComponent>
      <FeaturesContainer>
        <p>Powerful features you can rely on</p>
        <FeaturesWrapper>
          <AssetCard
            assetSourceImage={history}
            assetTitle="Identity
            Management"
            assetDescription="Eliminate uncertainty. Know your Customers and Know your Business."
          />
          <AssetCard
            assetSourceImage={anywhere}
            assetTitle="Manage Assets from Anywhere"
            assetDescription="Add the mobile app to the Android or iOS devices you use daily."
          />
          <AssetCard
            assetSourceImage={roleBaseAccess}
            assetTitle="Enable role-based access"
            assetDescription="Improve data security and ease of onboarding for every job function in your company."
          />
          <AssetCard
            assetSourceImage={notification}
            assetTitle="Enable Custom Notifications"
            assetDescription="Keep Everyone automatically informed to improve efficiency and planning."
          />
          <AssetCard
            assetSourceImage={contactTracking}
            assetTitle="Contract and Maintenance Tracking"
            assetDescription="Get notified when equipment and technology contracts are set to expire."
          />
          <AssetCard
            assetSourceImage={workflow}
            assetTitle="Support your Workflows"
            assetDescription="Enhance your workflows with custom actions for faster and easier asset updates and management."
          />
        </FeaturesWrapper>
      </FeaturesContainer>
      <TextDiv>
        <Ready>Ready to get started?</Ready>
        <p>
          You need a partner that does more than talk about security, you need
          ChamsAccess, where the security of your premises is our highest
          priority.
        </p>
        <NavLink to="/request-a-callback" style={{ textDecoration: "none" }}>
          <RequestButton>Request a callback</RequestButton>
        </NavLink>
      </TextDiv>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </AssetManagementContainer>
  );
};

export default AssetManagementSolution;
