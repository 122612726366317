import styled from "styled-components";
import dotedComplete from "../../images/DotedComplete.png";
import dotedLeft from "../../images/DotedLeft.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const NewsContainer = styled.div`
  // background: red;
  // height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;
export const GetInTouch = styled.div`
  flex-wrap: wrap;
  width: inherit;
  margin-top: 80px;
  height: 500px;
  background: url(${dotedComplete}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
    height: 400px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
    height: 400px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
    height: 500px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    height: 400px;
    // height: 100vh;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
  }
`;

export const Content = styled.div`
  //   background: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   width: 909px;
  width: auto;
  //   height: 184px;
  height: auto;

  p {
    width: 503px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 480px) {
    // background: blue;
    justify-content: space-around;
    height: 250px;
    width: 100%;
    p {
      width: 90%;
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 375px) {
    p {
      width: 90%;
      line-height: 26px;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 320px) {
    height: 250px;
    p {
      width: 90%;
      line-height: 26px;
      font-size: 16px;
      // margin-bottom: 20px;
    }
  }
`;

export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 94px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
  transition: background 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export const Touch = styled.div`
  width: 533px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #08783d;
  }

  @media screen and (max-width: 480px) {
    width: 400px;
    font-size: 32px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 400px;
    font-size: 30px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 300px;
    line-height: 34px;
    font-size: 26px;
    margin-top: 20px;
    span {
      line-height: 30px;
    }
  }
`;
// export const NewsCotainer = styled.div``;
export const BlogCardWrapper = styled.div`
  // //   background: red;
  // width: 100vw;
  // height: auto;
  // display: flex;
  // justify-content: center;
  // align-content: center;
  // padding-top: 60px;
  // padding-bottom: 60px;
  // overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
// export const NewsCotainer = styled.div``;
export const Wrap = styled.div`
  // background: green;
  width: 95%;
  height: auto;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
  self-align: center;
  padding-top: 0px;
  padding-bottom: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 100vw;
    height: auto;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    padding-top: 15px;
    padding-bottom: 15px;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
  }
`;
// export const NewsCotainer = styled.div``;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 300px;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 350px;
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 59px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 40px;
    line-height: 50px;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    margin: 0px 0px 0px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 23px 33px;
  margin-top: 32px;
  margin-bottom: 50px;
  background: #08783d;
  border-radius: 10px;
  width: 210px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 480px) {
    margin: 0px;
  }
  @media screen and (max-width: 375px) {
    margin: 0px;
  }
`;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
// export const NewsCotainer = styled.div``;
