import React from "react";

export const Matters = () => {
  return (
    <div className="matters">
      <div className="sk-title">Why Securekid Matters</div>
      <div className="sk-subtitle">
        A trusted ally in child safety. Learn how SecureKid makes a difference
        every day.
      </div>
      {/* Update the src attribute to point to your video URL */}
      <iframe
        className="video-frame"
        src="https://adminapi.chamsccess.com/api/secure-kid/video" 
        // width="1200" 
        // height="400" 
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="SecureKid Video"
      />
    </div>
  );
};
