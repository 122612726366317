import React, { useState, useEffect } from "react";
import {
  HeaderContainer,
  LeftNav,
  RightNav,
  ImageLink,
  Navs,
  Item,
  ItemWrapper,
  ItemWrapper2,
  ItemWrapper22,
  ImageWrapper,
  Request,
  Support,
  Drop,
  Text,
  BurgerMenu,
  Item3,
  ItemWrapper3,
  Text3,
} from "./HeaderStyle";
import chamsLogo from "../../../images/chamsLogo.svg";
import arrowDownIcon from "../../../images/arrowDownIcon.svg";
import { RiCloseFill } from "react-icons/ri";
import { MdDehaze } from "react-icons/md";
import { Link } from "react-router-dom";
import WhoAreWe from "../navs/WhoWeAre";
import OurSolutions from "../navs/OurSolutions";
import OurSubsidiary from "../navs/OurSubsidiary";
import Media from "../navs/Media";

const Header = () => {
  const [showShadow, setShowShadow] = useState(false);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [burgerToggle, setBurgerToggle] = useState(false);
  const [who, setWho] = useState(false);
  const [our, setOur] = useState(false);
  const [sub, setSub] = useState(false);
  const [med, setMedia] = useState(false);

  const changeBuggerMenu = () => {
    setBurgerToggle(!burgerToggle);
  };

  const closeBuggerMenu = () => {
    // console.log("closed bugger menu");
    setBurgerToggle(false);
  };

  const showWhoWeAre = () => {
    setWho(true);
    setOur(false);
    setSub(false);
    setMedia(false);
  };
  const showOur = () => {
    setWho(false);
    setOur(true);
    setSub(false);
    setMedia(false);
  };
  const showSub = () => {
    setWho(false);
    setOur(false);
    setSub(true);
    setMedia(false);
  };
  const showMed = () => {
    setWho(false);
    setOur(false);
    setSub(false);
    setMedia(true);
  };

  // function that determines when the box-shadow of the header component shows and disappears.
  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    if (currentScrollY > lastScrollY) {
      setShowShadow(true);
    } else {
      setShowShadow(false);
    }
    setLastScrollY(currentScrollY);
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollY]);

  return (
    <HeaderContainer showShadow={showShadow}>
      <LeftNav>
        <ImageWrapper>
          <Link to="/">
            <ImageLink src={chamsLogo} alt="chamsLogo" />
          </Link>
        </ImageWrapper>
      </LeftNav>
      <BurgerMenu onClick={changeBuggerMenu}>
        {burgerToggle ? (
          <RiCloseFill size={40} style={{ fill: "red" }} />
        ) : (
          <MdDehaze size={40} style={{ fill: "#002644" }} />
        )}
      </BurgerMenu>
      <Navs onclick={changeBuggerMenu} toggle={burgerToggle}>
        <Item>
          <ItemWrapper
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <Text
              onClick={() => {
                showWhoWeAre();
              }}
            >
              Who are we
              <span>
                <img src={arrowDownIcon} alt="arrowDown" />
              </span>
            </Text>
            {toggle && who ? (
              <Drop
              // hd
              >
                <WhoAreWe />
              </Drop>
            ) : null}
          </ItemWrapper>
        </Item>
        <Item>
          <ItemWrapper2
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <Text
              onClick={() => {
                showOur();
              }}
            >
              Our solutions
              <span>
                <img src={arrowDownIcon} alt="arrowDown" />
              </span>
            </Text>
            {toggle && our ? (
              <Drop
              // hd
              >
                {console.log("our solution")}
                <OurSolutions />
              </Drop>
            ) : null}
          </ItemWrapper2>
        </Item>
        <Item>
          <ItemWrapper22
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <Text
              onClick={() => {
                showSub();
              }}
            >
              Our subsidiaries
              <span>
                <img src={arrowDownIcon} alt="arrowDown" />
              </span>
            </Text>
            {toggle && sub ? (
              <Drop
              // hd
              >
                <OurSubsidiary />
              </Drop>
            ) : null}
          </ItemWrapper22>
        </Item>
        {/* <Item3>
          <ItemWrapper3
            style={{ marginRight: "40px", textDecoration: "none" }}
            onClick={closeBuggerMenu}
          >
            <Link to="/insights" style={{ textDecoration: "none" }}>
              <Text3>News and insights</Text3>
            </Link>
          </ItemWrapper3>
        </Item3> */}
        <Item>
          <ItemWrapper22
            onClick={() => {
              setToggle(!toggle);
            }}
          >
            <Text
              onClick={() => {
                showMed();
              }}
            >
             News and Insights
              <span>
                <img src={arrowDownIcon} alt="arrowDown" />
              </span>
            </Text>
            {toggle && med ? (
              <Drop
              // hd
              >
                <Media />
              </Drop>
            ) : null}
          </ItemWrapper22>
        </Item>
        <RightNav>
          <Link to="/request-a-callback" style={{ textDecoration: "none" }}>
            <Request onClick={closeBuggerMenu}>Request a callback</Request>
          </Link>
          <a
            href="https://support.chamsaccess.com"
            style={{ textDecoration: "none" }}
            target="_blank"
            rel="noreferrer"
          >
            <Support onClick={closeBuggerMenu}>Support Center</Support>
          </a>
        </RightNav>
      </Navs>
    </HeaderContainer>
  );
};

export default Header;
