import React from "react";

import storyImg from "../assets/storyImage.png";

export const Story = () => {
  return (
    <div className="story">
      <div className="story-text">
        <div className="sk-title">The story behind this</div>
        <div className="sk-subtitle">
          The inception of ChamsAccess Security Solution System was driven by a
          growing need for enhanced safety measures in schools and communities.
          Recognizing the challenges posed by modern-day security threats, we
          embarked on a mission to create reliable, technology-driven solutions.
          This journey led to the development of SecureKid, a dedicated tool for
          safeguarding students, and Trackman, a versatile tracking solution for
          broader use. Together, these products form the backbone of ChamsAccess
          security solution, reflecting our commitment to creating a safer
          environment for everyone.
        </div>
      </div>
      <div className="st-img-div">
        <img src={storyImg} alt="tablet and pen" className="st-img" />
      </div>
    </div>
  );
};
