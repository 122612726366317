import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import recruitment from "../../images/Recruitment.svg";
import payroll from "../../images/Payroll.svg";
import leave from "../../images/Learning.svg";
import learning from "../../images/Learning.svg";
import remitance from "../../images/Remitance.svg";
import wellBeing from "../../images/well-being.svg";
import support from "../../images/support.svg";
import performance from "../../images/Performance.svg";
import HrHero from "../../images/HrHeroSolutions.svg";
import benefitsImage from "../../images/BenefitsImage.png";
import fistBumpGroup from "../../images/FistBumpGroup.png";
import photoWall from "../../images/photo-wall.png";
import atmIcon from "../../images/AtmIcon.svg";
import atmIconGreen from "../../images/AtmIconGreen.svg";
import expertise from "../../images/experience.svg";
import tailored from "../../images/tailored.svg";
import trackRecord from "../../images/track_record.svg";
import clientSatisfaction from "../../images/client_satisfation.svg";
import interview from "../../images/Interview.svg";
import circle from "../../images/circleEdge.svg";
import circle2 from "../../images/circleEdge2.svg";
import arrowRightWine from "../../images/arrow-right-wine.svg";
import arrowRight from "../../images/arrow-right-white.svg";
import parse from "html-react-parser";
import {
  Hero1,
  HeroContent,
  HeroImage,
  ContentHeader,
  ContentDescription,
  ContentButton,
  Atm,
  AtmWrapper,
  A2Description,
  A21,
  AtmOption,
  OptionsAtm,
  A1,
  A2,
  AssetManagementContainer,
  TextDiv,
  Ready,
  Perks,
  Information,
  PerkHeader,
  CardWrapper,
} from "./HrsolutionStyle";
import { RequestButton } from "../landingPage/trustee/TrusteeStyle";
import ScrollToTop from "react-scroll-to-top";
import HrPageCard from "./HrPageCard";

const AssetManagementSolution = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <AssetManagementContainer>
      <Header />
      <Hero1>
        <img
          src={circle}
          alt="realImage"
          // data-aos="fade-left"
          // data-aos-offset="50"
          // data-aos-delay="50"
          // data-aos-duration="2000"
          // data-aos-easing="ease-in-out"
          // data-aos-mirror="true"
          // data-aos-once="false"
          // data-aos-anchor-placement="top-center"
          className="circleEdge"
        />
        <HeroContent>
          <ContentHeader style={{ fontSize: "48px"}} >
            Let’s Transform Your <span>Workforce</span> Together
          </ContentHeader>
          <ContentDescription>
            We are dedicated to handling your staff's needs, while allowing you
            to focus on achieving your business goals.
          </ContentDescription>
          <Link to="contact" style={{ textDecoration: "none" }}>
            <ContentButton>Contact us</ContentButton>
          </Link>
        </HeroContent>
        <HeroImage>
          <img
            src={HrHero}
            alt="realImage"
            // data-aos="fade-left"
            // data-aos-offset="50"
            // data-aos-delay="50"
            // data-aos-duration="2000"
            // data-aos-easing="ease-in-out"
            // data-aos-mirror="true"
            // data-aos-once="false"
            // data-aos-anchor-placement="top-center"
          />
        </HeroImage>
      </Hero1>
      <Atm>
        <AtmWrapper>
          <img src={fistBumpGroup} alt="kid1" />

          <A2>
            <A2Description>
              <A21>What We Offer</A21>
              <p>
                At ChamsAccess will help you empower employees by managing their
                professional development, well-being, and lifestyle, ensuring
                holistic growth and satisfaction.
              </p>
              <AtmOption>
                <OptionsAtm>
                  <img src={atmIconGreen} alt="opt" />
                  <p>
                    Providing comprehensive training and development programs to
                    enhance skills and career growth.
                  </p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIconGreen} alt="opt" />
                  <p>
                    Efficient salary processing ensuring accurate and timely
                    compensation for your workforce.
                  </p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIconGreen} alt="opt" />
                  <p>
                    Implementing employee recognition and reward systems to
                    motivate and retain top talent.
                  </p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIconGreen} alt="opt" />
                  <p>
                    We provide robust tools for managing absence policies and
                    requests, ensuring compliance and employee satisfaction.
                  </p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIconGreen} alt="opt" />
                  <p>
                    Offering flexible work arrangements to accommodate diverse
                    lifestyles and needs.
                  </p>
                </OptionsAtm>
              </AtmOption>
            </A2Description>
          </A2>
        </AtmWrapper>
        {/* <img
          src={circle2}
          alt="realImage"
          // data-aos="fade-left"
          // data-aos-offset="50"
          // data-aos-delay="50"
          // data-aos-duration="2000"
          // data-aos-easing="ease-in-out"
          // data-aos-mirror="true"
          // data-aos-once="false"
          // data-aos-anchor-placement="top-center"
          className="circleEdge"
        /> */}
      </Atm>
      <Perks>
        <Information>
          <PerkHeader>Why You Should Think Of Us</PerkHeader>
          <p>
            We will manage all your needs to elevate your organisation to the
            desired level of operations. Our flexible approach allows us to
            tailor our services to meet the exact needs of your company,
            ensuring optimal efficiency and growth.
          </p>
        </Information>
        <CardWrapper>
          <HrPageCard
            sampleImage={expertise}
            cardHeading="Expertise and Experience"
            color="#FFFAEB"
            align="center"
            pad="27px"
            // Description="We would find the right candidates, streamline your hiring journey, and build your dream team with our services."
          />
          <HrPageCard
            sampleImage={wellBeing}
            cardHeading={parse("Holistic Employee Well-being")}
            align="center"
            pad="27px"
            // Description="Equip you employees by seamlessly managing all remitances, including pensions, taxes, etc, on their behalf without any hassle."
          />
          <HrPageCard
            sampleImage={tailored}
            cardHeading="Tailored Approach"
            color="#F6EAEB"
            align="center"
            pad="27px"

            // Description="Payroll shouldn't be a headache. Whether you have a big or small team, we'll handle it smoothly and make sure your employees get paid on time."
          />
          <HrPageCard
            sampleImage={support}
            cardHeading={parse("Continuous Support")}
            color="#FFFAF5"
            align="center"
            pad="27px"
            // Description="Equip you employees by seamlessly managing all remitances, including pensions, taxes, etc, on their behalf without any hassle."
          />
          <HrPageCard
            sampleImage={trackRecord}
            cardHeading="Proven Track Record"
            align="center"
            pad="27px"
            // Description="With chamsaccess HR services, you can manage and streamline all leave types for different grades of employees."
          />
          <HrPageCard
            sampleImage={clientSatisfaction}
            cardHeading="Client Satisfaction"
            color="#FFFAF5"
            align="center"
            pad="27px"
            // Description="ChamsAccess HR services will help organise employee training to fester growth and enhance performance effortlessly."
          />
        </CardWrapper>
      </Perks>
      <Atm>
        <AtmWrapper>
          <A2>
            <A2Description>
              <A21>
                <span>Awesome Benefits</span> Of Our Services
              </A21>
              <p>
                We will streamline operations, simplifying the process to ensure
                you get what you need effortlessly. Explore the advantages;
              </p>
              <AtmOption>
                <OptionsAtm>
                  <img src={atmIcon} alt="opt" />
                  <p>Boost productivity with efficient HR operrations.</p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIcon} alt="opt" />
                  <p>
                    Reduce operational costs through streamlined HR features.
                  </p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIcon} alt="opt" />
                  <p>Enhance satisfaction and engagement among your staff.</p>
                </OptionsAtm>
                <OptionsAtm>
                  <img src={atmIcon} alt="opt" />
                  <p>Develop and nurture a high-performing workforce.</p>
                </OptionsAtm>
              </AtmOption>
              <Link to="contact" style={{ textDecoration: "none" }}>
                <talk>
                  Talk to us <img src={arrowRightWine} />
                </talk>
              </Link>
            </A2Description>
          </A2>
          <img src={benefitsImage} alt="kid1" />
        </AtmWrapper>
        {/* <img
          src={circle2}
          alt="realImage"
          // data-aos="fade-left"
          // data-aos-offset="50"
          // data-aos-delay="50"
          // data-aos-duration="2000"
          // data-aos-easing="ease-in-out"
          // data-aos-mirror="true"
          // data-aos-once="false"
          // data-aos-anchor-placement="top-center"
          className="circleEdge"
        /> */}
      </Atm>

      <Atm style={{ background: "#ebf5f0" }}>
        <AtmWrapper>
          <img src={photoWall} alt="kid1" />
          <HeroContent>
            <ContentHeader>
              Need Help Managing Staff Across Nigeria & Africa?
            </ContentHeader>
            <ContentDescription>
              At ChamsAccess, we're more than just staff managers; we're here to
              help your team succeed. We're dedicated to your team's growth and
              smooth operations.
            </ContentDescription>
            <Link to="contact" style={{ textDecoration: "none" }}>
              <ContentButton>
                Get started <img src={arrowRight} />
              </ContentButton>
            </Link>
          </HeroContent>
        </AtmWrapper>
      </Atm>
      <TextDiv>
        <Ready>Ready to get started?</Ready>
        <p>
          Got questions about our services or want to learn more about how we
          can assist you in empowering your workforce and help your business
          grow? Our team is here to help.
        </p>
        <NavLink to="contact" style={{ textDecoration: "none" }}>
          <RequestButton>Contact us</RequestButton>
        </NavLink>
      </TextDiv>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </AssetManagementContainer>
  );
};

export default AssetManagementSolution;
