import React, { useState, useEffect } from "react";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import axios from "axios";
import NewsAndInsightCard from "./NewsAndInsightCard";
import { Link } from "react-scroll";
import { Link as NavLink } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import {
  NewsContainer,
  CareerPageContainer,
  GetInTouch,
  Content,
  Contact,
  Touch,
  BlogCardWrapper,
  Wrap,
  TextDiv,
  Ready,
  RequestButton,
} from "./NewsAndInsightStyle";

const NewsAndInsight = () => {
  const [blogData, setBlogData] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = "https://blogapi.chamsaccess.com/api/v1/visitors/blogs";
  // const url = "http://localhost:5050/api/v1/visitors/blogs";
  // function to get all blogs from the database
  const fetchBlogs = async () => {
    const response = await axios.get(url);
    const mainData = response.data.data;
    // sort the data to show the most recent event first
    const sortedBlogData = mainData.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
    setBlogData(sortedBlogData);
    setLoading(false);
  };
  // console.log(blogData);

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    fetchBlogs();
  }, []);

  return (
    <NewsContainer>
      <Header />
      <GetInTouch>
        <Content>
          <Link
            to="insights"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1500}
          >
            <Contact>Blog</Contact>
          </Link>

          <Touch>News & Insights</Touch>
          <p>
            We would love to respond to your queries. Feel free to get in touch
            with us.
          </p>
        </Content>
      </GetInTouch>
      <BlogCardWrapper className="row d-flex justify-content-center mb-5 ">
          {loading ? (
            <ClipLoader className="col-12" color="green" size={30} />
          ) : (
            <>
              {blogData.map((blog) => (
                  <NewsAndInsightCard
                    name={blog.label}
                    label={blog.label}
                    sampleImage={`https://blogapi.chamsaccess.com/uploads/${blog.captionImage}`}
                    // sampleImage={`http://localhost:5050/uploads/${blog.captionImage}`}
                    // sampleImage={blog.captionImage}
                    blogTitle={blog.title}
                    description={blog.description}
                    learnMore="Learn More"
                    id={blog._id}
                  />
              ))}
            </>
          )}
      </BlogCardWrapper>
      <TextDiv>
        <Ready>Download our annual general report</Ready>
        <NavLink to="" style={{ textDecoration: "none" }}>
          <RequestButton>Download Report</RequestButton>
        </NavLink>
      </TextDiv>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </NewsContainer>
  );
};

export default NewsAndInsight;
