import styled from "styled-components";

export const BiometricContainer = styled.div`
  //   background: red;
  height: auto;
  display: flex;
  flex-direction: column;
 overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const BiometricWrapper = styled.div`
  position: relative;
  //   background: blue;
  width: 100%;
  height: 700px;
  // height: auto; //this will be auto on mobile devices.
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) {
    position: relative;
    //   background: blue;
    width: 100%;
    height: 700px;
    // height: auto; //this will be auto on mobile devices.
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 850px;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: 700px;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: 730px;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: 680px;
    // height: auto;
    overflow-y: hidden;
  }
`;
export const BiometricBg = styled.div`
  justify-self: flex-end;
  align-self: flex-end;
  height: auto;

  img {
    width: 1048px;
    height: 700px;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 80px;

    img {
      width: 100%;
      height: 500px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    margin-top: 80px;

    img {
      width: 100%;
      height: 500px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 80px;

    img {
      width: 100%;
      height: 280px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 80px;

    img {
      width: 100%;
      height: 250px;
    }
  }
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 80px;

    img {
      width: 100%;
      height: 220px;
    }
  }
`;
export const BiometricTransparentCard = styled.div`
  position: absolute;
  width: 700px;
  height: 400px;
  left: 120px;
  top: 150px;
  top: 180px;
  background: rgba(243, 243, 243, 0.6);
  backdrop-filter: blur(4px);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  p {
    margin-top: 20px;
    width: 603px;
    height: 56px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
    margin-left: 56px;
    margin-right: 41px;
    margin-bottom: 20px;
  }

  span {
    color: #08783d;
  }

  @media screen and (max-width: 960px) {
    position: absolute;
    width: 90%;
    height: 400px;
    left: 5%;
    top: 50%;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-top: 20px;
      width: 603px;
      height: 56px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-left: 56px;
      margin-right: 41px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    position: absolute;
    width: 90%;
    height: 400px;
    left: 5%;
    top: 50%;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      margin-top: 20px;
      width: 603px;
      height: 56px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-left: 56px;
      margin-right: 41px;
      margin-bottom: 20px;
    }
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    position: absolute;
    width: 90%;
    height: auto;
    left: 5%;
    top: 45%;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      margin-top: 20px;
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-left: 56px;
      margin-right: 41px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  @media screen and (max-width: 375px) {
    position: absolute;
    width: 90%;
    height: auto;
    left: 5%;
    top: 42%;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      margin-top: 20px;
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-left: 56px;
      margin-right: 41px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  @media screen and (max-width: 320px) {
    position: absolute;
    width: 90%;
    height: auto;
    left: 5%;
    top: 35%;
    background: rgba(243, 243, 243, 0.6);
    backdrop-filter: blur(4px);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      margin-top: 20px;
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-left: 56px;
      margin-right: 41px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
`;
export const Deliver = styled.div`
  // background: red;
  width: 603px;
  height: 120px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #1d2939;
  // margin-top: 40px;
  margin-left: 56px;
  margin-right: 41px;

  @media screen and (max-width: 960px) {
    width: 90%;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    span {
    }
  }
  @media screen and (max-width: 768px) {
    width: 90%;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    margin-left: 0px;
    margin-right: 0px;
    span {
    }
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ButtonCover = styled.div`
  // background: blue;
  width: 80%;
  margin-left: 56px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    height: auto;
    margin-top: 10px;
    margin-left: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const BiometricButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: inherit;
  height: 44px;
  background: #08783d;
  border-radius: 8px;
  // margin-left: 56px;
  // margin-right: 41px;
  cursor: pointer;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  transition: transform 1s;

  :hover {
    transform: scale(0.96);
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Story1 = styled.div`
  width: 100%;
  height: 900px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  // background: red;
  overflow-x: hidden;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    width: 100%;
    height: auto;
    background: linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
    display: flex;
    flex-direction: column;
    align-items: center;
    // background: red;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;
export const StoryDescription = styled.div`
  width: 977px;
  height: 114px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-top: 113px;
  margin-bottom: 50px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
    height: auto;
    margin-top: 60px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-bottom: 80px;

    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-bottom: 80px;

    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 38px;
    text-align: center;
    color: #101828;
    margin-top: 50px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 85%;
    height: auto;
    margin-bottom: 80px;

    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    color: #101828;
    margin-top: 50px;
  }
`;
export const ImageAndText = styled.div`
  // background: red;
  width: 100vw;
  height: 510px;
  height: 90%;
  display: flex;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // margin-bottom: 60px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left = styled.div`
  // background: red;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 664px;
    height: 510px;
    // object-fit: cover;
    self-align: center;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;

    img {
      width: 75%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 90px;

    img {
      width: 85%;
      height: 510px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 90px;

    img {
      width: 85%;
      height: 510px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 90px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 90px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 90px;

    img {
      width: 85%;
      height: 250px;
    }
  }
`;
export const Right = styled.div`
  //   background: green;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    width: 539px;
    width: 500px;
    height: 247px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-top: 0px;
    margin-left: 80px;
  }

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 539px;
      width: 500px;
      height: 247px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 30px;
      color: #475467;
      margin-top: 0px;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 50px;

    p {
      // background: yellow;
      width: 80%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: brown;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // padding-top: 30px;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // background: yellow;
      width: 80%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: brown;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // padding-top: 30px;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // background: yellow;
      width: 80%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: brown;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // padding-top: 30px;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // background: yellow;
      width: 85%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: brown;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // padding-top: 30px;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // background: yellow;
      width: 85%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: brown;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // padding-top: 30px;
    // padding-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      // background: yellow;
      width: 85%;
      height: auto;
      margin-left: 0px;
      margin-right: 0px;
      font-size: 18px;
      line-height: 28px;
    }
  }
`;
export const Left2 = styled.div`
  //   background: red;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 664px;
    height: 510px;
  }

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 682px;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: black;
    width: 100%;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 80%;
      height: 410px;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80%;
      height: 410px;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 80%;
      height: 310px;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 80%;
      height: 280px;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 80%;
      height: 250px;
    }
  }
`;
export const Right2 = styled.div`
  // background: green;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  // justify-content: flex-start;
  // align-items: flex-start;

  @media screen and (min-width: 961px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 40px;
    // background: green;
  }
  @media screen and (max-width: 768px) {
    // background: brown;
    width: 100vw;
    height: auto;
    // overflow-y: hidden;
    padding-top: 0px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    // align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Story2 = styled.div`
  // background: green;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ParaInfo = styled.div`
  // background: yellow;
  width: 40vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  p {
    width: 100vw;
    height: auto;
    margin-left: 0px;
    // background: pink;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-top: 0px;
    margin-left: 0%;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: yellow;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      text-align: left;
      width: auto;
      height: auto;
      margin-left: 5%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 85vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    p {
      text-align: center;
      width: 85vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: yellow;
    width: 85vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;

    p {
      text-align: center;
      width: 85vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    p {
      text-align: center;
      width: 85vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    p {
      text-align: left;
      width: 85vw;
      height: auto;
      margin-left: 0px;
      font-size: 18px;
      line-height: 29px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Right2Head = styled.div`
  // background: pink;
  width: auto;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: auto;
    height: auto;
    margin-left: 5%;
    // text-align: center;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 85vw;
    height: auto;
    margin-left: 7%;
    text-align: left;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Right2Navs = styled.div`
  // background: red;
  display: flex;
  width: auto;
  margin-bottom: 23px;
  margin-top: 80px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    margin-left: 0%;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    margin-left: 5%;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;
    // background: white;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Right2Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  //   width: 99px;
  height: 28px;
  background: #ebf5f0;
  border-radius: 97px;
  cursor: pointer;
  font-weight: normal;
  margin-right: 10px;
  transition: transform 1s;

  width: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #08783d;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    margin: 6px;
  }
  @media screen and (max-width: 480px) {
    margin: 6px;
  }
  @media screen and (max-width: 375px) {
    margin: 6px;
  }
  @media screen and (max-width: 320px) {
    margin: 6px;
  }
`;
export const Story3 = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  // background: red;
  overflow-x: hidden;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left3 = styled.div`
  //   background: red;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 664px;
    height: 510px;
  }

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 682px;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 664px;
      height: 510px;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80%;
      height: 410px;
    }
  }
  @media screen and (max-width: 480px) {
    img {
      width: 85%;
      height: 310px;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 85%;
      height: 310px;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 85%;
      height: 250px;
    }
  }
`;
export const Right3 = styled.div`
  // background: green;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;

 
    @media screen and (min-width: 961px) {
      // background: yellow;
      width: 50%;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @media screen and (max-width: 960px) {
      width: 100vw;
      height: auto;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      align-items: flex-start;
      margin-bottom: 40px;
      // background: green;
    }
  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column-reverse;
    justify-content: center:
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ParaInfo2 = styled.div`
  // background: yellow;
  width: 40vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  p {
    width: 100vw;
    height: auto;
    margin-left: 0px;
    // background: pink;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-top: 0px;
    margin-left: 0%;
  }
  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: yellow;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      text-align: left;
      width: auto;
      height: auto;
      margin-left: 0%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
`;
export const Right3Navs = styled.div`
  //   background: blue;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 23px;
  margin-left: 80px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 70px;
    margin-left: 0%;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
    // margin-left: 5%;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 30px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 30px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 95vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Right3Head = styled.div`
  // background: red;
  width: auto;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: auto;
    height: auto;
    // margin-left: 5%;
    // text-align: center;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 90vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 90vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 90vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-self: center;
  }
`;
export const Right3Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  //   width: 99px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  font-weight: normal;
  margin-right: 10px;
  transition: transform 1s;

  width: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    margin: 6px;
  }
  @media screen and (max-width: 480px) {
    margin: 6px;
  }
  @media screen and (max-width: 375px) {
    margin: 6px;
  }
  @media screen and (max-width: 320px) {
    margin: 6px;
  }
`;
export const Story4 = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  // background: red;
  overflow-x: hidden;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Right4Head = styled.div`
  width: auto;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: auto;
    height: auto;
    margin-left: 5%;
    // text-align: center;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 80vw;
    height: auto;
    text-align: center;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 90vw;
    height: auto;
    text-align: left;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 90vw;
    height: auto;
    text-align: left;
    margin-left: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 90vw;
    height: auto;
    text-align: left;
    margin-left: 10px;
    margin-bottom: 0px;
  }
`;
export const Right4Navs = styled.div`
  //   background: blue;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 23px;
  margin-left: 10%;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    margin-left: 0%;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    margin-left: 5%;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 960px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 768px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 70px;
    align-self: center;
    margin-left: 0%;

    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    display: flex;
    width: 100vw;
    margin-bottom: 23px;
    margin-top: 30px;
    align-self: center;
    margin-left: 0%;

    // background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 0px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 0px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
  }
  @media screen and (max-width: 320px) {
    display: flex;
    margin-bottom: 23px;
    margin-top: 0px;
    align-self: center;
    margin-left: 0%;
    // background: red;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 95%;
  }
`;
export const ParaInfo4 = styled.div`
  // background: yellow;
  width: 40vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  p {
    width: 100vw;
    height: auto;
    margin-left: 0px;
    // background: pink;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #475467;
    margin-top: 0px;
    margin-left: 0%;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: yellow;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    p {
      text-align: left;
      width: auto;
      height: auto;
      margin-left: 5%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 90vw;
    height: auto;
    margin-top: 0px;

    p {
      width: 90vw;
      height: auto;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: yellow;
    width: 90vw;
    height: auto;

    p {
      width: 90vw;
      height: auto;
      margin-left: 0px;
    }
  }
`;
export const Right4Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  //   width: 99px;
  height: 28px;
  background: #ebf5f0;
  border-radius: 97px;
  cursor: pointer;
  font-weight: normal;
  margin-right: 10px;
  transition: transform 1s;

  width: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #08783d;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    margin: 6px;
  }
  @media screen and (max-width: 480px) {
    margin: 6px;
  }
  @media screen and (max-width: 375px) {
    margin: 6px;
  }
  @media screen and (max-width: 320px) {
    margin: 6px;
  }
`;
export const Left4 = styled.div`
  //   background: red;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 664px;
    height: 510px;
  }

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 682px;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 664px;
      height: 510px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 664px;
      height: 510px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 290px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 100vw;
    height: auto;
    displays: flex;
    justify-content: center;
    align-items: center;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 250px;
    }
  }
`;
export const Right4 = styled.div`
  // background: green;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: flex-start;

  @media screen and (min-width: 961px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-items: flex-start;
    margin-bottom: 40px;
    // background: green;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: green;
      height: 260px;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85vw;
    height: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: green;
      height: 260px;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      width: 90%;
      // background: green;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      width: 90%;
      // background: green;
      height: auto;
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      width: 90%;
      // background: green;
      height: auto;
      margin-left: 0px;
    }
  }
`;
export const IndustrialApplication = styled.div`
  // background: red;
  width: 100vw;
  height: auto;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  // background: purple;
  overflow-x: hidden;

  p {
    margin-top: 80px;
    margin-bottom: 50px;
    width: 554px;
    height: 37px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
  }

  @media screen and (min-width: 961px) {
    // background: red;
    width: 100vw;
    height: auto;

    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 80%;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 960px) {
    flex-wrap: wrap;
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;

    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 554px;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 768px) {
    flex-wrap: wrap;
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;
    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 554px;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 600px) {
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;
    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 554px;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 480px) {
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;
    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 90%;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 375px) {
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;
    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 90%;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 320px) {
    // background-color: red;
    width: 100vw;
    overflow-x: hidden;
    p {
      margin-top: 80px;
      margin-bottom: 50px;
      width: 90%;
      height: 37px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
`;
export const IndustrialCardWrapper = styled.div`
  //   background: red;
  width: 100vw;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  overflow-x: hidden;

  @media screen and (min-width: 961px) {
    width: 95%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// export const BiometricContainer = styled.div``;
// export const BiometricContainer = styled.div``;
// export const BiometricContainer = styled.div``;
