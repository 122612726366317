import React, { useState, useEffect } from "react";
import {
  RequestContainer,
  Left,
  Right,
  FormContainer,
  FormContent,
  Heading,
  FormWrapper,
  Form,
  Name,
  Contact1,
  Contact2,
  Message,
  Button,
  Err,
} from "./RequestCallbackFormStyle";
import requestImage from "../../images/RequestImage.png";
import cancelIcon from "../../images/CancelIcon.svg";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const RequestCallback = () => {
  const [loading, setLoading] = useState(false);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const dataSchema = yup.object().shape({
    firstName: yup.string().required("Firstname is required."),
    lastName: yup.string().required("Lastname is required."),
    companyName: yup.string().required("Company name is required."),
    email: yup.string().required("Email field is required."),
    number: yup
      .string()
      .required("phone field is required.")
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "too short")
      .max(11, "too long"),
    location: yup.string().required("Location is required."),
    description: yup.string().required("Message field is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (value) => {
    // console.log(value);

    const {
      firstName,
      lastName,
      companyName,
      email,
      number,
      location,
      description,
    } = value;
    try {
      setLoading(true);
      // const url = "http://localhost:2000/api/users";
      const url = "https://adminapi.chamsaccess.com/api/requestCalls";
      // const url = `${process.env.REACT_APP_BASE_URL}/api/requestCalls`;

      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: {
          firstName,
          lastName,
          companyName,
          email,
          number,
          location,
          description,
        },
      });
      if (response.status === 200) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Message sent",
          text: "You will hear from us.",
          showConfirmButton: true,
        }).then(() => {
          console.log(response.status);
          navigate("/");
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <RequestContainer>
      <Left>
        <p>We will reach out as soon as we can</p>
        <img src={requestImage} alt="seyi" />
      </Left>
      <Right>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            cursor: "pointer",
          }}
          onClick={() => {
            navigate(-1);
          }}
        >
          <img
            src={cancelIcon}
            alt="cancel"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              marginTop: "40px",
              marginRight: "40px",
              marginBottom: "80px",
              display: "flex",
              alignText: "flex-end",
            }}
          />
        </div>
        <FormContainer>
          <FormContent>
            <Heading>Request a callback</Heading>
            <p>
              We appreciate your interest in our services and solutions. Do let
              us know how we can reach out to discuss your project in details.
            </p>
          </FormContent>
          <FormWrapper>
            <Form>
              <Name>
                <label>
                  First name
                  <Err>{errors?.firstName?.message}</Err>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="Firstname"
                    {...register("firstName")}
                  />
                </label>
                <label>
                  Last name
                  <Err>{errors?.lastName?.message}</Err>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Lastname"
                    {...register("lastName")}
                  />
                </label>
              </Name>
              <Contact1>
                <label>
                  Company name
                  <Err>{errors?.companyName?.message}</Err>
                  <input
                    type="text"
                    placeholder="Company"
                    id="company"
                    {...register("companyName")}
                  />
                </label>
                <label>
                  Email address
                  <Err>{errors?.email?.message}</Err>
                  <input
                    type="email"
                    placeholder="Email"
                    id="email"
                    {...register("email")}
                  />
                </label>
              </Contact1>
              <Contact2>
                <label>
                  Phone number
                  <Err>{errors?.number?.message}</Err>
                  <input
                    type="tel"
                    placeholder="07012345678"
                    id="number"
                    {...register("number")}
                  />
                </label>
                <label>
                  Location of customer
                  <Err>{errors?.location?.message}</Err>
                  <input
                    type="text"
                    placeholder="Location"
                    id="location"
                    {...register("location")}
                  />
                </label>
              </Contact2>
              <Message>
                <label>
                  Enquiry description
                  <Err>{errors?.description?.message}</Err>
                  <textarea
                    type="text"
                    placeholder="Enter a description..."
                    id="description"
                    {...register("description")}
                  />
                </label>
              </Message>
              <Button
                disabled={loading}
                type="submit"
                onClick={() => {
                  onSubmit();
                }}
              >
                {loading ? "Loading......" : "Submit"}
              </Button>
            </Form>
          </FormWrapper>
        </FormContainer>
      </Right>
      <ScrollToTop smooth height="13" width="15" color="green" />
    </RequestContainer>
  );
};

export default RequestCallback;
