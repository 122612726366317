import React from "react";

import { Header } from "../components/Header";
import { Footer } from "../components/Footer";
import { Link } from "react-router-dom";
import ControlImg2 from "../assets/Contact-2.png";
import WhiteSands from "../assets/whitesands.png";
import Corona from "../assets/corona.png";
import SecureId from "../assets/secureid.png";
import Appzone from "../assets/appzone.png";
import Dermalog from "../assets/dermalog.png";
import { Expertise } from "../components/Expertise";
import { AboutHero } from "../components/AboutHero";
import { Story } from "../components/Story";
import bulb from "../assets/bulb.svg";
import globe from "../assets/globe.svg";

export const About = () => {
  return (
    <>
      <Header />
      <AboutHero />
      <Story />
      <Expertise />
      <Vision />
      <Control />
      <Footer />
    </>
  );
};

const Vision = () => {
  return (
    <div className="vision-mission">
      <div className="sk-title">Vision and Mission</div>
      <div className="sk-subtitle">
        To provide comprehensive security solutions that enhance safety and
        deliver peace of mind to individuals, including schools, businesses, and
        families.
      </div>
      <div className="vm-cards">
        <div className="vision-card">
          <div className="vc-tag">Our vision</div>
          <div className="vc-text">
            To seamlessly integrate advanced security solutions into daily life,
            ensuring widespread safety and tranquility.
          </div>
          <img className="vc-img" src={bulb} alt="buld" />
        </div>

        <div className="mission-card">
          <div className="mc-tag">Our mission</div>
          <div className="mc-text">
            To deliver comprehensive security solutions that enhance safety and
            offer peace of mind to individuals, schools, businesses, and
            families.
          </div>
          <img className="vc-img" src={globe} alt="buld" />
        </div>
      </div>
    </div>
  );
};

export const Control = () => {
  return (
    <div className="control">
      <div className="control-div">
        <div className="control-content">
          <div className="control-text">
            Get started with Chamsaccess Security Solution
          </div>
          <div className="control-subtext">
            Not sure which solution is best for you?
          </div>

          <Link to={"contact"}>
            <div className="">
              Talk to our sales team
              <svg
                width="12"
                height="10"
                viewBox="0 0 12 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4.99989C11.996 4.56147 11.8193 4.14231 11.5084 3.83323L7.93337 0.249896C7.77724 0.0946866 7.56603 0.00756836 7.34587 0.00756836C7.12572 0.00756836 6.91451 0.0946866 6.75837 0.249896C6.68027 0.327365 6.61827 0.419532 6.57596 0.521081C6.53366 0.622631 6.51188 0.731552 6.51188 0.841562C6.51188 0.951571 6.53366 1.06049 6.57596 1.16204C6.61827 1.26359 6.68027 1.35576 6.75837 1.43323L9.50004 4.16656H1.16671C0.945694 4.16656 0.733732 4.25435 0.577452 4.41064C0.421172 4.56692 0.333374 4.77888 0.333374 4.99989C0.333374 5.2209 0.421172 5.43286 0.577452 5.58915C0.733732 5.74543 0.945694 5.83322 1.16671 5.83322H9.50004L6.75837 8.57489C6.60145 8.7307 6.51286 8.94247 6.51208 9.16361C6.5113 9.38474 6.59839 9.59713 6.75421 9.75405C6.91002 9.91097 7.12179 9.99957 7.34293 10.0003C7.56406 10.0011 7.77645 9.91403 7.93337 9.75822L11.5084 6.17489C11.8214 5.86376 11.9982 5.4412 12 4.99989Z"
                  fill="#344054"
                />
              </svg>
            </div>
          </Link>
        </div>
        <div className="control-img-cont">
          <img src={ControlImg2} className="control-img" />
        </div>
      </div>
      <div className="partners">
        <h3>Our Partners</h3>
        <div className="partners-grid">
          <img src={WhiteSands} alt="logo" />
          <img src={Corona} alt="logo" />
          <img src={SecureId} alt="logo" />
          <img src={Appzone} alt="logo" />
          <img src={Dermalog} alt="logo" />
        </div>
      </div>
    </div>
  );
};
