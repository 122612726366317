import React, { useRef } from "react";
import styled from "styled-components";
import dumebiModal from "../../../images/dumebiModal.png";
import cancelIcon from "../../../images/CancelIcon.svg";

const Modal = ({ setShowModal }) => {
  // close the modal when clicking outside the modal.
  const modalRef = useRef();
  const closeModal = (e) => {
    if (e.target === modalRef.current) {
      setShowModal(false);
    }
  };
  return (
    <ModalCover
      ref={modalRef}
      onClick={closeModal}
      // style={{
      //   position: "fixed",
      //   top: "50%",
      //   left: "50%",
      //   transform: "translate(-50%, -50%)",
      //   width: "100vw",
      //   height: "100vh",
      //   display: "flex",
      //   alignItems: "center",
      //   justifyContent: "center",
      //   background: "rgba(0, 0, 0, 0.7)",
      //   zIndex: "999",
      //   // animation: "animate 0.9s",
      // }}
    >
      <ModalContainer>
        <ModalWrapper>
          <img src={dumebiModal} alt="sourceFile" />
          <Rights2 onClick={() => setShowModal(false)}>
            <img src={cancelIcon} alt="cancel" />
          </Rights2>
          <ModalContent>
            <Tops>
              <Lefts>
                <Name>Dumebi Obodo</Name>
                <Position>Director</Position>
              </Lefts>
              <Rights onClick={() => setShowModal(false)}>
                <img src={cancelIcon} alt="cancel" />
              </Rights>
            </Tops>
            <Buttoms>
              Dumebi Obodo is a consummate professional with over fifteen (15)
              years' cognate experience obtained from operational, management
              and board positions covering business strategy/development,
              Product Development & Deployment, Partnerships & Sales. <br />
              <br />
              Formerly holding the esteemed position of Managing Director at
              ChamsAccess, Dumebi has transition into another pivot role within
              the organization, now serving as a distinguished member of its
              Board of Directors. <br />
              <br />
              With a wealth of experience garnered over years of dedicated
              service in the technology and business sectors, his journey has
              been marked by notable achievements and strategic leadership.
              Having successfully navigated the challenges of executives
              management, Dumebi's transition to a directional role underscores
              a commitment to the long-term vision and sustainable growth of
              ChamsAccess.
              <br />
              <br />
              As a member of the Board of Directors, he brings forth a unique
              blend of visionary insights, industry expertise, and a profound
              understanding of the intricacies that drive success in the dynamic
              landscape of technology and corporate governance. Dumebi has a
              first degree in Microbiology, an MBS from the prestigious Lagos
              Business School (Pan Atlantic University), and is a member of the
              Chartered Institute of Directors of Nigeria.
            </Buttoms>
          </ModalContent>
        </ModalWrapper>
      </ModalContainer>
    </ModalCover>
  );
};

export default Modal;

const ModalCover = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
  // animation: animate 0.9s;
`;

const ModalContainer = styled.div`
  // background: rgba(0, 0, 0, 0.7);
  background: #ffffff;
  // background: red;
  border-radius: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 987px;
  height: 609px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px;
  z-index: 999;
  // animation: animate 0.9s;

  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }

  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: 50vw;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90vw;
    height: 50vw;
    border-radius: 20px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90vw;
    height: 50vw;
    border-radius: 20px;
    padding: 10px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 80vw;
    height: 60vh;
    border-radius: 20px;
    padding: 10px;
    overflow-y: scroll;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 80vw;
    height: 60vh;
    border-radius: 20px;
    // padding: 10px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 80vw;
    height: 100vw;
    height: 60vh;
    border-radius: 20px;
    // padding: 10px;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const ModalWrapper = styled.div`
  // background: yellow;
  width: 98%;
  height: 98%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 90%;
    height: auto;
    height: 95%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    img {
    }
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 100%;
    // height: 95vh;
    // height: 95%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    img {
      width: auto;
      height: 300px;
      margin-top: 30px;
      margin-bottom: 10px;
    }
  }
`;
const ModalContent = styled.div`
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 50px;
  overflow-x: auto;

  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 100vw;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
  }
`;
const Tops = styled.div`
  // background: tomato;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    // background: grey;
    width: 337px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 768px) {
    // background: grey;
    width: 300px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 600px) {
    // background: grey;
    width: 218px;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
  }
  @media screen and (max-width: 480px) {
    // background: grey;
    width: 340px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
    // background: grey;
    width: 300px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
  @media screen and (max-width: 320px) {
    // background: grey;
    width: 256px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 0px;
  }
`;
const Buttoms = styled.div`
  width: 100%;
  height: 429px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  margin-top: 22px;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: auto;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 600px) {
    // background: white;
    width: auto;
    height: auto;
    margin-top: 70px;
  }
  @media screen and (max-width: 480px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
  @media screen and (max-width: 375px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
  @media screen and (max-width: 320px) {
    // background: tomato;
    width: auto;
    height: auto;
    margin-top: 70px;
    padding-bottom: 30px;
    // overflow-y: scroll;
  }
`;
const Rights = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 375px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    display: none;
    background: none;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Rights2 = styled.div`
  display: flex;
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: -20px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 375px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: -20px;
    img {
      width: 24px;
      height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    display: flex;
    background: none;
    position: relative;
    top: -170px;
    right: 0px;
    img {
      width: 24px;
      height: 24px;
    }
  }
`;
const Lefts = styled.div`
  // background-color: red;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background-color: red;
    width: 90%;
  }
  @media screen and (max-width: 375px) {
    // background-color: red;
    width: 90%;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
  }
`;
const Name = styled.div`
  width: 100%;
  height: 32px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: 32px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
    color: #101828;
  }
`;
const Position = styled.div`
  // background: blue;
  width: inherit;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #1d2939;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: inherit;
    height: 24px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #1d2939;
  }
`;
