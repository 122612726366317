import styled from "styled-components";
import customBg from "../../images/CustomBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const CustomSolutionsContainer = styled.div`
  background: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
  }
`;
export const Customizable = styled.div`
  // background: red;
  width: 100vw;
  height: 780px;
  display: flex;
  margin-top: 80px;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: 780px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: 580px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 580px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
  }
`;
export const CustomBanner = styled.div`
  width: 1200px;
  height: 600px;
  background: url(${customBg}), linear-gradient(45deg, #043c1f 0%, #08783d 100%);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background: green;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95vw;
    height: 600px;
    background: url(${customBg}),
      linear-gradient(45deg, #043c1f 0%, #08783d 100%);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
  }
  @media screen and (max-width: 960px) {
    width: 90vw;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 90vw;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  width: 986px;
  height: 260px;
  // background: pink;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 80%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const P1 = styled.p`
  margin-top: 0px;
  width: 900px;
  height: 120px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ebf5f0;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 80%;
    font-size: 38px;
    line-height: 50px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100%;
    font-size: 38px;
    line-height: 50px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100%;
    font-size: 28px;
    line-height: 35px;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100%;
    font-size: 28px;
    line-height: 35px;
    height: auto;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const P2 = styled.p`
  margin-top: 0px;
  width: 900px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ebf5f0;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 80%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 80%;
    font-size: 18px;
    line-height: 26px;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    height: auto;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const CustomButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 187px;
  height: 44px;
  background: #ebf5f0;
  border-radius: 8px;
  margin-top: 20px;
  cursor: pointer;
  transition: background 1s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;

  :hover {
    background: linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
    color: #ffffff;
  }
`;
export const OurTeam = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TeamWrapper = styled.div`
  //   background: red;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: pink;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left1 = styled.div`
  //   background: pink;
  width: 45%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 529px;
    height: 554px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 80%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-x: hidden;
    overflow-y: hidden;

    img {
      width: 70%;
      height: 554px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    // background: red;
    overflow-x: hidden;
    // background: yellow;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
    // background: red;
    overflow-x: hidden;
    // background: yellow;
    overflow-y: hidden;

    img {
      width: 85%;
      height: 354px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-x: hidden;
    // background: yellow;
    overflow-y: hidden;

    img {
      width: 85%;
      height: 310px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-x: hidden;
    // background: yellow;
    overflow-y: hidden;

    img {
      width: 85%;
      height: 270px;
      overflow-y: hidden;
    }
  }
`;
export const Right1 = styled.div`
  //   background: pink;
  width: 45%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  p {
    width: 500px;
    height: 189px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
    margin: 0px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 500px;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
      margin: 0px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 90%;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    p {
      width: 85%;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      color: #101828;
      margin: 0px;
      text-align: center;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      margin-top: 0px;
      width: 85%;
      margin-top: 0px;
      margin-bottom: 20px;
      // background-color: green;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 0px;
      width: 85%;
      margin-top: 0px;
      margin-bottom: 20px;
      // background-color: green;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 28px;
      line-height: 36px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 0px;
      width: 85%;
      margin-top: 0px;
      margin-bottom: 40px;
      // background-color: green;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 28px;
      line-height: 36px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 0px;
      width: 85%;
      margin-top: 0px;
      margin-bottom: 40px;
      // background-color: green;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 26px;
      line-height: 34px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      margin-top: 0px;
      width: 85%;
      margin-top: 0px;
      margin-bottom: 40px;
      // background-color: green;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 24px;
      line-height: 34px;
    }
  }
`;
export const BuildingSolution = styled.div`
  // background-color: red;
  width: 100vw;
  height: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: red;
  overflow-x: hidden;
  overflow-y: hidden;
  margin-top: 30px;

  p {
    width: 891px;
    height: 40px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      width: 891px;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: hidden;

    p {
      width: 90%;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    width: 100vw;
    overflow-x: hidden;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
`;
export const BuildingCardWrapper = styled.div`
  width: 85%;
  height: 749px;
  //   background: tomato;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
    height: 749px;
    //   background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  @media screen and (max-width: 960px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Ready = styled.div`
  width: 1000px;
  height: 60px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  // margin-top: 60px;
  // margin-bottom: 10px;
  margin: 60px 0px 10px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 95vw;
    font-size: 34px;
    line-height: 44px;
    margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
  margin-top: 20px;
`;
// export const CustomSolutionsContainer = styled.div``;
// export const CustomSolutionsContainer = styled.div``;
// export const CustomSolutionsContainer = styled.div``;
// export const CustomSolutionsContainer = styled.div``;
