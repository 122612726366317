import styled from "styled-components";
import bg from "../../images/CyberIndustryWeServeBg.png";

export const AccessManagementContainer = styled.div`
  //   background: red;
  height: auto;
  display: flex;
  flex-direction: column;
  // background: red;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const Management = styled.div`
  position: relative;
  background: #ffffff;
  width: 100vw;
  height: 700px;
  margin-top: 80px;
  display: flex;
  overflow-x: hidden;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 500px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    // justify-content: flex-start;
    align-items: center;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
export const Div1 = styled.div`
  width: 900px;
  height: 700px;
  left: 0px;
  top: 0px;
  background: linear-gradient(45deg, #043c1f 0%, #08783d 100%);
  border-radius: 0px 0px 100px 0px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    // width: 1300px;
    height: 500px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 70vw;
    height: 500px;
  }
  @media screen and (max-width: 768px) {
    width: 500px;
    height: 400px;
    left: 0px;
    top: 0px;
    background: linear-gradient(45deg, #043c1f 0%, #08783d 100%);
    border-radius: 0px 0px 100px 0px;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    width: 500px;
    height: 400px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: auto;
    padding-top: 40px;
    // padding-bottom: 100px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Div2 = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -190px;

  img {
    width: 570px;
    height: 480px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -90px;

    img {
      width: 500px;
      height: 450px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -90px;
    top: -10px;

    img {
      width: 420px;
      height: 380px;
    }
  }
  @media screen and (max-width: 768px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -50px;
    top: -40px;

    img {
      width: 380px;
      height: 280px;
    }
  }
  @media screen and (max-width: 600px) {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -50px;
    top: -40px;

    img {
      width: 380px;
      height: 280px;
    }
  }
  @media screen and (max-width: 480px) {
    // // background: pink;
    // width: 100vw;
    // height: auto;
    // position: relative;
    // display: flex;
    // justify-content: flex-start;
    // top: -70px;
    // left: 0px;

    // img {
    //   width: 380px;
    //   height: 280px;
    // }

    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 280px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 280px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: 100vw;
    height: auto;
    position: relative;
    display: flex;
    justify-content: flex-start;
    top: 0px;
    left: 0px;

    img {
      width: 90%;
      height: 230px;
    }
  }
`;
export const M1 = styled.div`
  width: 455px;
  height: 126px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #fcfcfd;
  margin-bottom: 20px;
  margin-left: 120px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 70%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 70%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 768px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 480px) {
    // width: 80%;
    width: 400px;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    // width: 80%;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 54px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    // width: 80%;
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    letter-spacing: -0.02em;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
`;
export const M2 = styled.div`
  width: 455px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #fcfcfd;
  margin-bottom: 20px;
  margin-left: 120px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 40px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 80%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #fcfcfd;
    margin-bottom: 20px;
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessManagementButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 180px;
  height: 44px;
  background: #ebf5f0;
  border-radius: 8px;
  margin-left: 120px;
  cursor: pointer;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;

  :hover {
    width: 200px;
  }

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    margin-left: 40px;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
  }
  @media screen and (max-width: 768px) {
    margin-left: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementHistory = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementWrapper = styled.div`
  // background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // height: 1300px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ManagementLeft = styled.div`
  // background: yellow;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 75%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 524px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 424px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 324px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 280px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // height: 600px;
    overflow-x: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85vw;
      height: 250px;
    }
  }
`;
export const ManagementRight = styled.div`
  //   background: yellow;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #475467;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 95%;
      height: 73px;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    p {
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    // height: 600px;
    height: auto;
    overflow-x: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    justify-content: flex-end;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TextHeader = styled.div`
  width: 100%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    width: 85%;
    height: 154px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 85%;
    height: 154px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 85%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SecureKidComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidDivider = styled.div`
  width: 90%;
  // background: red;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidLeft = styled.div`
  //   background: green;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 65%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 80%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const K1 = styled.div`
  //   background: blue;
  width: 100%;
  height: 29px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const K2 = styled.div`
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #475467;
  margin-bottom: 26px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const KidOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptList = styled.div`
  //   background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 28px;
    height: 28px;
    margin-right: 12px;
  }

  p {
    width: 100%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
`;
export const KidNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: auto;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    // background: red;
    margin-top: 50px;
  }
`;
export const Name = styled.div`
  width: 82px;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #a92f34;
  margin-right: 5px;
`;
export const AccessControlComponent = styled.div`
  background-color: #ffffff;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlDivider = styled.div`
  // background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlLeft = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlRight = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C1 = styled.div`
  width: 450px;
  height: 72px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C2 = styled.div`
  width: 508px;
  height: 156px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;
export const PrintLabelComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PrintLabelRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 95%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PL1 = styled.div`
  width: auto%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PL2 = styled.div`
  width: auto;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

// new component
export const AssetSolutionComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 95%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AC1 = styled.div`
  width: auto%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AC2 = styled.div`
  width: auto;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AssetSolutionName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;
export const VisitorsManagementComponent = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorDivider = styled.div`
  width: 90%;
  //   background-color: green;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorLeft = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VisitorRight = styled.div`
  // background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: white;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const V1 = styled.div`
  width: 100%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const V2 = styled.div`
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceComponent = styled.div`
  background: white;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceDivider = styled.div`
  //   background: red;
  width: 90%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceLeft = styled.div`
  // background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceRight = styled.div`
  //   background: tomato;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceNav = styled.div`
  //   background: red;
  width: auto;
  hight: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 23px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    text-align: center;
    margin-top: 2px;
    transition: margin 0.5s;
  }

  :hover img {
    margin-left: 15px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 960px) {
    // background: white;
    width: 85vw;
    justify-self: flex-start;
  }
  @media screen and (max-width: 768px) {
    // background: white;
    width: 85vw;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveillanceName = styled.div`
  width: 82px;
  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;
  margin-right: 5px;
`;
export const S1 = styled.div`
  width: 508px;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const S2 = styled.div`
  width: 508px;
  height: 92px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IndustriesWeServe = styled.div`
  position: relative;
  width: 100vw;
  height: 340px;
  background-image: url(${bg});
  background-image: url(${bg}),
    linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    position: relative;
    width: 100vw;
    height: 350px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    position: relative;
    width: 100vw;
    height: 350px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const Gradient = styled.div`
  position: absolute;
  width: inherit;
  height: 340px;
  left: 0px;
  top: 0px;
  background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ServeContent = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
`;
export const ServeLeft = styled.div`
  //   background: red;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-left: 120px;
  //   margin-top: 94px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: width: 30%;
    margin-left: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    height: auto;
    width: width: 30%;
    margin-left: 0px;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    width: width: 100%;
    height: auto;
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    width: width: 100%;
    // height: 600px;
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
`;

export const L1 = styled.p`
  width: 383px;
  height: 44px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 32px;
  }
`;
export const L2 = styled.p`
  width: 406px;
  height: 88px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
  }
`;
export const ServeRight = styled.div`
  //   background: yellow;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-right: 120px;
  //   margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90%;
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90%;
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    // background: green;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
`;
export const R1 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R2 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R3 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R4 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R5 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
