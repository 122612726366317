import styled from "styled-components";

export const SubsidiaryContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px 20px 30px;
  position: absolute;
  //   position: relative;
  width: auto;
  height: auto;
  background: #fcfcfd;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 0px 0px 30px 30px;
  top: 80px;
  //   left: 10%;
  z-index: 999;

  @media screen and (min-width: 1201px) {
    right: -200px;
    width: auto;
  }
  @media screen and (min-width: 961px) and (max-width: 1200px) {
    right: -250px;
    width: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    padding-left: 0px;
    padding-right: 0px;
    width: 798px;
    left: -400px;
    top: 410px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media screen and (max-width: 800px) {
    // background: red;
    padding-left: 0px;
    padding-right: 0px;
    width: 730px;
    left: -365px;
    top: 465px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    padding-left: 0px;
    padding-right: 0px;
    // left: 2%;
    width: 90%;
    left: 5%;
    // top: 15%;
    top: 12px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    padding-left: 0px;
    padding-right: 0px;
    left: 2%;
    width: 95%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }
  @media screen and (max-width: 480px) {
    left: 5%;
    width: 90%;
    display: flex;
    // flex-direction: column;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    left: 5%;
    width: 90%;
    margin-left: 0px;
  }
  @media screen and (max-width: 320px) {
    left: 5%;
    width: 90%;
  }
`;
export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 360px;
  height: auto;
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: center;
    // background: pink;
    // width: auto;
    width: 370px;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    display: flex;
    justify-content: center;
    // background: orange;
    margin-left: 5px;
    // width: auto;
    width: 370px;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    // background: orange;
    margin-left: 10px;
    width: 330px;
  }
  @media screen and (max-width: 320px) {
    width: 280px;
  }
`;
export const Pension = styled.div`
  //   background: red;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  img {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    width: 40px;
  }

  :hover {
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 480px) {
    // background: tomato;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 0px;
    }
    :hover {
      width: 100%;
    }
  }
  @media screen and (max-width: 375px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;

    img {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;

    img {
      margin-left: 0px;
    }
  }
`;
export const Content = styled.div`
  //   background: green;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 282px;
  height: auto;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-right: 10px;

  p {
    width: 282px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    margin-top: 0px;
  }
  // @media screen and (max-width: 480px) {
  //   p {
  //     width: 250px;
  //   }
  // }
  @media screen and (max-width: 375px) {
    width: 260px;
    p {
      width: 95%;
    }
  }
  @media screen and (max-width: 320px) {
    width: 210px;

    p {
      width: 220px;
      width: 95%;
      line-height: 18px;
      font-size: 12px;
    }
  }
`;
export const P1 = styled.div`
  // background: tomato;
  width: 282px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #101828;
  margin-bottom: 4px;

  @media screen and (max-width: 480px) {
    width: 95%;
  }
  @media screen and (max-width: 375px) {
    width: 95%;
  }
  @media screen and (max-width: 320px) {
    width: 95%;
  }
`;
export const ArgonWorld = styled.div`
  //   background: red;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;

  img {
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 10px;
    height: 40px;
    width: 40px;
  }

  :hover {
    background-color: #f1f1f1;
  }

  @media screen and (max-width: 480px) {
    // background: tomato;
    width: 95%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-left: 0px;
    }
    :hover {
      width: 100%;
    }
  }
  @media screen and (max-width: 375px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;

    img {
      margin-left: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 95%;

    img {
      margin-left: 0px;
    }
  }
`;
// export const WhoContainer = styled.div``;
// export const WhoContainer = styled.div``;
// export const WhoContainer = styled.div``;
