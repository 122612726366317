import React, { useEffect, useState } from "react";
import mail from "../../../images/mail.svg";
import chamsLogo from "../../../images/chamsLogo.svg";
import twitter from "../../../images/Twitter.svg";
import linkedIn from "../../../images/LinkedIn.svg";
import facebook from "../../../images/Facebook.svg";
import Instagram from "../../../images/Instagram.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";
import {
  FooterWrapper,
  FooterTop,
  FooterMain,
  Desc,
  Form,
  Wrapper,
  Desc1,
  Desc2,
  Input,
  Button,
  InputWrapper,
  FooterContainer,
  ChamsAccess,
  ChamsAccess2,
  Navs,
  SocialMedia,
  Head,
  Copywrite,
  Err,
} from "./FooterStyle";
import { Link } from "react-router-dom";
import { RiWindowsFill } from "react-icons/ri";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState("");
  let year = new Date().getFullYear();

  // declaring the states
  const [loading, setLoading] = useState(false);

  const dataSchema = yup.object().shape({
    email: yup.string().lowercase().required("Email field is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (value) => {
    // console.log(value);

    const { email } = value;
    try {
      setLoading(true);
      // const url = `api/send-message`;
      // const url = "http://localhost:2000/api/emails";
      const url = "https://adminapi.chamsaccess.com/api/emails";
      // const url = `${process.env.REACT_APP_BASE_URL}/api/contacts`;

      const response = await axios({
        headers: {
          "Content-Type": "application/json",
          Accept: "*",
          mode: "cors",
        },
        url: url,
        method: "post",
        data: {
          email,
        },
      });
      if (response.status === 200) {
        setLoading(false);
        reset(
          {
            email: " ",
          },
          {
            keepErrors: true,
            keepDirty: true,
          }
        );
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Email Sent",
          showConfirmButton: true,
          text: "Thank you for subscribing with us.",
        }).then(() => {
          console.log(response.status);
          navigate("/");
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Email failed to submit",
          showConfirmButton: true,
        });
        // console.log(response.status);
        // setLoading(false);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      // console.error(error.message);
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
    setCurrentYear(year);
  }, [year]);

  return (
    <FooterWrapper>
      <FooterTop>
        <Wrapper>
          <Desc>
            <Desc1>Join our newsletter</Desc1>
            <Desc2>Stay in the loop</Desc2>
          </Desc>
          <Form>
            <Err>{errors?.name?.message}</Err>
            <InputWrapper>
              <img src={mail} alt="placeholder" />
              <Input
                type="email"
                id="email"
                placeholder="Enter your email address"
                // value={name}
                // onChange={(e) => setName(e.target.value)}
                {...register("email")}
              />
            </InputWrapper>
            <Button
              disabled={loading}
              type="submit"
              onClick={() => {
                onSubmit();
              }}
            >
              {loading ? "Loading..." : "Subscribe"}
            </Button>
          </Form>
        </Wrapper>
      </FooterTop>
      <FooterMain>
        <FooterContainer>
          <ChamsAccess>
            <Link to="/">
              <img src={chamsLogo} alt="chamsLogo" />
            </Link>

            <p>8, Louis Solomon Close, Victoria Island, Lagos.</p>
            <p>012914988</p>
            <p>hello@chamsaccess.com</p>
            <p>inquiries@chamsaccess.com</p>
            <SocialMedia>
              <a
                href="https://www.instagram.com/chamsaccessltd"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="instagram" />
              </a>
              <a
                href="https://www.twitter.com/chamsaccess"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/chamsaccessltd"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedIn} alt="linkedin" />
              </a>
              <a
                href="https://www.facebook.com/chamsaccess"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
            </SocialMedia>
          </ChamsAccess>
          <div
            style={{
              // background: "red",
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              alignItems: "flex-start",
              width: "100%",
              height: "auto",
              marginBottom: "20px",
              overflowY: "hidden",
            }}
          >
            <Navs>
              <Head>Company</Head>
              <Link to="/about" style={{ textDecoration: "none" }}>
                <p>About us</p>
              </Link>
              <Link to="/career-page" style={{ textDecoration: "none" }}>
                <p>Careers</p>
              </Link>
              <Link to="/our-impact" style={{ textDecoration: "none" }}>
                <p>Impacts</p>
              </Link>
              {/* <Link to="/contact" style={{ textDecoration: "none" }}>
              <p>FAQs</p>
            </Link> */}
              <Link to="/events" style={{ textDecoration: "none" }}>
                <p>Events</p>
              </Link>
            </Navs>
            <Navs>
              <Head>Our Solutions</Head>
              <Link
                to="/biometric-solutions"
                style={{ textDecoration: "none" }}
              >
                <p>Biometrics Solutions</p>
              </Link>
              <Link
                to="/cybersecurity-solutions"
                style={{ textDecoration: "none" }}
              >
                <p>Cybersecurity Solutions</p>
              </Link>
              <Link to="/payment-solutions" style={{ textDecoration: "none" }}>
                <p>Payments Solutions</p>
              </Link>
              <Link
                to="/hr-solutions"
                style={{ textDecoration: "none" }}
              >
                <p>HR Solutions</p>
              </Link>
              <Link
                to="/access-and-identity-solutions"
                style={{ textDecoration: "none" }}
              >
                <p>Access Management Solutions</p>
              </Link>
              <Link
                to="/customized-solutions"
                style={{ textDecoration: "none" }}
              >
                <p>Custom Solutions</p>
              </Link>
            </Navs>
            <Navs>
              <Head>Our Subsidiaries</Head>
              <Link to="/pension-central" style={{ textDecoration: "none" }}>
                <p>Pencentral</p>
              </Link>
              <Link to="/argone-world" style={{ textDecoration: "none" }}>
                <p>Argone World</p>
              </Link>
            </Navs>
            <Navs>
              <Head>Support</Head>
              <Link to="/contact-us" style={{ textDecoration: "none" }}>
                <p>Contact us</p>
              </Link>
              <a
                href="https://support.chamsaccess.com"
                style={{ textDecoration: "none" }}
                target="_blank"
                rel="noreferrer"
              >
                <p>Support center</p>
              </a>
              <Link to="/request-a-callback" style={{ textDecoration: "none" }}>
                <p>Request a callback</p>
              </Link>
            </Navs>
          </div>
          <ChamsAccess2>
            <Link to="/">
              <img src={chamsLogo} alt="chamsLogo" />
            </Link>

            <p>8, Louis Solomon Close, Victoria Island, Lagos.</p>
            <p>012914988</p>
            <p>hello@chamsaccess.com</p>
            <p>inquiries@chamsaccess.com</p>
            <SocialMedia>
              <a
                href="https://www.instagram.com/chamsaccessltd"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Instagram} alt="instagram" />
              </a>
              <a
                href="https://www.twitter.com/chamsaccess"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" />
              </a>
              <a
                href="https://www.linkedin.com/company/chamsaccessltd"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedIn} alt="linkedin" />
              </a>
              <a
                href="https://www.facebook.com/chamsaccess"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
            </SocialMedia>
          </ChamsAccess2>
        </FooterContainer>
      </FooterMain>
      <Copywrite>© Copyright {currentYear} ChamsAccess Limited.</Copywrite>
    </FooterWrapper>
  );
};

export default Footer;
