import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  ManagementTeamContainer,
  GetInTouch,
  Content,
  Contact,
  Touch,
  BoardOfDirectors,
  Caption,
  ManagementCardWrapper,
  MgtTeam,
} from "./ManagementTeamStyle";
import Header from "../landingPage/header/Header";
import Footer from "../landingPage/footer/Footer";
import ScrollToTop from "react-scroll-to-top";
import ManagementTeamCard from "./ManagementTeamCard";
import ManagementTeam2 from "./ManagementTeam2";
import obodo from "../../images/ManagementObodo.png";
import olayemi from "../../images/ManagementOlayemi.png";
import gbenga from "../../images/ManagementGbenga.png";
import dami from "../../images/ManagementDami.png";
import uche from "../../images/ManagementUche.png";
import femi from "../../images/Femi_Olawale.png";
import bose from "../../images/Abosede_Akinleye.png";
import foke from "../../images/Afoke_Richmond.png";
import irene from "../../images/Irene_Ebong.png";
import mgtArrow from "../../images/ManagementArrow.svg";

const ManagementTeam = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);

  return (
    <ManagementTeamContainer>
      <Header />
      <GetInTouch>
        <Content>
          <Link
            style={{
              textDecoration: "none",
            }}
            to="about"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1500}
          >
            <Contact>Leadership</Contact>
          </Link>

          <Touch>
            Meet the amazing people that lead
            <span> ChamsAccess</span>
          </Touch>
          <p>Our Leadership at Board and Management Levels</p>
        </Content>
      </GetInTouch>
      <BoardOfDirectors>
        <Caption>Board of directors</Caption>
        <ManagementCardWrapper>
          <ManagementTeam2 />
        </ManagementCardWrapper>
      </BoardOfDirectors>
      <MgtTeam>
        <Caption>Management team</Caption>
        <ManagementCardWrapper className="row">
          {/* <ManagementTeamCard
            cardImage={obodo}
            heading="Dumebi Obodo"
            post="MD/CEO"
            description="Dumebi Obodo is a consummate professional with over fifteen (15) years'
            cognate experience obtained from operational, management and board
            positions covering business strategy/development, Product Development &
            Deployment, Partnerships & Sales."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/dumebi"
          /> */}
          <ManagementTeamCard
            cardImage={olayemi}
            heading="Olayemi Odufeso"
            post="Chief Operating Officer"
            description="Olayemi Odufeso is the Chief Operating Officer for
            ChamsAccess Limited. She has over 18 years’ experience in Transaction
            Management, Leadership, People Management, Strategy, Finance Management,
            Performance Management, Administration & Corporate Planning."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/olayemi"
          />
          <ManagementTeamCard
            cardImage={gbenga}
            heading="Gbenga Osinoiki"
            post="VP, Emerging Markets and New Sectors"
            description="Gbenga Osinoiki is the Divisional Head of Emerging Market & New Sectors of
            ChamsAccess Limited where he focuses on new products & solutions and
            delivers them into Emerging and New Sectors in order to grow the Company.
            Prior to his current employment, he was Head of ISP, Govt & Education for
            MainOne Cable Company."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/gbenga"
          />
          <ManagementTeamCard
            cardImage={uche}
            heading="Uche Okorie"
            post="Divisional Head, Business Operations Support"
            description="Focused on improving the Business and Operations Unit of the ChamsAccess brand, Engr. Uche Okorie spent over 2 decades honing his skills in Operations Management, Business Technical Sales, Projects, and Service Delivery. He holds a B.Eng. in Electrical/Electronics Engineering, two Masters degrees in Business Administration (MBA) and Engineering Management (M.Eng.) from Nigeria and Germany respectively.

            He started his postgraduate career with V-Mobile Nigeria (Now Airtel Nigeria) in 2003 as a Network Controller. Uche later joined Multi-links Telkom and was the Manager in charge of Business Solutions and Pre-Sales until his appointment at ChamsAccess as Head, Business Operations and Support, where he grew to be the Divisional Head, Business Operations. 
            
            Engr. Uche is an alumnus of the Lagos Business School (Advanced Management Program), a Certified Associate in Project Management, Certified Agile and Emotional Intelligence practitioner. Registered COREN Engineer, and a member of the Nigeria Society of Engineers (NSE), as well as Institute of Electrical and Electronics Engineers (IEEE)"
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/uche"
          />
          <ManagementTeamCard
            cardImage={dami}
            heading="Damilola Adegbuyi"
            post="VP, Sales"
            description="Damilola Adegbuyi is an accomplished business manager with a strong
            entrepreneurial background and 15 years of experience in Telcos and the
            sales & marketing of technology solutions. His wealth of experience and
            exposure has made him a force to be reckoned with, it extends to strategic
            marketing and being at the leading edge of increasing sales margin."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/dami"
          />
          <ManagementTeamCard
            cardImage={femi}
            heading="Femi Olawale"
            post="Divisional Head, Innovation"
            description="A seasoned software development professional with a proven track record in creating innovative solutions and leading dynamic teams, Femi brings to the table a wealth of experience in computer programming, database management, web design, and development, making him a versatile and accomplished Lead Software Developer.

            With a career spanning almost 2 decades, Femi has built proficiency in a wide range of programming languages, including C#, VB .Net, .Net Core, Java, NodeJS, React, and JavaScript. His expertise extends to database management systems such as MS SQL Server and MySQL, and he excels in web design and development using technologies like ASP .Net, PHP, Angular, and React. Femi is also adept at Search Engine Optimization and possesses advanced skills in data analysis with R, Python, and PowerBI. He obtained his B.Sc. Mathematical Sciences (Computer Science Option) from the University of Agriculture, Abeokuta. His thirst for knowledge and personal development is evident in the series of trainings he has
            undergone after his first degree, in areas such as Software Engineering (NIIT), Emotional Intelligence, IT Service Management, Project Management, Maximum Productivity, etc.
            
            Prior to joining ChamsAccess Ltd as Lead, Software Development, Femi had worked with other reputable organizations such as Universal Embedded Technologies Nigeria Ltd (Software Development), Card Centre Nigeria Ltd (Software Developer), amongst others. Since joining the company, Femi has championed many innovative, best-in-class solutions and reached unprecedented milestones. He is currently the Head, Product Innovations."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/femi"
          />
          <ManagementTeamCard
            cardImage={bose}
            heading="Abosede Akinleye"
            post="Head, People & Culture"
            description="As a seasoned Human Resources Professional with over 15 years of dedicated service in the Technology industry, Abosede Omowumi Akinleye has had the privilege of serving in diverse roles encompassing engineering, procurement, storekeeping, and HR generalist functions. Her career journey has equipped her with a profound understanding of the intricacies of this dynamic sector.

            Currently serving as a Human Resources Manager, her core focus lies in talent management, culture reshaping, and leadership development. She is deeply passionate about fostering a workplace environment where individuals can thrive, organizations can flourish, and people can realize their full potential. This commitment to nurturing both talent and culture is at the heart of her professional ethos.
            
            She is a proud member of the Chartered Institute of Personnel Management (CIPM) and a Chartered Accountant, underscoring her dedication to maintaining the highest standards of professionalism and ethical conduct. Continuous learning is a cornerstone of her approach, and she actively participates in HR workshops and training programs within Nigeria to stay abreast of the latest industry trends and best practices. Abosede is also a member of the Institute of Chartered Accountants of Nigeria."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/abosede"
          />
          <ManagementTeamCard
            cardImage={foke}
            heading="Afoke Richmond"
            post="Head, Marketing & Communications"
            description="Afoke Richmond is a seasoned Marketing Communications professional boasting over nine years of dedicated experience in the field. She holds a Bachelor of Science degree in Mass Communication, graduating with Second Class Honours Upper Division from Delta State University in 2011. Additionally, she obtained a Master of Information Management from Ahmadu Bello University, Zaria.

            Commencing her career journey in 2013, Afoke worked as a call centre agent with Etisalat Nigeria. Demonstrating a penchant for broader horizons, she transitioned to the role of Corporate Communications Officer at Capitalfield Investment Group Ltd in 2014, where her ardor for Brands, Marketing, and Communications flourished.
            
            Throughout her professional trajectory, Afoke has continuously invested in her development, amassing a wealth of expertise and garnering several certifications and trainings. Her dedication and commitment led to her ascension to the position of Group Head, Corporate Communications at Capitalfield Group, where her contributions significantly impacted the company's bottom line. Presently, she spearheads the Brand & Marketing Communications department at ChamsAccess Ltd.
            
            A lifelong learner, Afoke's interests span diverse areas, including Brand Positioning, Integrated Marketing, Digital Marketing, Copywriting, Media Relations, Investor Relations, Stakeholder Management and Precision Marketing, underscoring her commitment to staying abreast of industry trends and advancements."
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/afoke"
          />
          <ManagementTeamCard
            cardImage={irene}
            heading="Irene Ebong"
            post="Accounts & Finance Manager"
            description=" "
            fullProfile="View full profile"
            arrowIcon={mgtArrow}
            mgtPage="/irene"
          />
        </ManagementCardWrapper>
      </MgtTeam>
      <Footer />
      <ScrollToTop smooth height="13" width="15" color="green" />
    </ManagementTeamContainer>
  );
};

export default ManagementTeam;
