import React from "react";
import styled from "styled-components";
import RoleClock from "../../images/RoleClock.png";

const RoleCard = ({ roleTitle, roleDescription, cardImage, workSite, department }) => {
  return (
    <RoleCardContainer>
      <RoleContent>
        <RoleText>{roleTitle}</RoleText>
        <p>{roleDescription}</p>
        <RoleButton2>Department</RoleButton2>
        <RolePeriod>
          <MiniCard>
            <img src={cardImage} alt="role-position" />
            <p>Full-time</p>
          </MiniCard>
          {/* <MiniCard>
            <img src={RoleClock} alt="role-position" />
            <p>{workSite}</p>
          </MiniCard> */}
        </RolePeriod>
      </RoleContent>
      <RoleButton>{department}</RoleButton>
    </RoleCardContainer>
  );
};

export default RoleCard;

const RoleCardContainer = styled.div`
  width: 100%;
  height: auto;
  background: #fcfcfd;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 1s;

  :hover {
    transform: scale(0.95);
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    align-items: space-between;
    justify-content: space-between;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  @media screen and (max-width: 320px) {
    height: 280px;
  }
`;
const RoleContent = styled.div`
  //   background: green;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  //   gap: 14px;
  width: 607px;
  width: 75%;
  height: auto;
  margin-left: 30px;
  margin-top: 17px;
  margin-bottom: 17px;
  margin-right: 17px;

  p {
    // background: red;
    margin-top: 0px;
    width: 606px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #475467;
    margin-top: 10px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;

    p {
      width: 100%;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 70%;

    p {
      width: 500px;
    }
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    margin-left: 10px;
    margin-top: 17px;
    margin-bottom: 17px;
    margin-right: 10px;
    width: 90%;
    height: 90%;
    justify-content: space-between;

    p {
      width: 320px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    height: 90%;
    p {
      width: 260px;
    }
  }
`;
const RoleButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  width: 117px;
  width: 20%;
  height: 24px;
  background: #ebf5f0;
  border-radius: 97px;
  margin-right: 30px;
  cursor: pointer;
  text-wrap: nowrap;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    background: yellow;
  }
  @media screen and (max-width: 600px) {
    display: none;
  }
  @media screen and (max-width: 480px) {
    display: none;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const RoleButton2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2px 20px;
  width: 117px;
  width: 20%;
  height: 24px;
  background: #ebf5f0;
  border-radius: 97px;
  margin-right: 30px;
  cursor: pointer;
  display: none;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    background: yellow;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin-top: 20px;
  }
`;
const RoleText = styled.div`
  width: 80%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #1d2939;
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const RolePeriod = styled.div`
  //   background: yellow;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const MiniCard = styled.div`
  //   background: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-right: 30px;
  height: inherit;
  align-items: center;

  img {
    margin-right: 10px;
    width: 18px;
    height: 18px;
  }

  p {
    width: 52px;
    height: 18px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #475467;
    text-align: center;
    margin-top: 10px;
    height: 18px;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// const RoleCardContainer = styled.div``;
// const RoleCardContainer = styled.div``;
// const RoleCardContainer = styled.div``;
// const RoleCardContainer = styled.div``;
// const RoleCardContainer = styled.div``;
