import React from "react";
import benefit1 from "../assets/Benefit1.png";
import benefit2 from "../assets/Benefit2.png";
import benefit3 from "../assets/Benefit3.png";

const BenefitsData = [
  {
    imgUrl: benefit1,
    text: "Ease in Locating One’s Child",
    subtext: "Quickly find your child's location with real-time tracking.",
  },
  {
    imgUrl: benefit2,
    text: "Enhanced Security of Premises",
    subtext:
      "Protect the school with various devices  keeping your environment secure.",
  },
  {
    imgUrl: benefit3,
    text: "Reduced Administrative Stress",
    subtext:
      "Automate attendance tracking and reporting, freeing up time for teachers and admins",
  },
];

const BenefitCard = ({ item }) => {
  return (
    <div className="benefitCard">
      <img className="benefitImage" src={item.imgUrl} />
      <div className="benefit-overlay">
        <div className="bt">{item.text}</div>
        <div className="st">{item.subtext}</div>
      </div>
    </div>
  );
};

export const Benefits = () => {
  return (
    <div className="benefits">
      <div className="sk-title p--3">Powerful Benefits for Everyone</div>
      <div className="sk-subtitle">
        Feel safe and sound with our school security solutions—giving students,
        parents, and teachers the peace of mind to shine bright and focus on
        what truly matters!
      </div>
      <div className="card-container row gap-5">
        <BenefitCard item={BenefitsData[0]} />
        <BenefitCard item={BenefitsData[1]} />
        <BenefitCard item={BenefitsData[2]} />
      </div>
    </div>
  );
};
