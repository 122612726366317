import styled from "styled-components";
import dotedComplete from "../../images/DotedComplete.png";
import dotedLeft from "../../images/DotedLeft.png";
import missionImage from "../../images/MissionImage.png";
import visionImage from "../../images/VisionImage.png";
import ourStoryBg1 from "../../images/OurStoryBg1.png";

export const AboutUsContainer = styled.div`
  // background: red;
  // height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const GetInTouch = styled.div`
  flex-wrap: wrap;
  width: inherit;
  margin-top: 80px;
  height: 500px;
  background: url(${dotedComplete}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: 400px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: 400px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: 500px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    height: 450px;
    background: url(${dotedLeft}),
      linear-gradient(
        0deg,
        rgba(57, 147, 100, 0.25) 0%,
        rgba(206, 228, 216, 0.25) 77.6%
      );
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100%;
    overflow-x: hidden;
  }
`;

export const Content = styled.div`
  //   background: red;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //   width: 909px;
  width: auto;
  //   height: 184px;
  height: auto;
  overflow-x: hidden;

  p {
    width: 703px;
    height: 56px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 960px) {
    // background: blue;
    justify-content: space-around;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    p {
      width: 85vw;
      height: auto;
      margin: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    justify-content: space-around;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    p {
      width: 85vw;
      height: auto;
      margin: 0px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    justify-content: space-around;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    p {
      width: 90vw;
      height: auto;
      margin: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    justify-content: space-around;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    p {
      width: 90vw;
      height: auto;
      margin: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    justify-content: space-around;
    height: auto;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    p {
      width: 90vw;
      height: auto;
      margin: 0px;
    }
  }
`;
export const Contact = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  width: 94px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin-bottom: 20px;
  transition: background 1s;

  text-wrap: nowrap;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;

  :hover {
    background: #a92f34;
    color: #f6eaeb;
  }

  @media screen and (max-width: 960px) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 0px;
    margin-bottom: 15px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    margin-top: 0px;
    margin-bottom: 20px;
  }
`;
export const Touch = styled.div`
  width: 1009px;
  height: 94px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;

  span {
    color: #08783d;
  }

  @media screen and (max-width: 960px) {
    width: 85vw;
    font-size: 32px;
    margin-bottom: 15px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    font-size: 32px;
    margin-bottom: 15px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 26px;
    line-height: 38px;
    margin-bottom: 0px;
    span {
      line-height: 38px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    font-size: 26px;
    line-height: 36px;
    margin-bottom: 25px;
    span {
      line-height: 24px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 26px;
    line-height: 36px;
    span {
      line-height: 24px;
    }
  }
`;
export const AboutProcess = styled.div`
  // background: tomato;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;

  @media screen and (max-width: 961px) and (max-width: 1200px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    // background: purple;
    // width: 100vw;
    height: auto;
    display: flex;
    // gap: 500px;
    align-self: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const Mission = styled.div`
  width: 1200px;
  // width: 100vw;
  //   height: 300px;
  height: auto;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  margin-top: 100px;
  margin-left: 120px;
  margin-right: 120px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #f6eaeb;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95vw;
    height: auto;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    // background: red;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    // background: red;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: green;
    width: 95%;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    overflow-y: hidden;
    // background: blue;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    width: 70%;
    height: 400px;
    margin-bottom: 60px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 80%;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 94%;
  }
`;
export const Image = styled.div`
  box-sizing: border-box;
  width: 400px;
  height: 300px;
  background: url(${missionImage});
  border-right: 10px solid #a92f34;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: purple;
    background-repeat: no-repeat;
    background-size: 400px 340px;
    width: 65%;
    height: 250px;
    border-right: 0px solid #a92f34;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: purple;
    background-repeat: no-repeat;
    background-size: 300px 300px;
    width: 43%;
    height: 200px;
  }
  @media screen and (max-width: 600px) {
    // background: green;
    // changed
    width: 36%;
    height: 150px;
    background-size: 200px 150px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 220px;
    background-size: 300px 300px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

export const Content1 = styled.div`
  // background: green;
  width: 800px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 84%;
    height: 250px;
    // background: red;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: purple;
    width: 60%;
  }
  @media screen and (max-width: 600px) {
    // changed
    // background: red;
    width: 73%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-width: 480px) {
    // background: purple;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Cover = styled.div`
  // background: tomato;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0px;
  position: absolute;
  width: 582px;
  height: 134px;
  margin: 83px 113px;

  p {
    width: 582px;
    height: 60px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #344054;
  }
  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;

    p {
      width: 80%;
      margin-left: 50px;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 420px;
    p {
      width: 400px;
      margin-left: 10px;
      font-size: 18px;
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 600px) {
    // changed
    // background: pink;
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-top: 10px;

    p {
      // background: tomato;
      width: 95%;
      height: auto;
      font-size: 16px;
      line-heigh: 26px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 300px;
    height: 250px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0px 0px;
    margin-top: 10px;

    p {
      width: 280px;
      height: auto;
      // text-align: center;
      margin-left: 0px;
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Title = styled.div`
  width: 213px;
  height: 44px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #a92f34;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 80%;
    margin-left: 50px;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: grey;
    font-size: 34pxp;
    width: auto;
    margin-left: 10px;
  }
  @media screen and (max-width: 600px) {
    // changed
    // background: green;
    width: 95%;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 95%;
    display: relative;
    font-size: 32px;
    margin-top: 0px;
    top: -2px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Vision = styled.div`
  width: 1200px;
  //   height: 300px;
  height: auto;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  margin-left: 120px;
  margin-right: 120px;
  margin-bottom: 100px;
  display: flex;
  flex-direction: row-reverse;
  background: #f6eaeb;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95vw;
    height: auto;
    margin-top: 30px;
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    display: flex;
    // background: red;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: green;
    width: 95%;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    overflow-y: hidden;
    // background: blue;
    display: flex;
    flex-wrap: wrap;
    align-self: center;
    width: 70%;
    height: 400px;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 80%;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 94%;
  }
`;

export const Image2 = styled.div`
  box-sizing: border-box;
  width: 400px;
  height: 300px;
  background: url(${visionImage});
  border-left: 0px solid #a92f34;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    @media screen and (min-width: 961px) and (max-width: 1200px) {
      // background: purple;
      background-repeat: no-repeat;
      background-size: 400px 340px;
      width: 52%;
      height: 250px;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: purple;
    background-repeat: no-repeat;
    background-size: 300px 300px;
    width: 43%;
    height: 200px;
  }
  @media screen and (max-width: 600px) {
    // background: green;
    // changed
    width: 36%;
    height: 150px;
    background-size: 200px 150px;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 220px;
    background-size: 300px 300px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const History = styled.div`
  width: inherit;
  height: 1200px;
  //   height: auto;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;
    flex-wrap: wrap;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: red;
    flex-direction: column;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const Left = styled.div`
  // background: red;
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    // background: yellow
    display: flex;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: green;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    // background: green;
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const LeftWrapper = styled.div`
  //   background: pink;
  height: auto;
  width: 100%;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    // background: tomato;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: pink;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const LeftTitle = styled.div`
  margin-left: 110px;
  margin-top: 108px;
  margin-bottom: 20px;
  width: 163px;
  height: 44px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 163px;
    height: 44px;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
  @media screen and (max-width: 480px) {
    // background: pink;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 163px;
    height: 44px;
    font-size: 30px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
  @media screen and (max-width: 375px) {
    // background: pink;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 163px;
    height: 44px;
    font-size: 28px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
  @media screen and (max-width: 320px) {
    // background: pink;
    margin-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 163px;
    height: 44px;
    font-size: 24px;
    line-height: 44px;
    letter-spacing: -0.02em;
  }
`;
export const LeftDesc = styled.div`
  margin-left: 110px;
  width: 462px;
  height: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #667085;
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    margin-left: 10px;
    width: 462px;
    height: 20px;
    font-size: 14px;
    line-height: 0px;
  }
  @media screen and (max-width: 480px) {
    margin-left: 10px;
    width: 462px;
    height: 20px;
    font-size: 14px;
    line-height: 0px;
  }
  @media screen and (max-width: 375px) {
    font-size: 14px;
  }
  @media screen and (max-width: 320px) {
    font-size: 14px;
  }
`;
export const LeftHistory = styled.div`
  // background: grey;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 500px;
  height: 880px;
  margin-left: 110px;
  margin-top: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    margin-left: 10px;
    margin-top: 10px;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 100vw;
    height: 800px;
    margin-left: 10px;
    margin-top: 10px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;

// export const AboutContainer = styled.div``;
export const Right = styled.div`
  // background: green;
  width: 55%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${ourStoryBg1});
  background-repeat: no-repeat;
  background-position: center center;
  overflow-x: hidden;

  img {
    width: 661px;
    height: 641px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    right: auto;
    // background: green;

    img {
      width: 80%;
      height: 741px;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: purple;
    width: 100%;
    height: 700px;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    // background: purple;
    width: 100%;
    height: 680px;
    height: auto;
    padding-top: 20px;
    padding-bottom: 20px;

    img {
      width: 85%;
      height: 80%;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    width: 100vw;
    height: 420px;
    background-image: url(${ourStoryBg1});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    img {
      width: 80%;
      height: 80%;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 100vw;
    height: 400px;
    background-image: url(${ourStoryBg1});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    img {
      width: 90%;
      height: 80%;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 100vw;
    height: 350px;
    background-image: url(${ourStoryBg1});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;

    img {
      width: 90%;
      height: 80%;
    }
  }
`;

export const CoreValue = styled.div`
  width: inherit;
  // height: 1250px;
  height: auto;
  background: #fcfcfd;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    // background: green;
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
  }
`;
export const Headers = styled.div`
  width: inherit;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  p {
    width: 460px;
    height: 28px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #344054;
    margin-top: 0px;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: tomato;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin-bottom: 20px;
      width: 460px;
    }
  }

  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: green;
    p {
      width: 400px;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    p {
      width: 350px;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    p {
      width: 300px;
    }
  }
`;
export const HeadTitle = styled.div`
  // background: red;
  margin-top: 50px;
  margin-bottom: 30px;
  width: 598px;
  height: auto;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #101828;
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 0px;
    width: 500px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 32px;
    p {
      width: 4px;
    }
  }
  @media screen and (max-width: 375px) {
    font-size: 30px;
    p {
      width: 4px;
    }
  }
  @media screen and (max-width: 320px) {
    font-size: 30px;
    p {
      width: 100px;
    }
  }
`;
export const Cards = styled.div`
  // background: red;
  // width: 1190px;
  width: 90%;
  // height: 918px;
  height: auto;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  // align-self: center;
  justify-content: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    overflow-x: hidden;
    width: 100vw;
    // background-color: red;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: tomato;
    width: 100%;
    width: 100vw;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    overflow-x: hidden;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    width: 100vw;
    padding-bottom: 20px;
    align-self: flex-start;
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    width: 100vw;
    padding-bottom: 20px;
    align-items: flex-start;
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    width: 100vw;
    height: 2200px;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-bottom: 40px;
    padding-top: 0px;
    align-items: flex-start;
  }
`;

export const CorePartner = styled.div`
  width: inherit;
  // height: 250px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  p {
    align-self: center;
    width: 226px;
    height: 44px;
    font-family: Komet;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1d2939;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    // background: red;
    overflow-x: hidden;
    p {
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    p {
      // background: red;
      height: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    p {
      // background: red;
      height: 0px;
      font-size: 34px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    p {
      // background: red;
      height: 0px;
      font-size: 32px;
    }
  }
`;
export const PartnerLogo = styled.div`
  // background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 38px;
  width: 1320px;
  height: 100px;
  overflow-x: hidden;

  img {
    // width: 200px;
    width: 150px;
    height: 100px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    overflow-x: hidden;
    // background: white;

    img {
      width: 180px;
      height: 80px;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    // background: tomato;
    width: 100vw;
    height: auto;
    flex-wrap: wrap;
    justify-content: center;
    gap: 50px;
    margin: 20px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 20px;

    img {
      // background: white;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: green;
    width: 100vw;
    gap: 0px;

    img {
      width: 40%;
      margin: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: green;
    width: 100vw;
    gap: 0px;

    img {
      width: 38%;
      margin: 10px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: green;
    gap: 0px;

    img {
      width: 43%;
      margin: 10px;
    }
  }
`;
// export const AboutContainer = styled.div``;
// export const AboutContainer = styled.div``;
// export const AboutContainer = styled.div``;

// @media screen and (max-width: 960px) {
// }
// @media screen and (max-width: 768px) {
//   background: red;
// }
// @media screen and (max-width: 600px) {
// }
// @media screen and (max-width: 480px) {
// }
// @media screen and (max-width: 375px) {
// }
// @media screen and (max-width: 320px) {
// }
