import React from "react";
import styled from "styled-components";

const CustomCard = ({ customSourceImage, customTitle, customDescription }) => {
  return (
    <CustomCardContainer>
      <img src={customSourceImage} alt="cardIcon" />
      <J1>{customTitle}</J1>
      <br />
      <J2>{customDescription}</J2>
    </CustomCardContainer>
  );
};

export default CustomCard;

const CustomCardContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 40px;
  width: 360px;
  height: 332px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 10px;
  cursor: pointer;
  transition: box-shadow 0.5s;

  :hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
      rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  img {
    width: 100px;
    height: 100px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 30%;
    margin: 15px;
  }
  @media screen and (max-width: 960px) {
    width: 42%;
    margin: 15px;
  }
  @media screen and (max-width: 768px) {
    width: 42%;
    margin: 15px;
  }
  @media screen and (max-width: 600px) {
    width: 80%;
    margin: 15px;
  }
  @media screen and (max-width: 480px) {
    width: 80%;
    margin: 15px;
    padding: 30px 20px;
  }
  @media screen and (max-width: 375px) {
    width: 80%;
    margin: 15px;
    padding: 30px 20px;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    margin: 15px;
    padding: 30px 20px;
  }
`;
const J1 = styled.div`
  width: 200px;
  height: 64px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #1d2939;
  margin-top: 20px;

  @media screen and (max-width: 960px) {
    width: 80%;
    // background: red;
    height: auto;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const J2 = styled.div`
  width: 100%;
  height: 64px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #1d2939;

  @media screen and (max-width: 960px) {
    width: 100%;
    // background: pink;
    height: auto;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 20px;
    color: #1d2939;
  }
`;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
// const CustomCardContainer = styled.div``;
