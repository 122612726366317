import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// import sampleImage from "../../images/TestImage.png";
import arrowUp from "../../images/arrow-up-right.svg";

const NewsAndInsightCard = ({
  label,
  sampleImage,
  blogTitle,
  description,
  content,
  name,
  learnMore,
  id,
}) => {
  const nav = useNavigate();
  const navigate = (id) => {
    nav(`/blog-detail/${id}`);
    console.log(id);
  };
  // let navigate = useNavigate();
  return (
    <CardWrapper className="col-md-3" onClick={() => navigate(id)}>
      <ImageDiv>
        <img
          // src={`https://blogapi.chamsaccess.com/${sampleImage}`}
          // src={`http://localhost:5050/uploads/${sampleImage}`}
          src={sampleImage}
          alt={name}
        />
      </ImageDiv>
      <ContentDiv>
        <Blog>{label}</Blog>
        <Title>{blogTitle}</Title>
        <Description>{description}</Description>
        {/* <Content><div dangerouslySetInnerHTML={{ __html: content }} /></Content> */}
        <Nav>
          <Text>{learnMore}</Text>
          <ArrowUp>
            <img
              src={arrowUp}
              alt="up"
              style={{ height: "15px", width: "15px" }}
            />
          </ArrowUp>
          {/* </NavLink> */}
        </Nav>
      </ContentDiv>
    </CardWrapper>
  );
};

export default NewsAndInsightCard;

const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px 24px;
  width: 336px;
  height: 477px;
  background: #fcfcfd;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  transition: transform 1s;
  cursor: pointer;
  margin: 20px;
  // background: red;

  :hover {
    transform: scale(1.02);
  }

  @media screen and (max-width: 960px) {
    width: 320px;
    height: 537px;
  }
  @media screen and (max-width: 768px) {
    width: 300px;
    height: 430px;
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
    width: 300px;
    height: 430px;
    margin: 10px;
  }
  @media screen and (max-width: 480px) {
    width: 300px;
    height: 430px;
    margin: 15px;
  }
  @media screen and (max-width: 375px) {
    width: 270px;
    height: 430px;
    margin: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 230px;
    height: 430px;
    margin: 15px;
  }
`;
const ImageDiv = styled.div`
  // background: green;
  width: 100%;
  height: 45%;

  img {
    width: 100%;
    height: 100%;
  border-radius: 10px;
  }
`;
const ContentDiv = styled.div`
  // background: purple;
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;
const Blog = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 20px;
  margin-top: 10px;
  background: #ebf5f0;
  border-radius: 97px;
  width: auto;
  height: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #08783d;
`;
const Title = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;
`;
const Description = styled.div`
  // background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 50px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
  overflow: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Content = styled.div`
  background-color: red;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #667085;
  overflow-y: scroll;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    width: 100%;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
const Nav = styled.div`
  // background: red;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 30px;
  cursor: pointer;
`;
const Text = styled.div`
  width: 73px;
  height: 20px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #1d2939;

  :hover {
    color: green;
  }
`;
const ArrowUp = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
`;
// const CardWrapper = styled.div``;
// const CardWrapper = styled.div``;
