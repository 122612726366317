  import React, { useState, useEffect } from "react";
  import Header from "../landingPage/header/Header";
  import Footer from "../landingPage/footer/Footer";
  import ScrollToTop from "react-scroll-to-top";
  import parse from "html-react-parser";
  import axios from "axios";
  import { Link as NavLink, useParams } from "react-router-dom";
  import {
    NewsCotainer,
    BlogCardWrapper,
    BlogTitles,
    TextDiv,
    Ready,
    RequestButton,
    BlogContentWrapper,
    Label,
    Title,
    Description,
    Content,
    BlogImage,
  } from "./NewDetailStyle";

  const NewsAndInsight = () => {
    const { blogid } = useParams();

    const [blogData, setBlogData] = useState({
      label: "",
      title: "",
      description: "",
      content: ""
    });
    // console.log(blogid);

    const url = `https://blogapi.chamsaccess.com/api/v1/visitors/blogs/${blogid}`;
    // const url = `http://localhost:5050/api/v1/visitors/blogs/${blogid}`;
    // function to get all blogs from the database
    const fetchBlogs = async () => {
      const response = await axios.get(url);
      setBlogData(response.data.data);
      
    };

    useEffect(() => {
      window.scroll({
        top: 0,
        left: 100,
        behavior: "smooth",
      });
      fetchBlogs();
    }, []);

    return (
      <NewsCotainer>
        <Header />
        <BlogCardWrapper>
          <BlogContentWrapper>
            <BlogTitles>
            <Label>{blogData.label}</Label>
            <Title>{blogData.title}</Title>
            <Description>{blogData.description}</Description>
            <BlogImage src={`https://blogapi.chamsaccess.com/uploads/${blogData.captionImage}`}>
            </BlogImage>
            </BlogTitles>
            <Content> { parse(blogData.content) }</Content>
          </BlogContentWrapper>
        </BlogCardWrapper>
        {/* TODO : Add other blog suggestions carousel... */}
        <TextDiv>
          <Ready>Download our annual general report</Ready>
          <NavLink to="" style={{ textDecoration: "none" }}>
            <RequestButton>Download Report</RequestButton>
          </NavLink>
        </TextDiv>
        <Footer />
        <ScrollToTop smooth height="13" width="15" color="green" />
      </NewsCotainer>
    );
  };

  export default NewsAndInsight;