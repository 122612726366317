import React, { useEffect, useState } from "react";
import SK from "../assets/SK-Logo.svg";
import WhiteSands from "../assets/whitesands.svg";
import Corona from "../assets/corona.svg";
import Hero1 from "../assets/Hero-1.png";
import Hero2 from "../assets/Hero-2.png";
import Hero3 from "../assets/Hero-3.png";
import { Link } from "react-router-dom";

export const Hero = () => {
  const [imgData, setImgData] = useState([
    { id: 1, img: Hero1 },
    { id: 2, img: Hero2 },
    { id: 3, img: Hero3 },
  ]);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setImgData((prevData) => {
  //       // Rotate the array: move the first element to the end...
  //       return [prevData[1], prevData[2], prevData[0]];
  //     });
  //   }, 2000); 

  //   return () => clearInterval(interval);
  // }, []);

  return (
    <div className="hero">
      <section className="hero-left">
        <img className="sk-logo" src={SK} alt="logo" />
        <div className="hero-text">
          Your Child’s <span className="sk-color-2">Security</span>
          <br /> Our <span className="sk-color-1">Priority</span>
        </div>
        <div className="hero-subtext">
          We have designed the right solution that ensures efficient management
          and security of child/ward drop-off and pick up at schools.
        </div>
        <Link to={"contact"}>
          <div className="contact-button">
            Request demo
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 4.55581L0 8.45292L3.40697e-07 0.658691L6.75 4.55581Z"
                fill="white"
              />
            </svg>
          </div>
        </Link>
        <div className="left-bottom">
          <div>Join 50+ schools protecting...</div>
          <div className="hero-partners">
            <img src={WhiteSands} />
            <img src={Corona} />
          </div>
        </div>
      </section>
      <section className="hero-right">
        <div className="hero-imgs">
          <img src={imgData[2].img} className="hero-3" />
          <img src={imgData[1].img} className="hero-2" />
          <img src={imgData[0].img} className="hero-1" />
        </div>
      </section>
    </div>
  );
};
