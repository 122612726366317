import styled from "styled-components";
import surveilanceBg from "../../images/SurveilanceBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const SurveilanceContainer = styled.div`
  //   background: red;
  
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 60px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SurveilanceMaster = styled.div`
  background: red;
  width: 100vw;
  height: 700px;
  background: url(${surveilanceBg});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 80px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    height: 700px;
    overflow-x: hidden;
    // background: red;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 60px;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 60px;
    padding-bottom: 60px;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 50px;
    // background: red;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;
    // background: red;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 380px;
    overflow-x: hidden;
    background: url(${surveilanceBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;
    // background: red;
  }
`;
export const S1 = styled.div`
  width: 600px;
  height: 126px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 112px;
  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background-color: red;
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    // background-color: red;
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 375px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 320px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
`;
export const S2 = styled.div`
  width: 800px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ebf5f0;
  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
`;
export const SurveillanceButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 187px;
  height: 44px;
  background: #08783d;
  border-radius: 8px;
  cursor: pointer;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ebf5f0;

  :hover {
    width: 220px;
  }
`;
export const WriteUp = styled.div`
  color: #ffffff;
  width: 100vw;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    color: #ffffff;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const WriteUpWrapper = styled.div`
  // background: red;
  // width: 1138px;
  width: 85%;
  height: auto;
  height: 264px;
  display: flex;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 90%;
    height: auto;

    p {
      width: 95%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #475467;
    }
  }
  @media screen and (min-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
    padding-top: 0px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 320px) {
  }
`;
export const WriteLeft = styled.div`
  // background: blue;
  width: 50%;
  display: flex;
  justify-content: flex-start;

  p {
    width: 90%;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #101828;
    margin-top: 0px;
    margin-left: 20px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 95%;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      color: #101828;
      margin-top: 0px;
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      width: 85%;
      height: auto;
      text-align: center;
      margin: 0px;
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: green;
    padding-top: 30px;
    padding-bottom: 10px;

    p {
      width: 85%;
      height: auto;
      // text-align: center;
      text-align: left;
      margin: 0px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const WriteRight = styled.div`
  // background: yellow;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;

  p {
    width: 90%;
    height: 140px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
    margin-top: 0px;
    margin-left: 20px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;

    p {
      width: 90%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-top: 0px;
      margin-left: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: pink;
    display: flex;
    justify-content: center;
    align-items: items;

    p {
      width: 85vw;
      height: auto;
      text-align: center;
      margin: 0px;
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: pink;
    padding-top: 10px;
    padding-bottom: 10px;

    p {
      width: 85vw;
      height: auto;
      text-align: left;
      margin: 0px;
      font-size: 18px;
      line-height: 26px;
    }
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VideoSurveillance = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const DivisionWrapper = styled.div`
  // background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // width: 85%;
  height: 100%;

  @media screen and (min-width: 961px) {
    // background: black;
    width: 90vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: flex-start;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VideoLeft = styled.div`
  // background: yellow;
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 550px;
    height: 570px;
  }

  @media screen and (min-width: 961px) {
    // background: red;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 65%;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 60px;
    padding-bottom: 60px;

    img {
      width: 80%;
      height: 570px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 80%;
      height: 560px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 430px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 350px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 300px;
    }
  }
`;
export const VideoRight = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VideoInfo = styled.div`
  // background: red;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 539px;
  height: auto;
  margin-left: 80px;

  @media screen and (min-width: 961px) {
    // background: red;
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 20px;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 80px;

    p {
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 80%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
      font-size: 16px;
    }
  }
`;
export const P1 = styled.div`
  width: 500px;
  height: 76px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 26px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 85%;
    height: auto;
    text-align: left;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    font-size: 26px;
  }
`;
export const P2 = styled.div`
  width: 557px;
  height: 112px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  margin-bottom: 26px;

  @media screen and (min-width: 961px) {
    // background: blue;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    width: 80%;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const VideoOptions = styled.div`
  // background: yellow;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Opt = styled.div`
  //   background: red;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
    // margin-left: 16px;
  }

  p {
    height: auto;
  }

  @media screen and (max-width: 960px) {
    // img {
    //   width: 32px;
    //   height: 32px;
    //   margin-right: 16px;
    // }
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      // margin-left: 16px;
    }
  }
  @media screen and (max-width: 600px) {
    height: auto;
    width: 95vw;
    height: 50px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      // margin-left: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    height: auto;
    width: 100vw;
    height: 50px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      margin-left: 16px;
    }

    p {
      // background: red;
      height: auto;
      line-height: 23px;
    }
  }
  @media screen and (max-width: 375px) {
    height: auto;
    width: 95vw;
    height: 60px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      margin-left: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    height: auto;
    width: 95vw;
    height: 60px;
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
      margin-left: 16px;
    }

    p {
      height: auto;
    }
  }
`;
export const Intrusion = styled.div`
  //   background: pink;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IntrusionDivider = styled.div`
  width: 90%;
  height: inherit;
  //   background: red;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    background: red;
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IntrusionLeft = styled.div`
  // background: yellow;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
    // }background-color: red;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IntrusionDescription = styled.div`
  //   background: green;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: auto;
    // background: white;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: auto;
    // background: white;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: auto;
    // background: white;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: auto;
    // background: white;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: auto;
    // background: white;
  }
`;
export const I1 = styled.div`
  width: 500px;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const I2 = styled.div`
  width: 500px;
  height: 213px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #475467;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    margin-left: 0px;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
  }
`;

export const IntrusionRight = styled.div`
  // background: blue;
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 80%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 75vw;
      height: 660px;
    }
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
    img {
      width: 560px;
      height: 552px;
    }
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
    img {
      width: 80vw;
      height: 452px;
    }
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
    img {
      width: 80vw;
      height: 352px;
    }
  }
  @media screen and (max-width: 375px) {
    margin-bottom: 20px;
    img {
      width: 80vw;
      height: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    margin-bottom: 20px;
    img {
      width: 80vw;
      height: 260px;
    }
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  overflow-x: hidden;
  overflow-y: hidden;

  p {
    width: 650px;
    height: 43px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 320px;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
`;
export const Ready = styled.div`
  //   background: red;
  width: 900px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  margin-bottom: 20px;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 34px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    // margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    // margin-top: 40px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 320px) {
    margin-top: 10px;
  }
`;

// export const SurveilanceContainer = styled.div``;
// export const SurveilanceContainer = styled.div``;
