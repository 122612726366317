import React, { useState, useEffect } from "react";
import {
  CareerContainer,
  Left,
  Right,
  FormContainer,
  FormContent,
  Heading,
  FormWrapper,
  Form,
  Name,
  Contact1,
  Contact2,
  Message,
  Button,
  DownloadIcon,
  RealInput,
  InputRow,
  UploadCV,
  UploadCV2,
  Err,
} from "./CareersFormStyle.js";
import careerImage from "../../images/CareerImage.png";
import cancelIcon from "../../images/CancelIcon.svg";
import uploadIcon from "../../images/uploadIcon.svg";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import axios from "axios";

const Career = () => {
  const [file1, setFile1] = useState();
  const [file2, setFile2] = useState();
  const [loading, setLoading] = useState(false);

  // handle any change on input field
  const handleChange1 = (e) => {
    e.preventDefault();
    // console.log(e.target.files[0]);
    setFile1(e.target.files[0]);
  };

  const handleChange2 = (e) => {
    e.preventDefault();
    // console.log(e.target.files[0]);
    setFile2(e.target.files[0]);
  };

  const dataSchema = yup.object().shape({
    firstName: yup.string().required("Firstname is required."),
    lastName: yup.string().required("Lastname is required."),
    location: yup.string().required("Location name is required."),
    email: yup
      .string()
      .email("Invalid email")
      .required("Email field is required."),
    description: yup.string().required("Message field is required."),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(dataSchema),
  });

  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (value) => {
    // console.log(value);

    const { firstName, lastName, location, email, description } = value;

    // console.log(file1, file2);
    // 👇 Create new FormData object and append files
    const data = new FormData();
    data.append("firstname", firstName);
    data.append("lastname", lastName);
    data.append("location", location);
    data.append("email", email);
    data.append("description", description);
    data.append("uploadCV", file1);
    data.append("uploadCoverLetter", file2);

    if (!file1 || !file2) {
      return;
    }

    try {
      setLoading(true);
      // const url = "http://localhost:2000/api/users";
      const url = "https://adminapi.chamsaccess.com/api/users";
      // const url = `${process.env.REACT_APP_BASE_URL}/api/users`;

      const response = await axios({
        url: url,
        method: "post",
        data: data,
      });
      if (response.status === 200) {
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Message sent",
          text: "Form submitted successfully.",
          showConfirmButton: true,
        }).then(() => {
          console.log(response.status);
          reset();
          navigate("/");
        });
      } else {
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Oops...",
          text: "Message failed to submit",
          showConfirmButton: true,
        });
        console.log(response.status);
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      console.error(error.message);
    }
  });

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 100,
      behavior: "smooth",
    });
  }, []);
  return (
    <CareerContainer>
      <Left>
        <p>Your dream job is waiting here</p>
        <img src={careerImage} alt="thelma" />
      </Left>
      <Right>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            justifySelf: "flex-end",
            alignSelf: "right",
            // backgroundColor: "red",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <img
            src={cancelIcon}
            alt="cancel"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "20px",
              marginTop: "40px",
              marginRight: "40px",
              marginBottom: "80px",
            }}
          />
        </Link>
        <FormContainer>
          <FormContent>
            <Heading>Your dream job</Heading>
            <p>
              Thanks for your interest in our services. Let us know how we can
              reach out to discuss your project in more detail.
            </p>
          </FormContent>
          <FormWrapper>
            <Form>
              <Name>
                <label>
                  First name
                  <Err>{errors?.firstName?.message}</Err>
                  <input
                    type="text"
                    id="firstName"
                    placeholder="Firstname"
                    {...register("firstName")}
                  />
                </label>
                <label>
                  Last name
                  <Err>{errors?.lastName?.message}</Err>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Lastname"
                    {...register("lastName")}
                  />
                </label>
              </Name>
              <Contact1>
                <label>
                  Current location
                  <Err>{errors?.location?.message}</Err>
                  <input
                    type="text"
                    id="location"
                    placeholder="location"
                    {...register("location")}
                  />
                </label>
                <label>
                  Email address
                  <Err>{errors?.email?.message}</Err>
                  <input
                    type="email"
                    id="email"
                    placeholder="email"
                    {...register("email")}
                  />
                </label>
              </Contact1>
              <Contact2>
                <UploadCV>
                  Upload CV
                  <InputRow>
                    <RealInput
                      type="file"
                      accept=".docx, .pdf, .doc"
                      placeholder="Choose file"
                      onChange={handleChange1}
                      style={{
                        display: "none",
                      }}
                    />
                    <span
                      style={{
                        color: "green",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      {/* {console.log(file1 && file1.name)} */}
                      {file1 && file1.name}
                    </span>
                    <DownloadIcon src={uploadIcon} alt="Download" />
                  </InputRow>
                </UploadCV>
                <UploadCV2>
                  Upload cover letter
                  <InputRow>
                    <RealInput
                      type="file"
                      accept=".docx, .pdf, .doc"
                      placeholder="Choose file"
                      onChange={handleChange2}
                      style={{
                        display: "none",
                      }}
                    />
                    <span
                      style={{
                        color: "green",
                        fontSize: "12px",
                        marginLeft: "10px",
                      }}
                    >
                      {/* {console.log(file2 && file2.name)} */}
                      {file2 && file2.name}
                    </span>
                    <DownloadIcon src={uploadIcon} alt="Download" />
                  </InputRow>
                </UploadCV2>
              </Contact2>
              <Message>
                <label>
                  Dream job description
                  <Err>{errors?.description?.message}</Err>
                  <textarea
                    type="text"
                    placeholder="Enter a description..."
                    id="description"
                    {...register("description")}
                  />
                </label>
              </Message>
              <Button
                disabled={loading}
                type="submit"
                onClick={() => {
                  console.log("submitted");
                  onSubmit();
                }}
              >
                {loading ? "Loading......" : "Submit"}
              </Button>
            </Form>
          </FormWrapper>
        </FormContainer>
      </Right>
    </CareerContainer>
  );
};

export default Career;
