import styled from "styled-components";
import assetBg from "../../images/AssetBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const AssetManagementContainer = styled.div`
  //   background: red;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  // background: red;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
  }
`;
// export const AssetManagementContainer = styled.div``;
export const AssetMaster = styled.div`
  //   background: red;
  width: 100vw;
  height: 700px;
  background: url(${assetBg});
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 80px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // height: 700px;
    overflow-x: hidden;
    // background: red;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 60px;
    padding-bottom: 60px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 60px;
    // background: red;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // padding-top: 60px;
    padding-bottom: 60px;
    // background: red;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 50px;
    // background: red;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;
    // background: red;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 380px;
    overflow-x: hidden;
    background: url(${assetBg});
    background-repeat: no-repeat;
    background-size: 100vw 450px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 50px;
    // background: red;
  }
`;
export const S1 = styled.div`
  // background: red;
  width: 700px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 112px;
  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 40px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background-color: red;
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    // background-color: red;
    width: 80vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 40px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 375px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 320px) {
    // background-color: red;
    width: 85vw;
    height: auto;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #ebf5f0;
    margin-top: 10px;
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 10px;
  }
`;
export const S2 = styled.div`
  width: 800px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #ebf5f0;
  margin-bottom: 20px;

  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    width: 80vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #ebf5f0;
    margin-bottom: 20px;
  }
`;
export const AssetButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 187px;
  height: 44px;
  background: #ebf5f0;
  border-radius: 8px;
  cursor: pointer;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #08783d;

  :hover {
    width: 220px;
  }
`;
// export const AssetManagementContainer = styled.div``;
export const PhoneTrackComponent = styled.div`
  background-color: #ffffff;
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PhoneTrackDivider = styled.div`
  // background: red;
  width: 100%;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PhoneLeft = styled.div`
  // background: tomato;
  width: 55%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 684px;
    height: 546px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 606px;
      object-fit: contain;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 546px;
    }
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 446px;
    }
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 300px;
    }
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;

    img {
      width: 85%;
      height: 220px;
    }
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PhoneRight = styled.div`
  //   background: blue;
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C1 = styled.div`
  width: 450px;
  height: 72px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C2 = styled.div`
  width: 508px;
  height: 156px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #667085;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TeamPageComponent = styled.div`
background: linear-gradient(0deg, rgba(57, 147, 100, 0.25) 0%, rgba(206, 228, 216, 0.25) 77.6%);
  width: 100vw;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    // height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex:
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TeamPageDivider = styled.div`
  //   background: red;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    // height: auto;
    // background: red;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    display: flex;
    // flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    // background: red;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TeamPageLeft = styled.div`
  //   background: tomato;
  width: 55%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: tomato;
    width: 50%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TeamPageRight = styled.div`
  //   background: blue;
  width: 50%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: pink;
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: pink;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 100vw;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const T1 = styled.div`
  width: 35%;
  height: 72px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 35%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 35%;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 45%;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 65%;
    height: auto;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const T2 = styled.div`
  width: 600px;
  height: 140px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #475467;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 85vw;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    // background: red;
  }
  @media screen and (max-width: 480px) {
    // background: red;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// export const AssetManagementContainer = styled.div``;
export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
    margin-bottom: 20px;

    color: var(--Grey-600, #475467);
  }

  overflow-x: hidden;
  overflow-y: hidden;

  p {
    width: 650px;
    height: 43px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 320px;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
`;

// export const Ready = styled.div`
export const FeaturesContainer = styled.div`
  //   background-color: red;
  width: 100vw;
  height: 1000px;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: red;
  overflow-x: hidden;
  margin-top: 40px;

  p {
    width: 891px;
    height: 40px;
    font-family: Komet;;
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    text-align: center;
    color: #101828;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      width: 891px;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
    overflow-y: hidden;

    p {
      width: 90%;
      height: auto;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    width: 100vw;
    overflow-x: hidden;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    p {
      width: 90%;
      height: 40px;
      font-family: Komet;;
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      color: #101828;
      margin: 0px;
    }
  }
`;
export const FeaturesWrapper = styled.div`
  width: 85%;
  height: 749px;
  //   background: tomato;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  // background: blue;
  gap: 20px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
    height: 749px;
    //   background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow-y: hidden;
  }
  @media screen and (max-width: 960px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 600px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 480px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 375px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  @media screen and (max-width: 320px) {
    // background: blue;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;
// export const Ready = styled.div`

export const Ready = styled.div`
  //   background: red;
  width: 900px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  margin-bottom: 20px;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 34px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    // margin: 30px 0px 40px;
  }
`;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
// export const AssetManagementContainer = styled.div``;
