import styled from "styled-components";

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 350px;
  height: 424px;
  background: #ffffff;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08),
    0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  border-radius: 14px;
  cursor: pointer;
  // margin-left: 30px;
  // margin-right: 30px;
  // margin-top: 60px;
  margin: 20px;
  transition: transform 1s;

  img {
    border-top-right-radius: 14px;
    border-top-left-radius: 14px;
  }

  &:hover {
    transform: scale(1.08);
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 30%;
    margin: 15px;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: green;
    width: 42%;
    margin: 15px;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    // background: green;
    width: 45%;
    margin: 15px;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 480px) {
    // background: green;
    width: 80%;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 375px) {
    // background: green;
    width: 90%;

    img {
      width: 100%;
    }
  }
  @media screen and (max-width: 320px) {
    // background: green;
    width: 90%;
    height: 320px;

    img {
      width: 100%;
    }
  }
`;
export const CardContent = styled.div`
  height: 174px;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom-right-radius: 14px;
  border-bottom-left-radius: 14px;

  p {
    width: 300px;
    height: 70px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #475467;
    margin-left: 25px;
    margin-bottom: 30px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
    height: 100%;
    P {
      width: 85%;
      height: auto;
      line-height: 24px;
      margin-left: 25px;
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    height: 100%;
    P {
      width: 85%;
      height: auto;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 600px) {
    P {
      width: 85%;
      height: auto;
      line-height: 24px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100%;
    height: 100%;
  }
  @media screen and (max-width: 375px) {
    width: 100%;
    height: 100%;

    p {
      width: 250px;
      height: auto;
      // margin-right: 25px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100%;
    height: auto;

    p {
      width: 230px;
    }
  }
`;
export const Top = styled.div`
  width: 200px;
  height: 30px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #101828;
  margin-left: 25px;
  margin-top: 34px;
  margin-bottom: 10px;

  @media screen and (max-width: 900px) {
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
