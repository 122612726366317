import React from "react";
import HappyCustomers from "../assets/happyCustomers.svg";
import GrowingNetwork from "../assets/growingNetwork.svg";
import WideSpread from "../assets/wideSpreadSuccess.svg";
import TestTrusted from "../assets/testedTrusted.svg";
import ProtectingMillions from "../assets/protectingMillions.svg";
import StrongCounting from "../assets/strongCounting.svg";
import { Item } from "../../../landingPage/securityAccess/SecurityAccessStyle";

export const Expertise = () => {
  const expertiseData = [
    {
      imageUrl: HappyCustomers,
      text: "Happy Customers",
      subtext: "Thousands of satisfied users.",
    },
    {
      imageUrl: GrowingNetwork,
      text: "Growing Network",
      subtext: "Expanding customer base.",
    },
    {
      imageUrl: WideSpread,
      text: "Wide Spread  Success",
      subtext: "Effective across various applications.",
    },
    {
      imageUrl: TestTrusted,
      text: "Tested and Trusted",
      subtext: "Thousands of satisfied users.",
    },
    {
      imageUrl: ProtectingMillions,
      text: "Protecting Millions ",
      subtext: "Our systems safeguard individuals",
    },
    {
      imageUrl: StrongCounting,
      text: "Strong and Counting:",
      subtext: "Our systems safeguard individuals",
    },
  ];
  return (
    <div className="expertise">
      <div className="sk-title">Our Expertise</div>
      <div className="sk-subtitle">
        From safeguarding spaces to calming minds, our security solutions have
        made waves around the globe.
      </div>
      <div className="expertise-grid row">
        {expertiseData.map((item) => {
          return <div className="expertise-item col-md-4">
            <img src={item.imageUrl} />
            <div className="ex-item-box">
                <div className="ex-item-text">{item.text}</div>
                <div className="ex-item-subtext">{item.subtext}</div>
            </div>
          </div>;
        })}
      </div>
    </div>
  );
};
