import styled from "styled-components";
import trusteeImage from "../../images/TrusteesBg.svg";

export const AccessContainer = styled.div`
  //   background: red;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Access = styled.div`
  background: #ffffff;
  width: 100vw;
  height: 700px;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: red;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessLeft = styled.div`
  //   background: red;
  width: 40%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
    overflow-x: hidden;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessRight = styled.div`
  position: relative;
  //   background: blue;
  width: 60%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  top: 60px;

  img {
    width: 940px;
    height: 817.08px;
    object-fit: contain;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: green;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    overflow-x: hidden;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 80vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    img {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    img {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 85vw;
      height: auto;
    }
  }
`;
export const A1 = styled.div`
  width: 457px;
  height: 120px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  letter-spacing: -0.02em;
  color: #101828;
  margin-bottom: 20px;
  margin-left: 120px;

  span {
    color: #08783d;
  }

  @media screen and (max-width: 960px) {
    width: 75vw;
    height: auto;
    // background: blue;
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    // background: blue;
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    // background: blue;
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    // background: blue;
    margin-left: 0px;
    text-align: center;
    font-size: 40px;
    line-height: 50px;
  }
`;
export const A2 = styled.div`
  width: 438px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #101828;
  margin-bottom: 20px;
  margin-left: 120px;

  @media screen and (max-width: 960px) {
    width: 75vw;
    // background: blue;
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: blue;
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 187px;
  height: 44px;
  background: #08783d;
  border-radius: 8px;
  margin-left: 120px;
  cursor: pointer;
  transition: width 0.5s;

  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  :hover {
    width: 200px;
  }

  @media screen and (max-width: 960px) {
    margin-left: 0px;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessControlMaster = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );

  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AccessControlDivider = styled.div`
  //   background: pink;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: 100%;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const DividerLeft = styled.div`
  //   background: yellow;
  width: 50%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  img {
    width: 600px;
    height: 490.95px;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 500px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 60px;
    padding-bottom: 60px;

    img {
      width: 80%;
      height: 500px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 80%;
      height: 4600px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 380px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    // background: black;
    padding-top: 30px;
    padding-bottom: 30px;

    img {
      width: 85%;
      height: 280px;
    }
  }
`;
export const DividerRight = styled.div`
  //   background: blue;
  width: 45%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: red;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const C1 = styled.div`
  width: 500px;
  height: 37px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 26px;

  @media screen and (max-width: 960px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 85%;
    height: auto;
    text-align: left;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    font-size: 26px;
  }
`;
export const C2 = styled.div`
  width: 500px;
  height: 164px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  margin-bottom: 26px;

  @media screen and (max-width: 960px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 85%;
    height: auto;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ControlOption = styled.div`
  //   background: tomato;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const OptList = styled.div`
  //   background: yellow;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  img {
    width: 32px;
    height: 32px;
    margin-right: 16px;
  }

  p {
    width: 368px;
    height: 28px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
    margin-top: 0px;
  }

  @media screen and (max-width: 960px) {
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    p {
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }
    p {
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 600px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      // background: red;
      height: auto;
      line-height: 23px;
    }
  }
  @media screen and (max-width: 375px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
`;
export const TimeSolution = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TimeSolutionDivider = styled.div`
  // background: pink;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 85%;
  height: auto;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TimeLeft = styled.div`
  //   background: green;
  width: 43%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    // background-color: red;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    padding-top: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TimeRight = styled.div`
  //   background: tomato;
  width: 55%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  img {
    width: 638px;
    height: 510.06px;
    object-fit: contain;
  }

  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: red;
    padding-top: 60px;
    padding-bottom: 60px;

    img {
      width: 80vw;
      height: auto;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 560px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    img {
      width: 80vw;
      height: 452px;
    }
  }
  @media screen and (max-width: 480px) {
    padding-top: 40px;
    padding-bottom: 40px;
    img {
      width: 80vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    padding-top: 10px;
    padding-bottom: 10px;
    img {
      width: 80vw;
      height: 320px;
    }
  }
  @media screen and (max-width: 320px) {
    img {
      width: 80vw;
      height: 260px;
    }
  }
`;
export const T1 = styled.div`
  width: 576px;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin-bottom: 26px;

  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background-color: white;
    text-align: center;
  }
  @media screen and (max-width: 768px) {
    text-align: left;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const T2 = styled.div`
  width: 500px;
  height: 112px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #475467;
  margin-bottom: 26px;

  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background-color: white;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TimeOption = styled.div`
  //   background: brown;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (max-width: 960px) {
    // background: yellow;
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    height: auto;
  }
  @media screen and (max-width: 600px) {
    height: auto;
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const TimeList = styled.div`
  width: 100%;
  //   background: yellow;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;

  img {
    margin-right: 16px;
    width: 32px;
    height: 32px;
  }
  p {
    width: 491px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
  }

  @media screen and (max-width: 960px) {
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      // background: red;
      width: 85vw;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
    }
  }
  @media screen and (max-width: 600px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      // background: red;
      height: auto;
      line-height: 23px;
    }
  }
  @media screen and (max-width: 375px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    height: auto;

    img {
      width: 32px;
      height: 32px;
      margin-right: 16px;
    }

    p {
      height: auto;
    }
  }
`;

export const TextDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: 300px;
  //   height: auto;
  background: url(${trusteeImage}),
    linear-gradient(
      0deg,
      rgba(57, 147, 100, 0.25) 0%,
      rgba(206, 228, 216, 0.25) 77.6%
    );

  p {
    width: 800px;
    height: 55px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #475467;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  overflow-x: hidden;
  overflow-y: hidden;

  p {
    width: 650px;
    height: 43px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 960px) {
    overflow-x: hidden;
    width: 100vw;
    height: 320px;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 768px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 600px) {
    overflow-x: hidden;
    width: 100vw;

    p {
      width: 90vw;
      height: auto;
      margin-bottom: 30px;
    }
  }
  @media screen and (max-width: 480px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 375px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 350px;
    p {
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 320px) {
    overflow-x: hidden;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 400px;
    p {
      margin-top: 0px;
    }
  }
`;
export const Ready = styled.div`
  //   background: red;
  width: 900px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #08783d;
  margin-bottom: 20px;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 768px) {
    width: 95vw;
    font-size: 40px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    font-size: 34px;
    line-height: 50px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    font-size: 35px;
    line-height: 45px;
    // margin: 30px 0px 40px;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    font-size: 36px;
    line-height: 40px;
    // margin: 30px 0px 40px;
  }
`;
export const RequestButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 30px;
  background: #08783d;
  border-radius: 10px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    // margin-top: 40px;
  }
  @media screen and (max-width: 480px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 375px) {
    margin-top: 10px;
  }
  @media screen and (max-width: 320px) {
    margin-top: 10px;
  }
`;
// export const AccessContainer = styled.div``;
// export const AccessContainer = styled.div``;
// export const AccessContainer = styled.div``;
// export const AccessContainer = styled.div``;
