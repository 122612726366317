import React, { useState } from "react";
import testimonialGirl from '../assets/Testimonial_GIrl.png';

export const Testimonials = () => {  
  const [testimonial, setTestimonial] = useState({
    index: 0,
    text: "Browse Setup your account in a few simple steps, browse available property listings. We’ve provided free information about the monthly cash flow it will generate for you from the rent collected.Listings",
    profile: {
      name: "Olivia Rhye",
      email: "olivia@untitledui.com",
      imageUrl: "#",
    },
  });

  const testimonialsData = [
    {
      text: "Browse Setup your account in a few simple steps, browse available property listings. We’ve provided free information about the monthly cash flow it will generate for you from the rent collected.",
      profile: {
        name: "Olivia Rhye",
        email: "olivia@untitledui.com",
        imageUrl: "#",
      },
    },
    {
      text: "Browse Setup your account in a few simple steps, browse available property listings. We’ve provided free information about the monthly cash flow it will generate for you from the rent collected.",
      profile: {
        name: "Olivia Rhye",
        email: "olivia@untitledui.com",
        imageUrl: "#",
      },
    },
    {
      text: "Browse Setup your account in a few simple steps, browse available property listings. We’ve provided free information about the monthly cash flow it will generate for you from the rent collected.",
      profile: {
        name: "Olivia Rhye",
        email: "olivia@untitledui.com",
        imageUrl: "#",
      },
    },
  ];

  const next = () => {
    if (testimonial.index < testimonialsData.length - 1) {
        setTestimonial((prevTestimonial) => {
            return { ...prevTestimonial, index: prevTestimonial.index + 1 };
            });
  } }

  const prev = () => {
    if (testimonial.index > 0) {
        setTestimonial((prevTestimonial) => {
            return { ...prevTestimonial, index: prevTestimonial.index - 1 };
            });
  }}

  return (
    <div className="testimonials">
      <div className="t-img-container">
        <img className="t-img" src={testimonialGirl} />
      </div>
      <div className="testimonials-content">
        <div className="sk-title">What Our Customers Say</div>
        <div className="testimonial-card">
            <div className="testimonial-text">{testimonialsData[testimonial.index].text}</div>
            <div className="testimonial-profile">
                <img className="testimonial-profile-image" src={testimonialsData[testimonial.index].profile.imageUrl} />
                <div className='profile-info'>
                <div className="testimonial-profile-name">{testimonialsData[testimonial.index].profile.name}</div>
                <div className="testimonial-profile-email">{testimonialsData[testimonial.index].profile.email}</div>
                </div>
            </div>
        </div>
        <div className="prev-next-buttons">
            <div className="prev-button" onClick={() => prev()}> {"<"} </div>
            <div className="next-button" onClick={() => next()}> {">"} </div>
        </div>
      </div>
    </div>
  );
};
