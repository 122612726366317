import React from "react";
import styled from "styled-components";

const CareersPageCard = ({ sampleImage, cardHeading, Description, color = "#FFFFFF" }) => {
  return (
    <CardContainer style={{ background: color }}>
      <img src={sampleImage} alt="page-card" />
      <Text>{cardHeading}</Text>
      <p>{Description}</p>
    </CardContainer>
  );
};

export default CareersPageCard;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 30%;
  height: 300px;
  background: #f9fafb;
  border-radius: 10px;
  border: 1px;
  cursor: pointer;
  transition: transform 1s;
  margin: 20px;

  img {
    width: 60px;
    height: 60px;
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 40px;
    margin-bottom: 20px;
  }

  p {
    font-family: "Komet";
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
    margin-top: 0px;
    line-height: 24px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 30%;
    margin: 10px;

    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 960px) {
    margin: 10px;
    p {
      width: 85%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 42%;
    line-height: 23px;
    margin: 10px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 600px) {
    margin: 10px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    margin: 15px;
    p {
      width: 85%;
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 15px;
  }
  @media screen and (max-width: 320px) {
    width: 85%;
    height: 320px;
    margin: 15px;
    p {
      width: 85%;
      margin-top: 15px;
    }
  }
`;

const Text = styled.p`
  width: 301px;
  height: 40px;
  font-family: Komet;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #101828;
  margin-left: 30px;
  margin-right: 30px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
    line-height: 25px;
  }
  @media screen and (max-width: 960px) {
    width: 90%;
    height: auto;
    line-height: 25px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    width: 220px;
    line-height: 26px;
    margin-bottom: 10px;
  }
`;
