import React from "react";
import WhiteSands from '../assets/whitesands.png';
import Corona from '../assets/corona.png'
import SecureId from '../assets/secureid.png'
import Appzone from '../assets/appzone.png'
import Dermalog from '../assets/dermalog.png'
import controlImg from "../assets/Control.png";
import { Link } from "react-router-dom";


export const Control = () => {
  return (
    <div className="control">
      <div className="control-div">
        <div className="control-content">
          <div className="control-text">
            Gain complete control with SecureKid?
          </div>
          <div className="control-subtext">
            Love to learn more and see how SecureKid can make a difference for
            your school!
          </div>

         <Link to={'contact'} ><div className="contact-button">
            Contact us
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 4.55581L0 8.45292L3.40697e-07 0.658691L6.75 4.55581Z"
                fill="white"
              />
            </svg>
          </div></Link>
        </div>
        <div className="control-img-cont">
          <img src={controlImg} className="control-img" />
        </div>
      </div>
      <div className="partners">
        <h3>Our Partners</h3>
        <div className="partners-grid">
        <img src={WhiteSands} alt="logo"/>
        <img src={Corona} alt="logo"/>
        <img src={SecureId} alt="logo"/>
        <img src={Appzone} alt="logo"/>
        <img src={Dermalog} alt="logo"/>
        </div>
      </div>
    </div>
  );
};
