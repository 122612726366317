import styled from "styled-components";
import bg from "../../images/PaymentIndustryWeServeBg.png";
import trusteeImage from "../../images/TrusteesBg.svg";

export const PaymentContainer = styled.div`
  background: #ffffff;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    // background: red;
    overflow-x: hidden;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const AdvanceThreats = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  overflow-x: hidden;

  img {
    width: 1240px;
    height: 400px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    overflow-x: hidden;
    padding-top: 10px;
    padding-bottom: 60px;

    img {
      width: 95%;
      height: 300px;
    }
  }
  @media screen and (min-width: 769px) and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
    overflow-x: hidden;
    padding-top: 10px;
    padding-bottom: 60px;

    img {
      width: 95%;
      height: 300px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;

    img {
      width: 90vw;
      height: 250px;
    }
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    padding-top: 10px;
    padding-bottom: 20px;
    // background: red;

    img {
      width: 90vw;
      height: 150px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: red;

    img {
      width: 90vw;
      height: 130px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: red;

    img {
      width: 90vw;
      height: 150px;
    }
  }
`;
export const A1 = styled.p`
  width: 850px;
  height: 120px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 48px;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #1d2939;
  margin-bottom: 20px;

  span {
    color: #08783d;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90vw;
    height: auto;
  }
  @media screen and (min-width: 769px) and (max-width: 960px) {
    width: 90vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 90vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media screen and (max-width: 480px) {
    font-size: 40px;
    line-height: 48px;
  }
  @media screen and (max-width: 375px) {
    font-size: 36px;
    line-height: 46px;
  }
  @media screen and (max-width: 320px) {
    font-size: 30px;
    line-height: 38px;
  }
`;
export const PaymentButton = styled.p`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 8px;
  width: 227px;
  height: 44px;
  background: #08783d;
  border-radius: 8px;
  margin-bottom: 50px;
  margin-top: 0px;

  height: 24px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 320px) {
    margin-bottom: 30px;
  }
`;

export const ImageDiv = styled.div`
  margin-top: 30px;
`
export const FinancialInstance = styled.div`
  // background: tomato;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const FinanceWrapper = styled.div`
// background: tomato;
width: 90%
height: auto;
display: flex;
justify-content: space-between;
align-items: center;

// @media screen and (min-width: 961px) and (max-width: 1200px) {
@media screen and (min-width: 961px) {
  // background: red;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 600px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 480px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 0px;
  padding-bottom: 0px;
}
@media screen and (max-width: 375px) {
}
@media screen and (max-width: 320px) {
}
`;
export const Left = styled.div`
  // background: red;
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 682px;
    height: 552px;
  }

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: auto;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 682px;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 650px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 350px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 350px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 280px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 200px;
    }
  }
`;
export const Right = styled.div`
  // background: brown;
  width: 50%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media screen and (min-width: 961px) and (max-height: 1200px) {
  @media screen and (min-width: 961px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const FinancialInfo = styled.div`
  // background: red;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 539px;
  height: auto;
  margin-left: 80px;

  p {
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
  }

  // @media screen and (min-width: 961px) and (max-height: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 20px;

    p {
      // background: blue;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 85%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 80px;

    p {
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 80%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
      font-size: 16px;
    }
  }
`;

export const Right3Navs = styled.div`
  //   background: blue;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 26px;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 70vw;
    // background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    // background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 95vw;
    // background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    // background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    // background: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const Right3Button = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 15px;
  //   width: 99px;
  height: 28px;
  background: #f6eaeb;
  border-radius: 97px;
  cursor: pointer;
  font-weight: normal;
  margin-right: 10px;
  transition: transform 1s;

  width: auto;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #a92f34;

  :hover {
    transform: scale(1.1);
    font-weight: bold;
  }

  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 12px;
    //   width: 99px;
    height: 28px;
    background: #f6eaeb;
    border-radius: 97px;
    cursor: pointer;
    font-weight: normal;
    margin-right: 0px;
    transition: transform 1s;

    width: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a92f34;
  }
  @media screen and (max-width: 375px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 10px;
    //   width: 99px;
    height: 28px;
    background: #f6eaeb;
    border-radius: 97px;
    cursor: pointer;
    font-weight: normal;
    margin-right: 0px;
    transition: transform 1s;
    margin: 8px;

    width: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #a92f34;
  }
  @media screen and (max-width: 320px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 15px;
    //   width: 99px;
    height: 28px;
    background: #f6eaeb;
    border-radius: 97px;
    cursor: pointer;
    font-weight: normal;
    margin-right: 0px;
    transition: transform 1s;
    margin: 8px;

    width: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #a92f34;
  }
`;
export const Text1 = styled.div`
  width: 500px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin: 0px;

  @media screen and (max-width: 960px) {
    width: 70vw;
    // background: green;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 70vw;
    // background: green;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 70vw;
    // background: green;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    // background: green;
    height: auto;
    font-size: 28px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    // background: green;
    height: auto;
    font-size: 28px;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    // background: green;
    height: auto;
  }
`;
export const FinancialOptions = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  // background: blue;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 480px) {
    width: 95vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
`;
export const List = styled.div`
  // background: tomato;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: auto;
  margin-bottom: 20px;

  img {
    // background: red;
    width: 32px;
    height: 32px;
    // background: #f6eaeb;
    // border-radius: 16px;
    margin-right: 16px;
    margin-left: 0px;
  }

  p {
    // background: yellow;
    margin: 0px;
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    // background: pink;
    width: auto;

    p {
      // background: blue;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 70%;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
    p {
      width: 100%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    height: 50px;
    p {
      width: 82%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 50px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    height: 60px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 320px) {
    width: 95vw;
    height: 60px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
      font-size: 16px;
    }
  }
`;
export const ChamsRequirement = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
  margin-bottom: 100px;
  overflow-x: hidden;

  img {
    width: 1240px;
    height: 400px;
    margin-top: 50px;
  }

  @media screen and (min-width: 769px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 20px;
    padding-bottom: 40px;
    overflow-x: hidden;

    img {
      width: 90%;
      height: 300px;
      margin-top: 50px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: 600px;
    height: auto;
    // background: red;

    img {
      width: 85%;
      height: 250px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: 600px;
    height: auto;
    // background: red;
    margin-bottom: 60px;

    img {
      width: 85%;
      height: 250px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 600px;
    height: auto;
    // background: red;
    margin-bottom: 30px;
    padding-top: 0px;
    padding-bottom: 0px;

    img {
      width: 90%;
      height: 150px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: 650px;
    height: auto;
    // background: red;
    margin-top: 10px;
    margin-bottom: 30px;

    img {
      width: 90%;
      height: 150px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: 650px;
    // background: red;
    margin-top: 0px;
    margin-bottom: 30px;

    img {
      width: 90%;
      height: 130px;
    }
  }
`;
export const C1 = styled.p`
  //   background: red;
  width: 700px;
  height: 73px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #101828;
  margin-bottom: 23px;

  @media screen and (min-width: 769px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    height: auto;
    font-size: 24px;
    line-height: 34px;
  }
`;
export const C2 = styled.div`
  width: 1052px;
  height: 56px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: #667085;

  @media screen and (min-width: 769px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: auto;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: auto;
  }
`;
export const DesignInstance = styled.div`
  width: 100vw;
  height: 800px;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const DesignWrapper = styled.div`
  // background: tomato;
  width: 90%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 95vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    // padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 00px;
    padding-bottom: 00px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left2 = styled.div`
  //   background: red;
  width: 0%;
  height: auto;

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const Right2 = styled.div`
  // background: brown;
  width: 55%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 552px;
    height: 541px;
    width: 552px;
    height: 541px;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    width: 50%;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 682px;
      height: 552px;
      margin-top: 60px;
      margin-bottom: 60px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 650px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 350px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 350px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 280px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 200px;
    }
  }
`;
export const DesignInfo = styled.div`
  //   background: red;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0px;
  width: 539px;
  height: 550px;
  //   margin-left: 80px;

  p {
    width: 539px;
    height: 88px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
    margin-top: 30px;
    margin-bottom: 60px;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-left: 20px;

    p {
      // background: blue;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 85%;
      height: auto;
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 80px;

    p {
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 80%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
      font-size: 16px;
    }
  }
`;
export const Text2 = styled.div`
  width: 500px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  margin: 0px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 95%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 80vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 70vw;
    // background: green;
    height: auto;
  }
  @media screen and (max-width: 600px) {
    width: 70vw;
    // background: green;
    height: auto;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    // background: green;
    height: auto;
    font-size: 28px;
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    // background: green;
    height: auto;
    font-size: 28px;
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    // background: green;
    height: auto;
  }
`;
export const DesignOption = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 480px) {
    width: 95vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
`;
export const Opt = styled.div`
  //   background: blue;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: 20px;

  img {
    // background: red;
    width: 32px;
    height: 32px;
    background: #f6eaeb;
    border-radius: 16px;
    margin-right: 16px;
    margin-left: 0px;
  }

  p {
    margin: 0px;
    width: 546px;
    height: auto;
  }

  @media screen and (min-width: 961px) and (max-height: 1200px) {
    // background: pink;
    width: auto;
    height: auto;

    p {
      // background: blue;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 70%;
      height: auto;
    }
  }
  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
    p {
      width: 90%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    height: auto;
    p {
      width: 82%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    p {
      width: 85%;
      height: auto;
      text-align: left;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    height: auto;
    p {
      width: 85%;
      height: auto;
      text-align: left;
      font-size: 16px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 95vw;
    height: auto;
    p {
      width: 85%;
      height: auto;
      text-align: left;
      font-size: 16px;
    }
  }
`;
export const SelfService = styled.div`
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    width: 100vw;
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const SelfServiceWrapper = styled.div`
  //   background: red;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left3 = styled.div`
  //   background: tomato;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 560px;
    height: 560px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 80%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 75vw;
      height: 660px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 600px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 75vw;
      height: 560px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 600px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 70vw;
      height: 560px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 450px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 380px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 450px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 330px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 300px;
    }
  }
`;
export const Right3 = styled.div`
  // background: blue;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
`;
export const SelfDescription = styled.div`
  //   background: green;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  p {
    // background: red;
    width: 500px;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    // margin-left: 80px;
    margin-to: 30px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    p {
      // background: red;
      width: 80%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      // margin-left: 80px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    p {
      // background: red;
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      margin-left: 0px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 450px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: 450px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: 650px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: 700px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: 750px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
`;
export const SelfHeading = styled.div`
  width: 546px;
  height: 38px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  //   margin-left: 80px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 600px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const IndustriesWeServe = styled.div`
  position: relative;
  width: 100vw;
  height: 340px;
  background-image: url(${bg});
  background-image: url(${bg}),
    linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
    width: 100vw;
  }
  @media screen and (max-width: 768px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    position: relative;
    width: 100vw;
    height: 320px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    position: relative;
    width: 100vw;
    height: 350px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    position: relative;
    width: 100vw;
    height: 400px;
    // background-image: url(${bg});
    background-image: url(${bg}),
      linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
    opacity: 0.8;
    overflow-x: hidden;
    overflow-y: hidden;
  }
`;
export const Gradient = styled.div`
  position: absolute;
  width: inherit;
  height: 340px;
  left: 0px;
  top: 0px;
  background: linear-gradient(26.57deg, #022412 8.33%, #08783d 91.67%);
  opacity: 0.8;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ServeContent = styled.div`
  position: absolute;
  width: inherit;
  height: inherit;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 375px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 100vw;
    height: inherit;
    // height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;
    align-items: center;
    // margin-top: 20px;
    // margin-bottom: 20px;
  }
`;
export const ServeLeft = styled.div`
//   background: red;
color: #ffffff;
z-index: 999;
width: 406px;
height: auto;
margin-left: 120px;
//   margin-top: 94px;

@media screen and (max-width: 960px) {
}
@media screen and (max-width: 768px) {
  // background: pink;
  width: width: 30%;
  margin-left: 10px;
}
@media screen and (max-width: 600px) {
  // background: pink;
  height: auto;
  width: width: 30%;
  margin-left: 0px;
}
@media screen and (max-width: 480px) {
  // background: pink;
  width: width: 100%;
  height: auto;
  margin-left: 0px;
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 375px) {
  // background: pink;
  width: width: 100%;
  height: auto;
  margin-left: 0px;
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 320px) {
  // background: pink;
  width: width: 100%;
  // height: 600px;
  margin-left: 0px;
  display: flex;
  justify-content: center;
}
`;

export const L1 = styled.p`
  width: 383px;
  height: 44px;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
  }
  @media screen and (max-width: 320px) {
    // background: black;
    width: 100%;
    height: auto;
    text-align: center;
    font-size: 32px;
  }
`;
export const L2 = styled.p`
  width: 406px;
  height: 88px;
  font-family: "Komet";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
    width: 100%;
  }
  @media screen and (max-width: 480px) {
    // background: black;
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
    display: flex;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    // background: red;
    width: 90%;
    height: auto;
    display: flex;
    justify-content: center;
  }
`;
export const ServeRight = styled.div`
  //   background: yellow;
  color: #ffffff;
  z-index: 999;
  width: 406px;
  height: auto;
  margin-right: 120px;
  //   margin-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    // background: pink;
    width: 90%;
    margin-right: 10px;
  }
  @media screen and (max-width: 600px) {
    // background: pink;
    width: 90%;
    margin-right: 0px;
  }
  @media screen and (max-width: 480px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    // background: green;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 375px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
  @media screen and (max-width: 320px) {
    color: #ffffff;
    z-index: 999;
    width: 95%;
    height: auto;
    margin-right: 0px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
  }
`;
export const R1 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R2 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R3 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R4 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
export const R5 = styled.p`
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  color: #ebf5f0;
  margin-top: 0px;

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    margin: 10px;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
    margin: 5px;
  }
`;
// export const Receipt =styled.div``;
export const Receipt = styled.div`
  // background: tomato;
  width: 90%;
  height: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: red;
    justify-content: center;
    width: 100vw;
    height: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: pink;
    overflow-x: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const ReceiptWrapper = styled.div`
background: #ffffff;
width: 90%
height: 100%;
display: flex;
justify-content: space-between;
align-items: center;

// @media screen and (min-width: 961px) and (max-width: 1200px) {
@media screen and (min-width: 961px) {
  // background: black;
  width: 95vw;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 960px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: flex-start;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 768px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 600px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 480px) {
  width: 100vw;
  height: auto;
  // background: pink;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 30px;
}
@media screen and (max-width: 375px) {
}
@media screen and (max-width: 320px) {
}
`;
export const Left5 = styled.div`
  // background: red;
  width: 50%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  img {
    width: 682px;
    height: 552px;
  }

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 50%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 80%;
      height: 552px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 650px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 552px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 350px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 300px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 252px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 280px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 85%;
      height: 200px;
    }
  }
`;
export const Right5 = styled.div`
  // background: brown;
  width: 45%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  // @media screen and (min-width: 961px) and (max-width: 1200px) {
  @media screen and (min-width: 961px) {
    // background: yellow;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const ReceiptInfo = styled.div`
  // background: red;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 539px;
  height: auto;
  margin-left: 80px;

  p {
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
  }

  // @media screen and (min-width: 961px) and (max-height: 1200px) {
  @media screen and (min-width: 961px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 20px;

    p {
      // background: blue;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 85%;
    }
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    height: auto;
    margin-left: 80px;

    p {
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #475467;
      width: 80%;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 40px;
    overflow-x: hidden;
    padding-top: 50px;
    padding-bottom: 30px;

    p {
      width: 70vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    // background: red;
    margin-left: 0px;
    overflow-x: hidden;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;

    p {
      width: 85vw;
      // background: pink;
      margin-top: 20px;
      height: auto;
      // text-align: center;
      font-size: 16px;
    }
  }
`;
export const ReceiptOptions = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

  @media screen and (max-width: 960px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 480px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 375px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
  @media screen and (max-width: 320px) {
    width: 90vw;
    overflow-x: hidden;
    // background: yellow;
  }
`;
// export const PaymentContainer =styled.div``;
export const RecOpt = styled.div`
  // background: tomato;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
  height: auto;
  margin-bottom: 20px;

  img {
    // background: red;
    width: 32px;
    height: 32px;
    // background: #f6eaeb;
    // border-radius: 16px;
    margin-right: 16px;
    margin-left: 0px;
  }

  p {
    // background: yellow;
    margin: 0px;
    // width: 90%;
    width: 90%;
    height: auto;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #475467;
  }

  @media screen and (max-width: 960px) {
  }
  @media screen and (max-width: 768px) {
    width: auto;
    height: 50px;
    p {
      width: 100%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 600px) {
    width: 95vw;
    height: 50px;
    p {
      width: 82%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: 50px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 375px) {
    width: 95vw;
    height: 60px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
    }
  }
  @media screen and (max-width: 320px) {
    width: 95vw;
    height: 60px;
    p {
      width: 85%;
      height: auto;
      text-align: left;
      font-size: 16px;
    }
  }
`;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
export const Performance = styled.div`
  // background: yellow;
  background: linear-gradient(
    0deg,
    rgba(57, 147, 100, 0.25) 0%,
    rgba(206, 228, 216, 0.25) 77.6%
  );
  width: 100vw;
  height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const PerformanceWrapper = styled.div`
  // background: red;
  width: 90%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    //   background: red;
    width: 95%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: inherit;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
export const Left4 = styled.div`
  //   background: tomato;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 552px;
    height: 541px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 80%;
      height: 80%;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 75vw;
      height: 660px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 600px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 75vw;
      height: 560px;
    }
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 600px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 70vw;
      height: 560px;
    }
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 450px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 380px;
    }
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 450px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 330px;
    }
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 400px;
    overflow-x: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: red;
    overflow-y: hidden;

    img {
      width: 85vw;
      height: 300px;
    }
  }
`;
export const Right4 = styled.div`
  //   background: blue;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: blue;
    width: 50%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  @media screen and (max-width: 960px) {
    width: 100vw;
    height: auto;
    padding-top: 60px;
    padding-bottom: 60px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 768px) {
    width: 100vw;
    height: auto;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 600px) {
    width: 100vw;
    height: auto;
    height: 450px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 480px) {
    width: 100vw;
    height: auto;
    height: 650px;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 375px) {
    width: 100vw;
    height: auto;
    height: 700px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
  @media screen and (max-width: 320px) {
    width: 100vw;
    height: auto;
    height: 750px;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: green;
    overflow-y: hidden;
  }
`;
export const PerformanceDescription = styled.div`
  //   background: green;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-x: hidden;

  p {
    // background: red;
    width: 500px;
    height: 84px;
    font-family: "Komet";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #667085;
    // margin-left: 80px;
    margin-to: 30px;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    // background: green;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow-x: hidden;

    p {
      // background: red;
      width: 80%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      // margin-left: 80px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 960px) {
    // background: green;
    width: 100vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-x: hidden;

    p {
      // background: red;
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #667085;
      margin-left: 0px;
      overflow-y: hidden;
    }
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 450px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: 450px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-x: hidden;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: 650px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 375px) {
    width: 85vw;
    height: 700px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
  @media screen and (max-width: 320px) {
    width: 85vw;
    height: 750px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: flex-wrap;
    overflow-y: hidden;
    // background: white;

    p {
      width: 85vw;
      height: auto;
    }
  }
`;
export const PerformanceHeading = styled.div`
  width: 546px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
  //   margin-left: 80px;

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 90%;
    height: auto;
  }
  @media screen and (max-width: 960px) {
    width: 85vw;
    height: auto;
    // background: blue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 768px) {
    width: 85vw;
    height: 600px;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 600px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 480px) {
    width: 85vw;
    height: auto;
    // background: tomato;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 375px) {
  }
  @media screen and (max-width: 320px) {
  }
`;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
// export const PaymentContainer =styled.div``;
