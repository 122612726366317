import styled from "styled-components";

export const CardWrapper = styled.div`
  position: relative;
  //   background: yellow;
  width: 361px;
  width: 28%;
  height: 420px;
  border: 1px solid #eaecf0;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }

  :hover #info {
    height: 240px;
    opacity: 0.95;
  }
  :hover #para {
    visibility: visible;
    // overflow-y: hidden;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px) {
    width: 30%;
    margin: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
  @media screen and (max-width: 960px) {
    width: 40%;
    margin: 15px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
    }
  }
  @media screen and (max-width: 768px) {
    width: 44%;
    margin: 20px;
  }
  @media screen and (max-width: 600px) {
    // width: 45%;
    width: 40%;
    margin: 20px;
  }
  @media screen and (max-width: 480px) {
    width: 85%;
    margin: 20px;
  }
  @media screen and (max-width: 375px) {
    width: 85%;
    margin: 20px;
  }
  @media screen and (max-width: 320px) {
    width: 95%;
    margin: 20px;
  }
`;
export const CardInfo = styled.div`
  position: absolute;
  width: 361px;
    width: 100%;
  height: 110px;
  background: linear-gradient(330.33deg, #101828 14.68%, #475467 98.42%);
  // opacity: 0.6;
  opacity: 0.8;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  cursor: pointer;
  overflow: hidden;
  transition: height 1s;

  // :hover {
  // height: 240px;
  // opacity: 1;
  // p {
  //   visibility: visible;
  // }
  // }

  p {
    // width: 90%;
    width: 300px;
    font-family: "Komet";
    font-style: normal;
    font-size: 18px;
    line-height: 25px;
    color: #ffffff;
    margin-top: 20px;
    margin-left: 24px;
    margin-right: 24px;
    visibility: hidden;
  }

  @media screen and (min-width: 961px) and (max-width: 1200px){
    width: 100%;
    height: auto;

    p {
      width: 85%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-size: 16px;
      line-height: 25px;
      color: #ffffff;
      margin-top: 0px;
      margin-left: 20px;
      margin-right: 20px;
      visibility: hidden;
      // background: red;
    }
  }
  @media screen and (max-width: 960px) {
    width: 100%;
    p {
      width: 90%;
      height: auto;
      font-family: "Komet";
      font-style: normal;
      font-size: 16px;
      line-height: 25px;
      color: #ffffff;
      margin-top: 0px;
      margin-left: 10px;
      margin-right: 0px;
      visibility: hidden;
      // background: red;
    }
  }
`;
export const CardHeading = styled.div`
  //   width: 80%;
  width: 280px;
  height: auto;
  font-family: Komet;;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 24px;
  margin-bottom: 22px;
  margin-left: 24px;
  margin-right: 24px;
  opacity: 1;
  z-index: 999;

  @media screen and (min-width: 961px) {
    // background: red;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  @media screen and (max-width: 960px) {
    // background: red;
    width: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    margin-left: 10px;
    margin-right: 0px;
  }
  @media screen and (max-width: 768px) {
  }
  @media screen and (max-width: 600px) {
  }
  @media screen and (max-width: 480px) {
    width: 90%;
  }
  @media screen and (max-width: 375px) {
    width: 90%;
  }
  @media screen and (max-width: 320px) {
    width: 90%;
    font-size: 22px;
  }
`;
// export const CardWrapper = styled.div``;
// export const CardWrapper = styled.div``;
