import React from "react";
import chamsLogo from "../assets/CASLogo.svg";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="header">
     <Link to={'/'} ><img src={chamsLogo} alt="chams-logo" /> </Link>
      <div className="nav">
        <div className="nav-links"></div>
        <Link to={'/secure-kid/about'} ><div className="nav-links">About us</div></Link>
       <Link to={'/secure-kid/faq'}> <div className="nav-links">FAQs</div></Link>
        <Link to={"/secure-kid/contact"}>
          {" "}
          <div className="contact-button bg-green">
            Request demo
            <svg
              width="7"
              height="9"
              viewBox="0 0 7 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75 4.55581L0 8.45292L3.40697e-07 0.658691L6.75 4.55581Z"
                fill="white"
              />
            </svg>
          </div>
        </Link>
      </div>
    </div>
  );
};
