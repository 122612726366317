import React from "react";
import { Link } from "react-router-dom";
import cyberSecurityImage from "../../../images/CybersecurityImage.png";
import cyberIcon from "../../../images/CyberIcon.svg";
import arrowRight from "../../../images/arrow-right.svg";
import cyberIcon2 from "../../../images/CyberIcon2.svg";
import cyberIcon3 from "../../../images/CyberIcon3.svg";
import {
  CyberWrapper,
  CyberImage,
  CyberContent,
  CyberHeading,
  CyberInfo,
  CyberFeatures,
  CyberNav,
  Items,
  ItemDesc,
  DescHead,
  DescBody,
} from "./CybersecurityStyle";

const Cybersecurity = () => {
  return (
    <CyberWrapper>
      <CyberImage>
        <img
          src={cyberSecurityImage}
          alt="biometricImage"
          data-aos="fade-right"
          data-aos-offset="100"
          data-aos-delay="50"
          data-aos-duration="1000"
          data-aos-easing="ease-in-out"
          data-aos-mirror="true"
          data-aos-once="true"
          //   data-aos-anchor-placement="top-center"
        />
      </CyberImage>
      <CyberContent>
        <img src={cyberIcon} alt="cyberIcon" />
        <CyberHeading>Cybersecurity Solutions</CyberHeading>
        <CyberInfo>
          Cybersecurity is one of the main concerns for most businesses, and for
          a good reason. Companies of all sizes, from small mom-and-pop
          businesses to large corporations, are facing cyber attacks. Each year,
          cybercriminals become more and more innovative when it comes to the
          types of cyber attacks they launch against organizations.
        </CyberInfo>
        <CyberNav>
          <Link
            to="/cybersecurity-solutions"
            style={{
              textDecoration: "none",
            }}
          >
            <p>
            Learn More
              <span>
                <img src={arrowRight} alt="rightArrow" />
              </span>
            </p>
          </Link>
        </CyberNav>
        <CyberFeatures>
          <Items>
            <img
              src={cyberIcon2}
              alt="ItemIcon"
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-delay="50"
              data-aos-duration="500"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            />
            <ItemDesc
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-delay="50"
              data-aos-duration="1000"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              <DescHead>Data Security</DescHead>
              <DescBody>
                Get complete visibility into where your data is (on premises or
                in the cloud) and how it’s being stored and shared at all times.
              </DescBody>
            </ItemDesc>
          </Items>
          <Items>
            <img
              src={cyberIcon3}
              alt="ItemIcon"
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-delay="50"
              data-aos-duration="1200"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            />
            <ItemDesc
              data-aos="fade-left"
              data-aos-offset="100"
              data-aos-delay="50"
              data-aos-duration="1700"
              data-aos-easing="ease-in-out"
              data-aos-mirror="true"
              data-aos-once="true"
            >
              <DescHead>Infrastructure Protection</DescHead>
              <DescBody>
                World class stack of solutions and services that provide both
                web-application and network security.
              </DescBody>
            </ItemDesc>
          </Items>
        </CyberFeatures>
      </CyberContent>
    </CyberWrapper>
  );
};

export default Cybersecurity;
